import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ModalManager from 'view/ModalManager';
// import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from 'data/store';

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
			<ModalManager />
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
