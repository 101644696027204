export const PER_PAGE = 100;

export const DATA_SET_OPTIONS = [
	{ value: 'dcaSidewalkLicenses', label: 'DCA Sidewalk Licenses', },
	{ value: 'dcpZoning', label: 'DCP Zoning', },
	{ value: 'dobCos', label: 'Certificates of Occupancy', },
	{ value: 'dobNowBuildElevatorDeviceDetails', label: 'DOB NOW BUILD Elevator Device Details', },
	{ value: 'dobNowBuildElevatorPermitApplications', label: 'DOB NOW BUILD Elevator Permit Applications', },
	{ value: 'dobNowBuildJobApplicationFilings', label: 'DOB NOW Build Job Application Filings', },
	{ value: 'dobNowElectricalPermitApplications', label: 'DOB NOW Electrical Permit Applications', },
	{ value: 'dobNowElectricalPermitDetails', label: 'DOB NOW Electrical Permit Details', },
	{ value: 'dobNowFacades', label: 'DOB NOW Facades', },
	{ value: 'dobNowSafetyBoilers', label: 'DOB NOW Safety Boilers', },
	{ value: 'dobPermitIssuances', label: 'Permit Issuances', },
	{ value: 'dobSignApplications', label: 'DOB Sign Applications', },
	{ value: 'dobViolations', label: 'DOB Violations', },
	// { value: 'dosBusinessEntities', label: 'DOS Business Entities', },
	{ value: 'ecbViolations', label: 'ECB Violations', },
	{ value: 'fdnyInspections', label: 'FDNY Inspections', },
	{ value: 'fdnySummaries', label: 'FDNY Summaries', },
	{ value: 'fdnyViolations', label: 'FDNY Violations', },
	{ value: 'hpdBuildings', label: 'HPD Buildings', },
	{ value: 'hpdHousingComplaintProblems', label: 'HPD Housing Complaint Problems', },
	{ value: 'hpdHousingComplaints', label: 'HPD Housing Complaints', },
	{ value: 'hpdHousingViolations', label: 'HPD Housing Violations', },
	{ value: 'hpdRepairVacateOrders', label: 'HPD Orders to Repair / Vacate', },
	{ value: 'jobApplications', label: 'DOB Job Applications', },
	{ value: 'oathHearings', label: 'OATH Hearings', },
	// { value: 'slaLiquorLicenses', label: 'SLA Liquor Licenses', },
	{ value: 'standardsApplicationsStatuses', label: 'Standards Application Statuses', },
	{ value: 'tooComplaints', label: '311 Complaints', },
];

export const getLabelFromValue = (value) => {
	if (!value) {
		return '';
	}
	return DATA_SET_OPTIONS.find(option => option.value === value)?.label || '';
};
