import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPortfolioSettings, setNotification/*, removeProperty*/ } from 'reducers/portfolioSettingsReducer';
// import { fetchPortfolio } from 'reducers/portfolioReducer';
// import { closeModal } from 'reducers/modalReducer';
import StandardModal from 'modals/StandardModal';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

import './PropertyNotificationsModal.css';

const PropertyNotificationsModal = ({ options }) => {
	const dispatch = useDispatch();
	const { portfolioId, property } = options;
	const {
		portfolioSettings,
		isLoading,
		// userId,
	} = useSelector(state => ({
		isLoading: state.portfolioSettings.isLoading || state.portfolioSettings.isRemovingProperty,
		portfolioSettings: state.portfolioSettings.data || {},
		// userId: state.user.userData?.id,
	}));

	useEffect(() => {
		dispatch(fetchPortfolioSettings(portfolioId));
	}, [portfolioId, dispatch]);

	const handleToggle = (notificationId, isEnabled) => {
		dispatch(setNotification(portfolioId, notificationId, isEnabled));
	};

	// const handleRemoveProperty = (propertyId) => {
	// 	dispatch(removeProperty(property.id))
	// 	.then((res) => {
	// 		dispatch(fetchPortfolio(userId));
	// 		dispatch(closeModal());
	// 	});
	// }

	let modalContent = (
		<div className="property-notification-modal-content">
			{isLoading && <Spinner />}
			<h6>{property?.address}</h6>
			<div className="mb-2">Email Notifications:</div>
			<div>
				{portfolioSettings.notificationTypes?.map(notificationType => (
					<Toggle
						key={notificationType.id}
						label={notificationType.name}
						isEnabled={notificationType.enabled === 1}
						onChange={(isEnabled) => handleToggle(notificationType.id, isEnabled)}
					/>
				))}
			</div>
{/*			<div>
				<button className="btn btn-danger" onClick={() => handleRemoveProperty(property.id)}>Remove Property</button>
			</div>*/}
		</div>
	);

	return (
		<StandardModal
			title="Property Settings"
			size="md"
			closeOnOutsideClick={false}
			closeLabel="Done"
		>
			{modalContent}
		</StandardModal>
	);
};

export default PropertyNotificationsModal;
