import StatusBadge from 'formatters/StatusBadge';
import DateFormatter from 'formatters/DateFormatter';
import CalendarLink from 'formatters/CalendarLink';
// import DrawingRequest from 'formatters/DrawingRequest';
// import DobViolationResolutionRequest from 'formatters/DobViolationResolutionRequest';
import AcrisRealPropertyMaster from 'formatters/AcrisRealPropertyMaster';
import ECBInfractionCode from 'formatters/ECBInfractionCode';
import OutboundLink from 'formatters/OutboundLink';
import ResolutionRequest from 'formatters/ResolutionRequest';
import { AEU2Link, AEU20Link } from 'formatters/AEULink';

//todo: refactor this so that new formatters don't need to be added manually

const formatters = {
	StatusBadge: StatusBadge,
	DateFormatter: DateFormatter,
	CalendarLink: CalendarLink,
	// DrawingRequest: DrawingRequest,
	// DobViolationResolutionRequest: DobViolationResolutionRequest,
	AEU2Link: AEU2Link,
	AEU20Link: AEU20Link,
	AcrisRealPropertyMaster: AcrisRealPropertyMaster,
	ECBInfractionCode: ECBInfractionCode,
	OutboundLink: OutboundLink,
	ResolutionRequest: ResolutionRequest,
};

export default formatters;