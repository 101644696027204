import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { BOROUGHS } from 'utils/boroughs';
import { checkBlockAndLot } from 'reducers/addressReducer';

import "./BlockAndLotSearch.css";

function BlockAndLotSearch(props) {
	const dispatch = useDispatch();
	const [block, setBlock] = useState('');
	const [lot, setLot] = useState('');
	const [borough, setBorough] = useState(BOROUGHS.MANHATTAN);

	let { label, hideLabel } = props;
	label = label || 'Block and Lot Search';

	const onBlockLotSearch = () => {
		if (props.onSelect) {
			props.onSelect({ block, lot, borough });
		}
		return dispatch(checkBlockAndLot({ block, lot, borough }));
	}

	const onEnter = (e) => {
		if (e.key === 'Enter') {
			onBlockLotSearch();
		}
	}

	return (
		<div className="block-and-lot-search">
			{!hideLabel && <label className="form-label">{label}</label>}
			<div className="input-group block-and-lot">
				<input
					type="text"
					className="form-control block-input"
					placeholder="Block"
					onChange={(e) => setBlock(e.target.value)}
					onKeyPress={(e) => onEnter(e)}
					value={block}
				/>
				<input
					type="text"
					className="form-control lot-input"
					placeholder="Lot"
					onChange={(e) => setLot(e.target.value)}
					onKeyPress={(e) => onEnter(e)}
					value={lot}
				/>
				<select className="form-select borough-select" placeholder="Borough" value={borough} onChange={e => setBorough(e.target.value)}>
					<option value={BOROUGHS.MANHATTAN}>Manhattan</option>
					<option value={BOROUGHS.BRONX}>Bronx</option>
					<option value={BOROUGHS.BROOKLYN}>Brooklyn</option>
					<option value={BOROUGHS.QUEENS}>Queens</option>
					<option value={BOROUGHS.STATEN_ISLAND}>Staten Island</option>
				</select>
				<button className="btn btn-outline-secondary" type="button" onClick={onBlockLotSearch}>
					<i className="bi bi-search" />
				</button>
			</div>
		</div>
	);
}

export default BlockAndLotSearch;
