import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserFiles, getPropertyFiles, getAdminFiles, uploadUserFile, uploadPropertyFile } from 'reducers/fileReducer';

import SpinnerOverlay from 'components/SpinnerOverlay';
import FileTable from 'components/userFiles/FileTable';
import Paginator from 'components/Paginator';

import'./FilesWidget.css';

const FilesWidget = ({ title='', parentType, propertyId }) => {
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState(null);
	const {
		isLoading,
		token,
	} = useSelector(state => ({
		isLoading: state.files.isUploading || state.files.isLoading,
		token: state.user.authToken,
	}));

	const fileInfo = useSelector((state) => {
		if (parentType === 'user') {
			return state.files.userFiles;
		} else if (parentType === 'property') {
			return state.files.propertyFiles[propertyId];
		} else if (parentType === 'admin') {
			return state.files.adminFiles;
		}
		return [];
	});

	const files = fileInfo?.data || [];
	const currentPage = fileInfo?.current_page || 0;
	const totalPages = fileInfo?.last_page || 0;

	const onFileChange = (e) => {
		let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    setSelectedFile(formData);
	}

	const loadFiles = (pageNum=1) => {
		if (parentType === 'user') {
			dispatch(getUserFiles(pageNum));
		} else if (parentType === 'property' && propertyId) {
			dispatch(getPropertyFiles(propertyId, pageNum));
		}	else if (parentType === 'admin') {
			dispatch(getAdminFiles(pageNum));
		}
	}

	let onUpload = () => {};
	if (parentType === 'user') {
		onUpload = (file) => {
			dispatch(uploadUserFile(file)).then(loadFiles);
		}
	} else if (parentType === 'property') {
		onUpload = (file) => {
			dispatch(uploadPropertyFile(propertyId, file)).then(loadFiles);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		loadFiles();
	}, []);

	let canUpload = parentType === 'user' || parentType === 'property';

	return (
		<div className="files-widget">
			{isLoading && <SpinnerOverlay isLoading={isLoading} />} 
			{!!title && <div className="upload-title"><strong>{title}</strong></div>}
			{canUpload && (
				<div className="upload-wrapper">
					<div>Upload a File</div>
					<div className="d-flex align-items-center">
						<input type="file" onChange={onFileChange} className="" />
						{!!selectedFile && <button className="btn btn-primary btn-sm" disabled={!selectedFile} onClick={() => onUpload(selectedFile)}>Upload</button>}
					</div>
				</div>
			)}
			<FileTable files={files} parentType={parentType} token={token} />
			{totalPages > 1 && (
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					setPage={(pageNum) => dispatch(loadFiles(pageNum))}
				/>
			)}
		</div>
	);
};

export default FilesWidget