// import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

// import { dataConfig } from 'data/dataConfig';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import OutboundLink from 'components/OutboundLink';


function RecordDetailsLink({ isBis, row, dataSet }) {
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	// let config = dataConfig[dataSet];

	let modalLink = (
		<button
			className="btn-hyperlink"
			onClick={() => dispatch(openModal(MODAL_TYPES.RECORD_DETAILS, { dataSet, id: row.id }))}
		>
			<i className="bi bi-info-circle" />
		</button>
	);

	// let pageLink = (
	// 	<Link to={`/${config.urlPath}/${row.id}`}>
	// 		<i className="bi bi-info-circle" />
	// 	</Link>
	// );

	let outboundLink = (<OutboundLink url={row.url}><i className="bi bi-info-circle" /></OutboundLink>);

	if (isBis && row.url) {
		return outboundLink;
	} else if (!isBis && dataSet && row?.id) {
		if (isMobile) {
			// return pageLink;
			return modalLink;
		}
		return modalLink;
	}

	//this can happen if there's no row.url and data source is BIS
	return <span>-</span>;
}

export default RecordDetailsLink;
