function PhoneNumber(props) {
	let { text } = props;

	let phoneRegex = /^(\d*)(\d{3})(\d{3})(\d{4})$/;

	let formattedText = text;
	let match = phoneRegex.exec(text);

	if (match && match[2] && match[3] && match[4]) {
		if (match[1]) { //country code
			formattedText = `${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
		} else { //no country code
			formattedText = `${match[2]}-${match[3]}-${match[4]}`;
		}
	}

	return (
		<span>{formattedText}</span>
	);
}

export default PhoneNumber;
