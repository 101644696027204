import { Link, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { getNavRoutes } from 'routes/navRoutes'
import DataSetLinks from 'components/sidebar/DataSetLinks';

import './MobilePropertyNavPage.css';

function MobilePropertyNavPage(props) {
	usePageTitle('Navigate');
	let { address } = useParams();
	let {
		isLoggedIn,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
	}));
	
	return (
		<div className="mobile-property-nav">
			<ul>
				{getNavRoutes(address, isLoggedIn).map(route => (
					<li key={route.url}>
						<Link to={route.url} className="mobile-property-nav-button data-set-nav-button">
							{route.label}
						</Link>
					</li>
				))}
				<li className="divider" />
				<DataSetLinks />
			</ul>
		</div>
	);
}

export default MobilePropertyNavPage;
