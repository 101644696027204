import { CONTACT_PHONE, CONTACT_PHONE_FORMATTED, CONTACT_EMAIL, CONTACT_ADDRESS } from 'utils/contactInfo';


import './footer.css';
import aapc from '../images/footer_aapc.png';
import azark from '../images/footer_azark.png';
import arkco from '../images/footer_arkco.png';
import bvs from '../images/footer_bvs.png';
import az from '../images/footer_az.png';
import vps from '../images/footer_vps.png';
import violerts from '../images/footer_violerts.png';
// import nas1 from '../images/nas_1.png';
// import nas2 from '../images/nas_2.png';
// import nas3 from '../images/nas_3.png';
import phone from '../images/footer_call.png';
import location from '../images/footer_door.png';
import email from '../images/footer_mail.png';


const Footer = () => {

    return (
        <div className='footer'>
            <div className='footer-top'>
{/*                <div className='nas-icons'>
                    <a href={''}><img src={nas1} alt=""/></a>
                    <a href={''}><img src={nas2} alt=""/></a>
                    <a href={''}><img src={nas3} alt=""/></a>
                </div>*/}
                <div className='company-icons'>
                    <a href='https://www.azark.com' target={"_blank"} rel="noreferrer"><img src={azark} alt="Azark logo"/></a>
                    <a href='https://aapc.nyc' target={"_blank"} rel="noreferrer"><img src={aapc} alt='AAPC icon'/></a>
                    <a href='https://arkco.nyc' target={"_blank"} rel="noreferrer"><img src={arkco} alt='Arkco icon'/></a>
                    <a href='https://bvs.nyc' target={"_blank"} rel="noreferrer"><img src={bvs} alt='BVS icon'/></a>
                    <a href='https://az-title.com' target={"_blank"} rel="noreferrer"><img src={az} alt='AZ-Title icon'/></a>
                    <a href='https://www.vps.nyc' target={"_blank"} rel="noreferrer"><img src={vps} alt='VPS icon'/></a>
                    <a href='https://www.violerts.com' target={"_blank"} rel="noreferrer"><img src={violerts} alt='Violerts icon'/></a>
                </div>
            </div>
            <div className='footer-bot'>
                <div className='bot-left'>
                    <div className='bot-left-icons'>
                        <a href={`tel:${CONTACT_PHONE}`}>
                            <img className="mx-2" src={phone} alt='Phone icon'/>
                            <span>{CONTACT_PHONE_FORMATTED}</span>
                        </a>
                        <a href={`mailto:${CONTACT_EMAIL}`}>
                            <img className="mx-2" src={email} alt='Email icon'/>
                            <span>{CONTACT_EMAIL}</span>
                        </a>
                        <a href={'https://maps.app.goo.gl/3S2kgXWk48vREADu9'} target={"_blank"} rel="noreferrer">
                            <img className="mx-2" src={location} alt='Location icon'/>
                            <span>{CONTACT_ADDRESS}</span>
                        </a>
                    </div>
                    <div className='bot-left-links'>
                        <a href='https://violerts.com/use-policy'><p>Acceptable Use Policy</p></a>
                    </div>
                </div>
                <div className='bot-right'><p>Copyright © {new Date().getFullYear()} Violerts - AZARK - All Rights
                    Reserved</p></div>
            </div>
        </div>
    );
}

export default Footer;

