import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
import { fetchRecordDetails } from 'reducers/recordDetailsReducer';
import { getDetailsApiPath, getRecordTitle, isPropertyHidden, prettifyPropName } from 'utils/recordDetailsUtils';

import Spinner from 'components/Spinner';

import './RecordDetailsPage.css';

const RecordDetailsPage = (props) => {
	const dispatch = useDispatch();
	let { id } = useParams();
	let { dataSet } = props;
	const detailsUrlPath = getDetailsApiPath(dataSet);
	const isMobile = useMediaPredicate(MOBILE_SIZE);

	let {
		isLoading,
		data,
		propertyData,
	} = useSelector(state => ({
		isLoading: state.recordDetails.isLoading,
		data: state.recordDetails.data || {},
		propertyData: state.property.propertyData,
	}));

	usePageTitle(`${getRecordTitle(dataSet)} ${id}`, propertyData);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		dispatch(fetchRecordDetails(id, detailsUrlPath));
	}, [id, detailsUrlPath]);


	return (
		<div className="detail-page">
			{isLoading ? <Spinner /> :
				<div className={isMobile ? '' : 'm-4'}>
					<h4>{getRecordTitle(dataSet)}</h4>
					<table className="table table-striped table-bordered">
						<tbody>
							{Object.keys(data).map((key, i) => {
								if (isPropertyHidden(key)) {
									return null;
								}
								return (
									<tr key={key}>		
										<td>{prettifyPropName(key)}</td>
										<td>{data[key] || '-'}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			}

		</div>
	);	
}

export default RecordDetailsPage;
