import { useSelector } from 'react-redux';

import ComplianceDatesChecklist from 'components/complianceCalendar/ComplianceDatesChecklist';
import { usePageTitle } from 'utils/pageTitle';
// import './ComplianceDates.css';

const ComplianceDates = (props) => {
	const {
		propertyData,
	} = useSelector(state => ({
		propertyData: state.property.propertyData,
	}));
	usePageTitle('Compliance Dates', propertyData);
	return (
		<div className="property-overview">
			<div className="card">
				<div className="card-header">
					Compliance Dates
				</div>
				<div className="card-body">
					<ComplianceDatesChecklist showCalendarLink />
				</div>
			</div>
		</div>
	);	
}

export default ComplianceDates;
