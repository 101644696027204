import { specialDistricts } from 'utils/specialDistrictUtils';
import StandardModal from 'modals/StandardModal';

const SpecialDistrictsModal = ({ options }) => {
	return (
		<StandardModal
			title={`Special Districts`}
			size="lg"
			closeOnOutsideClick
		>
			<table className="table table-striped table-bordered">
				<tbody>
					{Object.entries(specialDistricts).map(([key, value]) => {
						return (
							<tr key={key}>		
								<td className="key-cell">{key}</td>
								<td className="value-cell">{value}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</StandardModal>
	);
};

export default SpecialDistrictsModal;
