import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_ADMIN_NOTIFICATIONS_REQUEST = 'GET_ADMIN_NOTIFICATIONS_REQUEST';
const GET_ADMIN_NOTIFICATIONS_SUCCESS = 'GET_ADMIN_NOTIFICATIONS_SUCCESS';
const GET_ADMIN_NOTIFICATIONS_ERROR = 'GET_ADMIN_NOTIFICATIONS_ERROR';

const initialState = {
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchAdminNotifications = (sort='created_at|desc', page=1, perPage=10) => (dispatch, getState) => {
	let state = getState();
	let url = `${API_URL}/api/admin/notifications?sort=${sort}&page=${page}&per_page=${perPage}`;
	dispatch(getAdminNotificationsRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getAdminNotificationsSuccess(result));
		},
		onError: error => dispatch(getAdminNotificationsError(error)),
	});
};

export const getAdminNotificationsRequest = () => ({
	type: GET_ADMIN_NOTIFICATIONS_REQUEST,
	payload: {
		data: null,
		isLoading: true,
		hasError: false,
		error: null,
	}
});

export const getAdminNotificationsSuccess = (data) => ({
	type: GET_ADMIN_NOTIFICATIONS_SUCCESS,
	payload: {
		isLoading: false,
		data,
		hasError: false,
		error: null,
	}
});

export const getAdminNotificationsError = (error) => ({
	type: GET_ADMIN_NOTIFICATIONS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const adminNotificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ADMIN_NOTIFICATIONS_REQUEST:
		case GET_ADMIN_NOTIFICATIONS_SUCCESS:
		case GET_ADMIN_NOTIFICATIONS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default adminNotificationsReducer;
