import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_PORTFOLIO_SETTINGS_REQUEST = 'GET_PORTFOLIO_SETTINGS_REQUEST';
const GET_PORTFOLIO_SETTINGS_SUCCESS = 'GET_PORTFOLIO_SETTINGS_SUCCESS';
const GET_PORTFOLIO_SETTINGS_ERROR = 'GET_PORTFOLIO_SETTINGS_ERROR';
const DELETE_PROPERTY_FROM_PORTFOLIO_REQUEST = 'DELETE_PROPERTY_FROM_PORTFOLIO_REQUEST';
const DELETE_PROPERTY_FROM_PORTFOLIO_SUCCESS = 'DELETE_PROPERTY_FROM_PORTFOLIO_SUCCESS';
const DELETE_PROPERTY_FROM_PORTFOLIO_ERROR = 'DELETE_PROPERTY_FROM_PORTFOLIO_ERROR';

const initialState = {
	data: null,
	isLoading: false,
	hasError: false,
	error: null,

	isRemovingProperty: false,
	propertyDeleteError: null,
};

export const fetchPortfolioSettings = (portfolioId) => (dispatch, getState) => {
	const state = getState();
	let url = `${API_URL}/api/portfolio/settings/${portfolioId}`;
	dispatch(getPortfolioSettingsRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getPortfolioSettingsSuccess(result));
		},
		onError: error => dispatch(getPortfolioSettingsError(error)),
	});
};

export const fetchPortfolioSettingsByPropertyId = (propertyId) => (dispatch, getState) => {
	const state = getState();
	let url = `${API_URL}/api/property/${propertyId}/portfolio/settings`;
	dispatch(getPortfolioSettingsRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getPortfolioSettingsSuccess(result));
		},
		onError: error => dispatch(getPortfolioSettingsError(error)),
	});
};

export const removeProperty = (propertyId) => (dispatch, getState) => {
	const state = getState();
	let url = `${API_URL}/api/portfolio/remove/property/${propertyId}`;
	dispatch(deletePropertyFromPortfolioRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			return dispatch(deletePropertyFromPortfolioSuccess(result));
		},
		onError: (error) => {
			return dispatch(deletePropertyFromPortfolioError(error));
		},
	});
};


//this api endpoint doesn't tell you if it worked, so just assume it succeeds. 
//don't dispatch anything. we don't even need an isLoading state
export const setNotification = (portfolioId, notificationId, isEnabled) => (dispatch, getState) => {
	const state = getState();
	const enableText = isEnabled ? 'enable' : 'disable';
	let url = `${API_URL}/api/portfolio/${portfolioId}/${enableText}-notification/${notificationId}`;
	// dispatch(setNotificationRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			// silently ignore this, since the api response doesn't tell us anything
			// console.log('success toggling notification', result)
		},
		onError: (error) => { 
			// silently ignore this, since the api response doesn't tell us anything
			// console.log('error toggling notification', error);
		},
	});
};

export const getPortfolioSettingsRequest = () => ({
	type: GET_PORTFOLIO_SETTINGS_REQUEST,
	payload: {
		data: null,
		isLoading: true,
		hasError: false,
		error: null,
	}
});

export const getPortfolioSettingsSuccess = (data) => ({
	type: GET_PORTFOLIO_SETTINGS_SUCCESS,
	payload: {
		isLoading: false,
		data,
		hasError: false,
		error: null,
	}
});

export const getPortfolioSettingsError = (error) => ({
	type: GET_PORTFOLIO_SETTINGS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

export const deletePropertyFromPortfolioRequest = () => ({
	type: DELETE_PROPERTY_FROM_PORTFOLIO_REQUEST,
	payload: {
		isRemovingProperty: true,
		propertyDeleteError: null,
	}
});

export const deletePropertyFromPortfolioSuccess = () => ({
	type: DELETE_PROPERTY_FROM_PORTFOLIO_SUCCESS,
	payload: {
		isRemovingProperty: false,
		propertyDeleteError: null,
	}
});

export const deletePropertyFromPortfolioError = (error) => ({
	type: DELETE_PROPERTY_FROM_PORTFOLIO_ERROR,
	payload: {
		isRemovingProperty: false,
		propertyDeleteError: error,
	}
});

const portfolioSettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PORTFOLIO_SETTINGS_REQUEST:
		case GET_PORTFOLIO_SETTINGS_SUCCESS:
		case GET_PORTFOLIO_SETTINGS_ERROR:
		case DELETE_PROPERTY_FROM_PORTFOLIO_REQUEST:
		case DELETE_PROPERTY_FROM_PORTFOLIO_SUCCESS:
		case DELETE_PROPERTY_FROM_PORTFOLIO_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default portfolioSettingsReducer;
