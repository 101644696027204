
export const numberWithCommas = (x) => {
	return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getLine = (title, totals) => {
	if (totals) {
		return totals.find(row => row.title === title);
	}
}

//todo: dont use array index

export const getPenalties = (totals) => {
	// let ecbOpenDataPenalties = totals?.[0]?.totalPenalties;
	// let ecbBISPenalties = totals?.[1]?.totalPenalties;
	let ecbOpenData = getLine('od-ecb-violations', totals)?.totalPenalties;
	let ecbBIS =  getLine('bis-ecb-violations', totals)?.totalPenalties;
	let citypay = getLine('citypay-tickets', totals)?.totalPenalties;
	let facades = getLine('facade-filings', totals)?.totalPenalties;
	let electrical = getLine('electrical-permit-applications', totals)?.totalPenalties;
	let total = getLine('property-total', totals)?.totalPenalties;

	let penalties = {
		ecb: Math.max(ecbOpenData, ecbBIS),
		ecbOpenData,
		ecbBIS,
		citypay,
		facades,
		electrical,
		total,
	};

	return penalties;
};

export const getRefunds = (totals) => {
	// let ecbOpenDataRefunds = totals?.[0]?.totalRefunds;
	// let ecbBISRefunds = totals?.[1]?.totalRefunds;

	let ecbOpenData = getLine('od-ecb-violations', totals)?.totalRefunds;
	let ecbBIS =  getLine('bis-ecb-violations', totals)?.totalRefunds;
	let citypay = getLine('citypay-tickets', totals)?.totalRefunds;
	let facades = getLine('facade-filings', totals)?.totalRefunds;
	let electrical = getLine('electrical-permit-applications', totals)?.totalRefunds;
	let total = getLine('property-total', totals)?.totalRefunds;

	let refunds = {
		ecb: Math.max(ecbOpenData, ecbBIS),
		ecbOpenData,
		ecbBIS,
		citypay,
		facades,
		electrical,
		total,
	};

	return refunds;
};
