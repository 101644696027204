import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { forgotPassword, clearResetFields } from 'reducers/userReducer';

import Alert from 'components/Alert';
import Spinner from 'components/Spinner';

import './AuthPage.css';


const ForgotPassword = (props) => {
	usePageTitle('Forgot Password');
	const dispatch = useDispatch();
	const {
		isResettingPassword,
		wasResetSuccessful,
		passwordResetMessages,
	} = useSelector(state => ({
		isResettingPassword: state.user.isResettingPassword,
		wasResetSuccessful: state.user.wasResetSuccessful,
		passwordResetMessages: state.user.passwordResetMessages,
	}));
	const [emailAddress, setEmailAddress] = useState('');
	const onSubmit = () => dispatch(forgotPassword(emailAddress));
	const onEnter = (event) => {
		if (event.key === 'Enter') {
      onSubmit();
    }
	};

	// let showMessage = !!passwordResetMessages.length;
	let alertColor = wasResetSuccessful ? 'success' : 'danger';

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => dispatch(clearResetFields()), []);

	return (
		<div className="auth-page">
			<div className="auth-page-content">
				<h1>Forgot Password</h1>

				<div className="sign-in-wrapper">
					{isResettingPassword && <Spinner />}
					{passwordResetMessages.map(message => <Alert color={alertColor} text={message} key={message} />)}

					<div className="input-wrapper">
						<label className="form-label">Email</label>
						<input
							type="text"
							value={emailAddress}
							onChange={ev => setEmailAddress(ev.target.value)}
							className="form-control"
							placeholder="Email"
							disabled={isResettingPassword}
							onKeyDown={onEnter}
							autoFocus
						/>
					</div>

					<div className="input-wrapper">
						<button onClick={onSubmit} disabled={isResettingPassword} className="btn btn-primary">Reset Password</button>
					</div>

					<div className="input-wrapper">
						<Link to="/sign-up">Don't have an account yet? Sign up</Link>
					</div>
				</div>
			</div>
		</div>
	);	
}

export default ForgotPassword;
