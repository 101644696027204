import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import GoogleAddressSearch from 'components/addressSearch/GoogleAddressSearch';
import BlockAndLotSearch from 'components/addressSearch/BlockAndLotSearch';

import './MobileSearchPage.css';

const MobileSearchPage = (props) => {
	usePageTitle('Search');
	return (
		<div className="mobile-search-page">
			<div className="mobile-search-wrapper">
				<h2>Find a property</h2>
				<GoogleAddressSearch hideLabel />
				<BlockAndLotSearch hideLabel />
			</div>

			<div className="divider" />

			<div className="mobile-dob-button-wrapper">
				<h2>Or search across properties</h2>
				<Link to={`/backend-search`} className="btn btn-outline-primary">
					Backend Search
				</Link>
			</div>
		</div>
	);	
}

export default MobileSearchPage;
