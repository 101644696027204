import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
import { actions } from 'data/store';
import { dataConfig } from 'data/dataConfig';
import { prettifyDataSource } from 'data/dataSourceConstants';
import DataTable from 'components/dataTable/DataTable';
import FilterButtons from 'components/dataTable/FilterButtons';
import Paginator from 'components/Paginator';
import Spinner from 'components/Spinner';
import OutboundLink from 'components/OutboundLink';

import './DataTablePage.css';

function DataTablePage({ dataSet, reducerName, dataSource, title, columns }) {
	let dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	let {
		propertyId,
		isLoading,
		data,
		currentPage,
		totalPages,
		filteredStatus,
		sortField,
		sortDirection,
		numOpen,
		numClosed,
		dataSetUrl,
		propertyData,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoading: state[reducerName].isLoading || state.property.isLoading || state.address.isLoading,
		data: state[reducerName].data,
		currentPage: state[reducerName].currentPage,
		totalPages: state[reducerName].totalPages,
		filteredStatus: state[reducerName].filteredStatus,
		sortField: state[reducerName].sortField,
		sortDirection: state[reducerName].sortDirection,
		numOpen: state[reducerName].numOpen,
		numClosed: state[reducerName].numClosed,
		dataSetUrl: state[reducerName].dataSetUrl,
		propertyData: state.property.propertyData,
	}));

	usePageTitle(title, propertyData);

	let [searchString, setSearchString] = useState('');

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (propertyId) {
			dispatch(actions[reducerName].fetchData(propertyId, 'open'));
		}
	}, [propertyId]);

	let subtitle = `(${prettifyDataSource(dataSource)})`;
	let showStatusFilter = dataConfig[dataSet].hasStatus !== false;

	const debouncedSearch = debounce((searchValue) => {
		setSearchString(searchValue);
		dispatch(actions[reducerName].fetchData(propertyId, filteredStatus, 1, searchValue));
	}, 400);

	return (
		<div className={`data-table-container ${isMobile ? 'data-table-container-mobile' : ''}`}>
			<nav className="navbar navbar-expand-lg navbar-light bg-light">
				<div className="container-fluid">
					<div>
						<span className="navbar-brand data-set-name">{title}</span>
						<span className="navbar-text data-source-name"><OutboundLink url={dataSetUrl}>{subtitle}</OutboundLink></span>
					</div>
					<div className="table-controls">
						<input
							type="text"
							className="form-control data-table-search me-3"
							placeholder={`Search ${title}`}
							onChange={(e) => debouncedSearch(e.target.value)}
						/>
						{showStatusFilter &&
							<FilterButtons
								onFilter={(status) => dispatch(actions[reducerName].fetchData(propertyId, status, 1, searchString))}
								filteredStatus={filteredStatus}
								numOpen={numOpen}
								numClosed={numClosed}
							/>
						}
					</div>
				</div>
			</nav>
			{propertyData && isLoading && <Spinner />}
			<div className="table-wrapper">
				<DataTable
					columns={columns}
					data={data}
					dataSet={dataSet}
					dataSource={dataSource}
					propertyData={propertyData}
					isLoading={isLoading}
					searchString={searchString}
					sortField={sortField}
					sortDirection={sortDirection}
					onSort={(nextSort) => dispatch(actions[reducerName].fetchData(propertyId, filteredStatus, 1, searchString, nextSort))}
				/>
			</div>
			<Paginator
				currentPage={currentPage}
				totalPages={totalPages}
				setPage={(pageNum) => dispatch(actions[reducerName].setPage(pageNum))}
			/>
		</div>
	);
}

export default DataTablePage;
