import { push } from 'connected-react-router';
import { API_URL } from 'data/services/apiConstants';
import { get, post } from 'data/services/apiService';

export const GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST';
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_ERROR = 'GET_PROPERTY_ERROR';

const initialState = {
	propertyData: null,
	isLoading: false,
	error: null,
};

export const postNewProperty = (awsResponse, address) => (dispatch, getState) => {
	let url = `${API_URL}/api/new/property`;
	let data = Object.assign({}, awsResponse);
	if (address) {
		data.address = address;
	}

	dispatch(propertyRequest());
	return post({
		dispatch,
		url,
		data,
		onSuccess: (result) => {
			if (result?.address) {
				return dispatch(fetchProperty(result.address));
			} else {
				return dispatch(propertyRequestError({ message: 'address not found' }));
			}
		},
		onError: error => onPropertyError(error, dispatch, getState),
	});
}

export const fetchProperty = (addressWithoutUSA) => (dispatch, getState) => {
	let url = `${API_URL}/api/property/overview/${encodeURIComponent(addressWithoutUSA)}`;
	dispatch(propertyRequest());
	return get({
		dispatch,
		url,
		onSuccess: result => onPropertySuccess(result?.property, dispatch, getState),
		onError: error => onPropertyError(error, dispatch, getState),
	});
};

export const silentlyRefetchProperty = () => (dispatch, getState) => {
	let state = getState();
	let address = state.property.propertyData?.address;
	if (address) {
		let url = `${API_URL}/api/property/overview/${encodeURIComponent(address)}`;
		return get({
			dispatch,
			url,
			onSuccess: (result) => {
				if (result?.property) {
					return dispatch(propertyRequestSuccess(result?.property));
				}
			},
		});
	}
};

function onPropertySuccess(property, dispatch, getState) {
	let state = getState();

	if (property?.address) {
		dispatch(propertyRequestSuccess(property));
		let path = state.router.location.pathname;

		let pathParts = path.split('/');

		//address needs to be decoded then re-encoded to get the encoding to match, router doesn't seem to encode whitespace
		let oldPath = `/${pathParts[1]}/${encodeURIComponent(decodeURIComponent(pathParts[2]))}/${pathParts[3]}`
		let newPath = `/property/${encodeURIComponent(property.address)}/overview`;
		if (pathParts[1] === 'property' && pathParts[3]) {
			newPath = `/property/${encodeURIComponent(property.address)}/${pathParts[3]}`
		}

		//check if the path changed to prevent repeats being pushed to the browser history, making the back button annoying
		if (newPath !== oldPath) {
			dispatch(push(newPath));
		}
	} else {
		return dispatch(propertyRequestError({ message: 'address not found' }));
	}
}

function onPropertyError(error, dispatch, getState) {
	dispatch(propertyRequestError(error));
	dispatch(push(`/property-not-found`));
}

export const propertyRequest = () => ({
	type: GET_PROPERTY_REQUEST,
	payload: {
		isLoading: true,
	}
});

export const propertyRequestSuccess = (propertyData) => ({
	type: GET_PROPERTY_SUCCESS,
	payload: {
		isLoading: false,
		propertyData,
		error: null,
	}
});

export const propertyRequestError = (error) => ({
	type: GET_PROPERTY_ERROR,
	payload: {
		isLoading: false,
		propertyData: null,
		error,
	}
});

const propertyReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROPERTY_REQUEST:
		case GET_PROPERTY_SUCCESS:
		case GET_PROPERTY_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default propertyReducer;
