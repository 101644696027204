import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { resetPassword, clearResetFields } from 'reducers/userReducer';

import Alert from 'components/Alert';
import Spinner from 'components/Spinner';

import './AuthPage.css';


const ResetPassword = (props) => {
	usePageTitle('Reset Password');
	const dispatch = useDispatch();
	let { resetToken } = useParams();
	const {
		isResettingPassword,
		wasResetSuccessful,
		passwordResetMessages,
	} = useSelector(state => ({
		isResettingPassword: state.user.isResettingPassword,
		wasResetSuccessful: state.user.wasResetSuccessful,
		passwordResetMessages: state.user.passwordResetMessages,
	}));
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const onSubmit = () => dispatch(resetPassword(newPassword, confirmNewPassword, resetToken));
	const onEnter = (event) => {
		if (event.key === 'Enter') {
      onSubmit();
    }
	};

	// let showMessage = !!passwordResetMessages.length;
	let alertColor = wasResetSuccessful ? 'success' : 'danger';

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => dispatch(clearResetFields()), []);


	return (
		<div className="auth-page">
			<div className="auth-page-content">
				<h1>Reset Password</h1>

				<div className="sign-in-wrapper">
					{isResettingPassword && <Spinner />}
					{passwordResetMessages.map(message => <Alert color={alertColor} text={message} key={message} />)}

					<div className="input-wrapper">
						<label className="form-label">New Password</label>
						<input
							type="password"
							value={newPassword}
							onChange={ev => setNewPassword(ev.target.value)}
							className="form-control"
							placeholder="New Password"
							disabled={isResettingPassword}
							onKeyDown={onEnter}
							autoFocus
						/>
					</div>

					<div className="input-wrapper">
						<label className="form-label">Confirm New Password</label>
						<input
							type="password"
							value={confirmNewPassword}
							onChange={ev => setConfirmNewPassword(ev.target.value)}
							className="form-control"
							placeholder="Confirm New Password"
							disabled={isResettingPassword}
							onKeyDown={onEnter}
						/>
					</div>

					<div className="input-wrapper">
						<button onClick={onSubmit} disabled={isResettingPassword} className="btn btn-primary">Reset Password</button>
					</div>
				</div>
			</div>
		</div>
	);	
}

export default ResetPassword;
