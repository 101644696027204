import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import { complianceDataToEvents } from 'utils/complianceDatesUtils';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { fetchComplianceDates } from 'reducers/complianceDatesReducer';

import Spinner from 'components/Spinner';

import './MonthCalendar.css';


const MonthCalendar = (props) => {
	const dispatch = useDispatch();
	const { propertyId, height } = props;
	const calendarHeight = height || 500;
	const {
		// propertyId,
		isLoading,
		complianceData,
	} = useSelector(state => ({
		// propertyId: state.property.propertyData?.id,
		// isLoading: state.complianceDates.isLoading || state.property.isLoading,
		isLoading: state.complianceDates.isLoading,
		complianceData: state.complianceDates.data || {},
	}));


	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (propertyId) {
			dispatch(fetchComplianceDates(propertyId));
		}
	}, [propertyId, dispatch]);

	//todo: pass this in as param
	let onEventClick = (e) => {
		return dispatch(openModal(MODAL_TYPES.CALENDAR_EVENT, { 
			eventTitle: e.event.title,
		}));
	};

	if (props.onEventClick) {
		onEventClick = props.onEventClick;
	}


	return (
		<div className="calendar-month">
			{isLoading ? <Spinner /> :
				<FullCalendar
					plugins={[ dayGridPlugin ]}
					initialView="dayGridMonth"
					height={calendarHeight}
					events={complianceDataToEvents(complianceData)}
					eventClick={onEventClick}
					eventColor={props.eventColor}
				/>
			}
		</div>
	);	
}

export default MonthCalendar;
