import { useDispatch } from 'react-redux';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import './DataTableLinkButton.css';

function ECBInfractionCode(props) {
	const dispatch = useDispatch();
	const { text } = props;

	return (
		<button
			className="data-table-link-button btn btn-link"
			onClick={() => dispatch(openModal(MODAL_TYPES.ECB_INFRACTION_CODE, { codeId: text }))}
		>
			{text}{/* <i className="bi bi-box-arrow-up-right" />*/}
		</button>
	);
}

export default ECBInfractionCode;
