import { Link } from "react-router-dom";

import ExternalLinksList from 'components/externalLinks/ExternalLinksList';
import { codesLinks } from 'utils/propertyLinks';
import Dropdown from 'components/Dropdown';
import OutboundLink from 'components/OutboundLink';
import AcrisLink from 'components/externalLinks/AcrisLink';

import './LinkDropdownButton.css';


const LinkList = ({ links }) => {
	return (
		<div>
			{Object.entries(links).map(([label, url]) => (
				<div key={`${label}`}>
					<OutboundLink url={url}>{label}</OutboundLink>
				</div>
			))}
		</div>
	);
};



const LinkDropdownButton = ({ listName, property, iconOnly }) => {
	if (!listName) {
		return null;
	}

	let menuChild = null;
	let buttonText = null;
	let buttonIcon = null;
	let hideChevron = false;


	const iconLinks = (
		<div className="external-icons">
			{property?.address && (
				<Link to={`/property/${property?.address}/dosBusinessEntitiesOpenData`}>
					<img src="/dos-ny-32.png"  alt="NY Department of State" />
				</Link>
			)}
{/*					<OutboundLink url={`https://www.google.com/search?q=${zoningData.ownername?.replace(' ', '+')}`}>
				<img src="/google-favicon.ico"  alt="Google" />
			</OutboundLink>*/}
			<OutboundLink url="https://a836-pts-access.nyc.gov/care/search/commonsearch.aspx?mode=address">
				<img className="img-dof" src="/nyc_finance_logo.png"  alt="NYC Department of Finance" />
			</OutboundLink>
			<AcrisLink>
				Acris
				{/*<img className="img-dof" src="/nyc_finance_logo.png"  alt="NYC Department of Finance" />*/}
			</AcrisLink>
		</div>
	);

	if (listName === 'quickLinks') {
		menuChild = (
			<div>
				{iconLinks}
				<ExternalLinksList listName={'quickLinksV2'} noIcons />
			</div>
		);
		buttonText = 'Quick Links';
		if (iconOnly) {
			buttonText = null;
			buttonIcon = 'bi bi-link';
			hideChevron = true
		}
	} else if (listName === 'codes') {
		menuChild = <LinkList links={codesLinks} />;
		buttonText = 'Codes';
		if (iconOnly) {
			buttonText = null;
			buttonIcon = 'bi bi-file-earmark-text';
			hideChevron = true
		}
	}

	return (
		<Dropdown
			buttonText={buttonText}
			buttonIcon={buttonIcon}
			menuChild={menuChild}
			className="overview-dropdown-links"
			buttonClass="btn-sm"
			menuClass="overview-dropdown-links-menu"
			hideChevron={hideChevron}
		/>
	);	


}

export default LinkDropdownButton;
