import { useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

let id = 1;

function Tooltip(props) {
	const { text, children, direction, disable, hide, isHtml } = props;
  const tipRef = useRef(null);
  id++;

	useEffect(() => {
		if (hide) {
			ReactTooltip.hide(tipRef);
		}
	}, [hide]);

	if (!text) {
		return <span>children</span>;
	}

	return (
		<span data-for={`${id}`} data-tip={text} data-place={direction || 'top'} data-effect="solid" ref={tipRef} >
			{children}
			<ReactTooltip className="tooltip" disable={disable || hide} id={`${id}`} html={isHtml} />
    </span>
  );
}

export default Tooltip;
