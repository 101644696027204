import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { historicDocumentRequest, clearDocumentRequestError } from 'reducers/documentRequestReducer';
import { isValidEmail } from 'utils/validators';

import Alert from 'view/components/Alert';
import Spinner from 'view/components/Spinner';

import StandardModal from 'modals/StandardModal';

const HistoricDocumentRequestModal = ({ options }) => {
	const dispatch = useDispatch();
	const { propertyData } = options;
	const {
		isSubmitting,
		hasError,
		userData,
	} = useSelector(state => ({
		isSubmitting: state.documentRequest.isSubmitting,
		hasError: state.documentRequest.hasError,
		userData: state.user.userData,
	}));

	useEffect(() => {
		if (userData?.email) {
			setEmail(userData.email);
			setIsEmailValid(isValidEmail(userData.email));
		}
		if (userData?.name) {
			setName(userData.name);
		}
		if (userData?.phone) {
			setPhone(userData.phone);
		}
	}, [userData]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => { // clear old error on initial load
		dispatch(clearDocumentRequestError());
		if (propertyData?.address) {
			setAddress(propertyData.address);
		}
	}, []);

	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [description, setDescription] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [sentToEmail, setSentToEmail] = useState('');

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	const onSubmit = () => {
		if (isValidEmail) {
			setIsSuccess(false);
			setSentToEmail(email)
			let documentInfo = { address, description, email, phone, name };
			dispatch(historicDocumentRequest(documentInfo)).then((res) => {
				if (res?.type === "REQUEST_DOCUMENT_SUCCESS") {
					setIsSuccess(true);	
				}
			});
		}
	}

	const reqForm = (
		<div>
			<div className="form-group">
				<label>Name</label>
				<input
					type="text"
					className="form-control"
					value={name}
					onChange={ev => setName(ev.target.value)}
					placeholder="Your name"
				/>
			</div>
			<div className="form-group">
				<label>Property Address</label>
				<input
					type="text"
					className="form-control"
					value={address}
					onChange={ev => setAddress(ev.target.value)}
					placeholder="Property Address"
				/>
			</div>
			<div className="form-group">
				<label>Email Address</label>
				<input
					type="email"
					placeholder="Email"
					className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
					required
					value={email}
					onChange={(ev) => onChangeEmail(ev.target.value)}
					autoFocus
				/>
			</div>
			<div className="form-group">
				<label>Phone Number</label>
				<input
					type="text"
					className="form-control"
					value={phone}
					onChange={ev => setPhone(ev.target.value)}
					placeholder="(555)-555-5555"
				/>
			</div>
			<div className="form-group">
				<label>Request Description</label>
				<textarea
					className="form-control"
					value={description}
					onChange={ev => setDescription(ev.target.value)}
					placeholder="Description"
				/>
			</div>
		</div>
	);

	const errorAlert = (
		<Alert color="danger">
			<p><strong>Oops, looks like something went wrong</strong></p>
			<div>Your document request has not been received.</div> 
			<div>Please try again or contact Violerts via email if this problem continues.</div>
		</Alert>
	);

	const successAlert = (
		<Alert color="success">
			<p><strong>Document request sent!</strong></p>
			<div>{`Your document request for ${address} has been sent to Violerts.`}</div> 
			<div>{`You should receive a follow up by email at ${sentToEmail} when your request has been processed.`}</div>
		</Alert>
	);


	return (
		<StandardModal
			title={`Historic Information Request`}
			size="lg"
			closeOnOutsideClick
			onConfirm={onSubmit}
			disableConfirm={!address || !description || !email || !isEmailValid || isSubmitting}
			disableClose={isSubmitting}
			confirmText="Submit Request"
			closeLabel="Cancel"
		>
			<div className="docuemnt-request-modal-content">
				{isSubmitting && <Spinner />}
				{hasError && errorAlert}
				{isSuccess && successAlert}
				{reqForm}
				<div>
					<div>Violerts will contact you by email to deliver the requested document(s).</div>
				</div>
			</div>
		</StandardModal>
	);
};

export default HistoricDocumentRequestModal;
