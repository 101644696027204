import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// import { fetchNotifications } from 'reducers/notificationsReducer';
// import { usePageTitle } from 'utils/pageTitle';
import DashboardNav from 'pages/_rd_dashboard/DashboardNav';
// import Portfolio from 'pages/_rd_dashboard/_rd_Portfolio';
 
import './DashboardLayout.css';

const DashboardLayout = ({ children }) => {
	const history = useHistory();
	// const dispatch = useDispatch();
	let {
		isLoggedIn,
		isLoading,
		isUpdating,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		isLoading: state.user.isLoading,
		isUpdating: state.user.isUpdating,
	}));

	useEffect(() => {
		if (!isLoggedIn && !isLoading && !isUpdating) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, isLoading, isUpdating, history]);

	return (
		<div className="rd-dashboard">
			<DashboardNav />
			<div className="rd-dashboard-content">
				{children}
			</div>
		</div>
	);	


}

export default DashboardLayout;
