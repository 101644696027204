import StandardModal from 'modals/StandardModal';

const CalendarEventModal = ({ options }) => {
	return (
		<StandardModal
			title={"Compliance Date"}
			size="md"
			closeOnOutsideClick
		>
			<div>
				{options?.eventTitle}
			</div>
		</StandardModal>
	);
};

export default CalendarEventModal;
