import Collapse from 'components/sidebar/Collapse';
import Badge from 'components/Badge';

const CollapsibleGroup = ({ label, subLinks, violationCount, hasActiveChild, category }) => {
	let badgeColor = 'danger';
	if (category !== 'violation') {
		badgeColor = 'secondary';
	}

	let buttonComponent = (
		<span className="dataset-item-wrapper">
			<span className="dataset-collapse-text">{label}</span> <Badge count={violationCount} color={badgeColor} />
		</span>
	);
	return (
		<li key={label} className={`collapsible-group-wrapper ${hasActiveChild ? 'has-active-child' : ''} category-${category}`}>
			<Collapse buttonComponent={buttonComponent} groupingId={label}>
				{subLinks}
			</Collapse>
		</li>
	);
};

export default CollapsibleGroup;