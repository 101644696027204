// import './Alert.css';

function Alert({ color, text, children }) {
	if (!text && !children) {
		return null;
	}
	
	return (
		<div className={`alert alert-${color || 'primary'}`} role="alert">
			{text || children}
		</div>
	);
}

export default Alert;
