import { useState } from 'react';

import { API_URL } from 'data/services/apiConstants';
import { isManhattan } from 'utils/propertyUtils';
import { getMapLinks } from 'utils/propertyLinks';

import Tooltip from 'components/Tooltip';

import LeafletMap from 'pages/_rd_overview/mapCard/LeafletMap';
import ImagesWidget from 'pages/_rd_overview/mapCard/ImagesWidget';

import './MapCard.css';

const maps = [
	'images',
	'1920s',
	'1950s',
	'sanborn',
	'street',
	'zoning',
	'tax',
	'zola',
	'fema',
];

function MapCard({ propertyData, zoningData }) {
	const [currentMap, setCurrentMap] = useState(null);
	const lat = propertyData?.lat;
	const longitude = propertyData?.['long'];
	const mapInfo = {
		'images': {
			label: 'Images',
			iconUrl: `${API_URL}${propertyData?.thumbnail_url}`,
		},
		'1920s': {
			label: '1920s',
			iconUrl: '/map_icon_1920s.png',
		},
		'1950s': {
			label: '1950s',
			iconUrl: '/map_icon_1950s.png',
		},
		'sanborn': {
			label: 'Sanborn',
			iconUrl: '/map_icon_sanborn.png',
		},
		'street': {
			label: 'Street',
			iconUrl: '/map_icon_street.png',
		},
		'zoning': {
			label: 'Zoning',
			iconUrl: '/map_icon_zoning.png',
			outboundUrl: getMapLinks(propertyData, zoningData)['Zoning Map'],
		},
		'tax': {
			label: 'Tax',
			iconUrl: '/map_icon_tax.png',
			outboundUrl: getMapLinks(propertyData, zoningData)['DOF Digital Tax Map'],
		},
		'zola': {
			label: 'ZoLa',
			iconUrl: '/map_icon_zola.png',
			outboundUrl: getMapLinks(propertyData, zoningData)['ZoLa Map'],
		},
		'fema': {
			label: 'FEMA',
			iconUrl: '/map_icon_fema.png',
			outboundUrl: getMapLinks(propertyData, zoningData)['FEMA Flood Map'],
		},
	};

	const setMap = (mapName) => {
		if (mapInfo[mapName]?.outboundUrl) {
			window.open(mapInfo[mapName].outboundUrl, '_blank').focus();
		} else {
			setCurrentMap(mapName || null)	
		}
	};
	const isDisabled = (mapName) => mapName === 'sanborn' && !isManhattan(propertyData);
	const tipText = (mapName) => {
		if (mapName === 'sanborn' && isDisabled(mapName)) {
			return 'Sanborn map only available in Manhattan';
		}
		return mapInfo[mapName].label;	
	};

	const MapSelection = (
		<div className="map-widget">
			<div className="map-icons">
				{maps.map((mapName) => (
					<Tooltip disable={!isDisabled(mapName)} text={tipText(mapName)} key={mapName}>
						<button className={`map-icon-button ${isDisabled(mapName) ? 'is-disabled' : ''}`} disabled={isDisabled(mapName)} onClick={() => setMap(mapName)} key={mapName}>
							<div>
								<img className="map-icon" src={mapInfo[mapName].iconUrl} alt={mapInfo[mapName].label} />
							</div>
							<div>
								{mapInfo[mapName].label}
							</div>
						</button>
					</Tooltip>
				))}
			</div>
		</div>
	);

	let content = MapSelection;

	//if lat/long are missing, map wont work, so just show the images widget
	if (!lat || !longitude) {
		content = (
			<ImagesWidget
				propertyData={propertyData}
				zoningData={zoningData}
				thumbnailUrl={propertyData?.thumbnail_url}
			/>
		);
	}

	if (currentMap !== null) {
		if (currentMap === 'images') {
			content = (
				<ImagesWidget
					propertyData={propertyData}
					zoningData={zoningData}
					thumbnailUrl={propertyData?.thumbnail_url}
					onBack={() => setMap(null)}
				/>
			);
		} else {
			content = (
				<div className="map-widget">
					<div className="map-overlay-buttons">
						<button onClick={() => setMap(null)}><i className="bi bi-x-lg" /></button>
					</div>
					<LeafletMap propertyData={propertyData} zoningData={zoningData} mapType={currentMap} />
				</div>
			);
		}
	}

	return (
		<div className="overview-card map-card">
			<div className="overview-card-content">
				{content}
			</div>
		</div>
	);
}

export default MapCard;
