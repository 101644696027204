import { useSelector } from 'react-redux';

import Badge from 'components/Badge';
import { numberWithCommas, getPenalties, getRefunds } from 'utils/amountsDueUtils';

import './PenaltiesRefundsCardV2.css';

function AmountBadge({ amount, isRefund }) {
	if (amount === 0) {
		return `$${amount}`;
	} else if (!amount) {
		return `-`;
	} else if (isRefund) {
		return <Badge color="success">${numberWithCommas(Math.abs(amount))}</Badge>;
	}
	return <Badge color="danger">${numberWithCommas(Math.abs(amount))}</Badge>;
}

function PenaltiesRefundsCard() {
	const {
		totals,
	} = useSelector(state => ({
		totals: state.property.propertyData?.totals,
	}));
	let penalties = getPenalties(totals);
	let refunds = getRefunds(totals);


	return (
		<div className="overview-card overview-card-small penalties-refunds-card ">
			<div className="overview-card-content penalties-refunds-card-content ">
				<table className="penalties-refunds-table table">
					<thead>
						<tr>
							<td className="fw-bold">Penalties and Refunds</td>
							<td>Refunds</td>
							<td>Penalties</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>ECB Violations (Open Data)</td>
							<td><AmountBadge amount={refunds.ecbOpenData} isRefund /></td>
							<td><AmountBadge amount={penalties.ecbOpenData} /></td>
						</tr>
						<tr>
							<td>ECB Violations (BIS)</td>
							<td><AmountBadge amount={refunds.ecbBIS} isRefund /></td>
							<td><AmountBadge amount={penalties.ecbBIS} /></td>
						</tr>
						<tr className="amounts-due-row-above-total">
							<td>Facade Filings</td>
							<td><AmountBadge amount={refunds.facades} isRefund /></td>
							<td><AmountBadge amount={penalties.facades} /></td>
						</tr>
						<tr className="amounts-due-row-above-total">
							<td>Electrical</td>
							<td><AmountBadge amount={refunds.electrical} isRefund /></td>
							<td><AmountBadge amount={penalties.electrical} /></td>
						</tr>
						<tr className="amounts-due-row-above-total">
							<td>CityPay Tickets</td>
							<td><AmountBadge amount={refunds.citypay} isRefund /></td>
							<td><AmountBadge amount={penalties.citypay} /></td>
						</tr>
						<tr className="amounts-due-totals-row">
							<td className="font-weight-bold">Total</td>
							<td><AmountBadge amount={refunds.total} isRefund /></td>
							<td><AmountBadge amount={penalties.total} /></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default PenaltiesRefundsCard;
