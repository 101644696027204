import { useDispatch, useSelector } from 'react-redux';

import { isItemInCart } from 'selectors/cartSelectors';
import { addItemToCart, removeItemFromCart } from 'reducers/cartReducer';

import { getPropertyDocuments } from 'utils/propertyDocuments';
// import { openModal } from 'reducers/modalReducer';
// import { MODAL_TYPES } from 'view/ModalManager';
import Spinner from 'components/Spinner';

import './PropertyDocumentsList.css'

function DocumentRow ({ documentName, isDisabled, isCompact }) {
	const dispatch = useDispatch();
	const {
		propertyId,
		address,
		isInCart,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		address: state.property.propertyData?.address,
		isInCart: isItemInCart(state, state.property.propertyData?.address, documentName),
	}));
	
	if (isDisabled) {
		return <div><span className="link-disabled">{documentName}</span></div>;
	}

	// const openAddToCartModal = () => {
	// 	return dispatch(openModal(MODAL_TYPES.ADD_TO_CART, { documentName }));
	// };

	const addToCart = () => dispatch(addItemToCart({ address, documentName, propertyId }));
	const removeFromCart = () => dispatch(removeItemFromCart(address, documentName));

	const toggleCart = () => {
		if (isInCart) {
			removeFromCart();
		} else {
			addToCart();
		}
	}

	return (
		<div className={`document-row ${isCompact ? 'is-compact' : ''}`}>
			<span className="doc-name">{documentName}</span>
			<span>
				<button className={`btn btn-${isInCart ? 'danger' : 'primary'}`} onClick={toggleCart}>
					{isInCart ? 'Remove from cart' : 'Request'}
				</button>
			</span>
		</div>
	);

	// return (
	// 	<button className="btn-hyperlink" disabled={props.areLinksDisabled} onClick={() => openAddToCartModal(documentName)}>
	// 		{documentName}
	// 	</button>
	// );
}

function PropertyDocumentsList(props) {
	// const dispatch = useDispatch();
	const {
		isLoading,
		documents,
	} = useSelector(state => ({
		isLoading: state.property.isLoading,
		documents: getPropertyDocuments(state.property.propertyData),
	}));

	// const openRequestDocumentModal = (documentName) => {
	// 	return dispatch(openModal(MODAL_TYPES.DOCUMENT_REQUEST, { documentName }));
	// };

	// const openAddToCartModal = (documentName) => {
	// 	return dispatch(openModal(MODAL_TYPES.ADD_TO_CART, { documentName }));
	// };

	return (
		<div className="property-document-list">
			{isLoading && <Spinner />}
			{documents.map(documentName => (
				<DocumentRow key={documentName} documentName={documentName} isDisabled={props.areLinksDisabled} isCompact={props.isCompact} />
			))}
		</div>
	);
}

export default PropertyDocumentsList;
