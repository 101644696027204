import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import { removeItemFromCart } from 'reducers/cartReducer';

import './CartPage.css';


const CartPage = (props) => {
	usePageTitle('Cart');
	const dispatch = useDispatch();
	const {
		cartItems,
	} = useSelector(state => ({
		cartItems: state.cart.items,
	}));

	const onRemove = (address, documentName, extra) => {
		dispatch(removeItemFromCart(address, documentName, extra));
	}
	
	return (
		<div className="cart-page">
			<div className="card m-4">
				<div className="card-header">
					Cart
				</div>
				<div className="card-body">
					<div className="cart-items">
						{!cartItems?.length && 'Your cart is empty'}
						{cartItems.length > 0 && (
							<div>
								<div>Violerts will contact you by email to deliver the requested documents.</div>
								<div>Each document request may have a fee, which will be disclosed by email upon request.</div>
							</div>
						)}
						{cartItems.map(item => (
							<div key={item.cartItemId} className="m-2 cart-item-row">
								<div>
									<div>{item.documentName}</div>
									{item.extra && <div>{item.extra}</div>}
									<div><Link to={`/property/${item.address}/overview`}>{item.address}</Link></div>
								</div>
								<div>
									<button className="btn btn-danger btn-sm" onClick={() => onRemove(item.address, item.documentName, item.extra)}>Remove</button>
								</div>
							</div>
						))}
					</div>
					{cartItems?.length ?
						<div className="cart-buttons-container">
							<Link to="/checkout" className="btn btn-primary">Checkout</Link>
						</div>
						: null
					}
				</div>
			</div>
		</div>
	);	
}

export default CartPage;
