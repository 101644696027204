import '../notificationAndSearch/notificationAndSearch.css';
import './dataSources.css';
import wifi from "../images/wifi.png";
import DOB from "../images/DOB_Logo.png"
import DOBNow from "../images/DOB_Now_Logo.png"
import LPC from "../images/LPC_Logo.png"
import NYC311 from "../images/311_Logo.png"
import NYS from "../images/NYS_Logo.png"
import HPD from "../images/HPD_Logo.png"
import FDNY from "../images/FDNY_Logo.png"
import OSHA from "../images/OSHA_Logo.png"
import DOF from "../images/DOF_Logo.png"
import EP from "../images/EP_Logo.png"
import DDC from "../images/DDC_Logo.png"
import DOR from "../images/DOR_Logo.png"

const DataSources = () => {

    return(
        <div className="dataSources">
            <div className='ds-container'>
                <div className='ds-container-left nas-top'>
                    <div className='nas-content'>
                        <div className='title-container'>
                            <h1>City Agencies</h1>
                            <img className='wifi' src={wifi} alt="Wifi logo"/>
                        </div>
                        {/*<h3>Our Data Sources</h3>*/}
                        <p>Violerts acts as your central nervous system for navigating the labyrinth of city agencies that oversee property management in New York. We connect seamlessly with the Department of Buildings [DOB], Housing Preservation and Development [HPD], Fire Department of New York [FDNY], Department of Transportation [NYC DOT], and even New York State databases. This comprehensive integration means Violerts can pull data from all the relevant sources – building applications, permits, approvals, violations, and maintenance records – and present it to you in a clear, unified platform. No more wasting time chasing down information from scattered sources. Violerts ensures you have a complete picture of your property's history and current status, making compliance management a breeze.</p>
                    </div>
                </div>
                <div className='ds-container-right'>
                    <div className='marquee-wrapper'>
                        <div className='slide-1'>
                            <div><img src={DOB} alt="DOB" /></div>
                            <div><img src={DOBNow} alt="DOBNow" /></div>
                            <div><img src={LPC} alt="LPC" /></div>
                            <div><img src={NYC311} alt="NYC311" /></div>
                        </div>
                        <div className='slide-2'>
                            <div><img src={NYS} alt="NYS" /></div>
                            <div><img src={HPD} alt="HPD" /></div>
                            <div><img src={FDNY} alt="FDNY" /></div>
                            <div><img src={OSHA} alt="OSHA" /></div>
                        </div>
                        <div className='slide-3'>
                            <div><img src={DOF} alt="DOF" /></div>
                            <div><img src={EP} alt="EP" /></div>
                            <div><img src={DDC} alt="DDC" /></div>
                            <div><img src={DOR} alt="DOR" /></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default DataSources;

