import { post } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

export const REQUEST_DOCUMENT_REQUEST = 'REQUEST_DOCUMENT_REQUEST';
export const REQUEST_DOCUMENT_SUCCESS = 'REQUEST_DOCUMENT_SUCCESS';
export const REQUEST_DOCUMENT_ERROR = 'REQUEST_DOCUMENT_ERROR';
export const CLEAR_DOCUMENT_REQUEST_ERROR = 'CLEAR_DOCUMENT_REQUEST_ERROR';

const initialState = {
	isSubmitting: false,
	hasError: false,
};

export const historicDocumentRequest = ({ email, name, phone, address, description }) => (dispatch, getState) => {
	let url = `${API_URL}/api/historic-document-requests`;

	let data = {
		propertyAddress: address,
		description: description,
		email: email,
	};

	if (name) {
		data.name = name;
	}
	if (phone) {
		data.phone = phone;
	}

	dispatch(requestDocumentRequest());
	return post({
		dispatch,
		url,
		data,
		onSuccess: (result) => {
			return dispatch(requestDocumentSuccess(result))
		},
		onError: error => dispatch(requestDocumenError(error)),
	});
};

export const requestDocumentByEmail = ({ propertyId, documentName, email, name, phone, extra }) => (dispatch, getState) => {
	let url = `${API_URL}/api/property/${propertyId}/document-request`;
	let data = {
		document: documentName,
		email: email,
	};

	if (name) {
		data.name = name;
	}
	if (phone) {
		data.phone = phone;
	}
	if (extra) {
		data.extra = extra;
	}

	dispatch(requestDocumentRequest());
	return post({
		dispatch,
		url,
		data,
		onSuccess: (result) => {
			return dispatch(requestDocumentSuccess(result))
		},
		onError: error => dispatch(requestDocumenError(error)),
	});
};

export const requestDocumentByUserId = (propertyId, documentName, userId) => (dispatch, getState) => {
	let state = getState();
	let url = `${API_URL}/api/property/${propertyId}/document-request`;
	dispatch(requestDocumentRequest());
	return post({
		dispatch,
		url,
		authToken: state.user.authToken,
		data: {
			document: documentName,
			user: {
				id: userId,
			},
		},
		onSuccess: (result) => {
			dispatch(requestDocumentSuccess(result))
		},
		onError: error => dispatch(requestDocumenError(error)),
	});
};

export const clearDocumentRequestError = () => ({
	type: CLEAR_DOCUMENT_REQUEST_ERROR,
	payload: {
		isSubmitting: false,
		hasError: false,
	}
});

export const requestDocumentRequest = () => ({
	type: REQUEST_DOCUMENT_REQUEST,
	payload: {
		isSubmitting: true,
	}
});

export const requestDocumentSuccess = (data) => ({
	type: REQUEST_DOCUMENT_SUCCESS,
	payload: {
		isSubmitting: false,
		hasError: false,
	}
});

export const requestDocumenError = () => ({
	type: REQUEST_DOCUMENT_ERROR,
	payload: {
		isSubmitting: false,
		hasError: true,
	}
});

const docuemntRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_DOCUMENT_REQUEST:
		case REQUEST_DOCUMENT_SUCCESS:
		case REQUEST_DOCUMENT_ERROR:
		case CLEAR_DOCUMENT_REQUEST_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default docuemntRequestReducer;
