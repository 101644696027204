//from here: https://www.nyc.gov/assets/finance/jump/hlpbldgcode.html
export const buildingClassifications = {
	A: "ONE FAMILY DWELLINGS",
	A0: "CAPE COD",
	A1: "TWO STORIES - DETACHED SM OR MID",
	A2: "ONE STORY - PERMANENT LIVING QUARTER",
	A3: "LARGE SUBURBAN RESIDENCE",
	A4: "CITY RESIDENCE ONE FAMILY",
	A5: "ONE FAMILY ATTACHED OR SEMI-DETACHED",
	A6: "SUMMER COTTAGE",
	A7: "MANSION TYPE OR TOWN HOUSE",
	A8: "BUNGALOW COLONY - COOPERATIVELY OWNED LAND",
	A9: "MISCELLANEOUS ONE FAMILY",
	B: "TWO FAMILY DWELLINGS",
	B1: "TWO FAMILY BRICK",
	B2: "TWO FAMILY FRAME",
	B3: "TWO FAMILY CONVERTED FROM ONE FAMILY",
	B9: "MISCELLANEOUS TWO FAMILY",
	C: "WALK UP APARTMENTS",
	C0: "THREE FAMILIES",
	C1: "OVER SIX FAMILIES WITHOUT STORES",
	C2: "FIVE TO SIX FAMILIES",
	C3: "FOUR FAMILIES",
	C4: "OLD LAW TENEMENT",
	C5: "CONVERTED DWELLINGS OR ROOMING HOUSE",
	C6: "WALK-UP COOPERATIVE",
	C7: "WALK-UP APT. OVER SIX FAMILIES WITH STORES",
	C8: "WALK-UP CO-OP; CONVERSION FROM LOFT/WAREHOUSE",
	C9: "GARDEN APARTMENTS",
	CM: "MOBILE HOMES/TRAILER PARKS",
	D: "ELEVATOR APARTMENTS",
	D0: "ELEVATOR CO-OP; CONVERSION FROM LOFT/WAREHOUSE",
	D1: "ELEVATOR APT; SEMI-FIREPROOF WITHOUT STORES",
	D2: "ELEVATOR APT; ARTISTS IN RESIDENCE",
	D3: "ELEVATOR APT; FIREPROOF WITHOUT STORES",
	D4: "ELEVATOR COOPERATIVE",
	D5: "ELEVATOR APT; CONVERTED",
	D6: "ELEVATOR APT; FIREPROOF WITH STORES",
	D7: "ELEVATOR APT; SEMI-FIREPROOF WITH STORES",
	D8: "ELEVATOR APT; LUXURY TYPE",
	D9: "ELEVATOR APT; MISCELLANEOUS",
	E: "WAREHOUSES",
	E1: "GENERAL WAREHOUSE",
	E2: "CONTRACTORS WAREHOUSE",
	E3: "SEMI-FIREPROOF WAREHOUSE",
	E4: "METAL FRAME WAREHOUSE",
	E7: "SELF-STORAGE WAREHOUSES",
	E9: "MISCELLANEOUS WAREHOUSE",
	F: "FACTORIES AND INDUSTRIAL BUILDINGS",
	F1: "FACTORY; HEAVY MANUFACTURING - FIREPROOF",
	F2: "FACTORY; SPECIAL CONSTRUCTION - FIREPROOF",
	F4: "FACTORY; INDUSTRIAL SEMI-FIREPROOF",
	F5: "FACTORY; LIGHT MANUFACTURING",
	F8: "FACTORY; TANK FARM",
	F9: "FACTORY; INDUSTRIAL-MISCELLANEOUS",
	G: "GARAGES",
	G0: "GARAGE; RESIDENTIAL TAX CLASS 1",
	G1: "ALL PARKING GARAGES",
	G2: "AUTO BODY/COLLISION OR AUTO REPAIR",
	G3: "GAS STATION WITH RETAIL STORE",
	G4: "GAS STATION WITH SERVICE/AUTO REPAIR",
	G5: "GAS STATION ONLY WITH/WITHOUT SMALL KIOSK",
	G6: "LICENSED PARKING LOT",
	G7: "UNLICENSED PARKING LOT",
	G8: "CAR SALES/RENTAL WITH SHOWROOM",
	G9: "MISCELLANEOUS GARAGE",
	GU: "CAR SALES OR RENTAL LOTS WITHOUT SHOWROOM",
	GW: "CAR WASH OR LUBRITORIUM FACILITY",
	H: "HOTELS",
	HB: "BOUTIQUE: 10-100 ROOMS, W/LUXURY FACILITIES, THEMED, STYLISH, W/FULL SVC ACCOMMODATIONS",
	HH: "HOSTELS- BED RENTALS IN DORMITORY-LIKE SETTINGS W/SHARED ROOMS & BATHROOMS",
	HR: "SRO- 1 OR 2 PEOPLE HOUSED IN INDIVIDUAL ROOMS IN MULTIPLE DWELLING AFFORDABLE HOUSING",
	HS: "EXTENDED STAY/SUITE: AMENITIES SIMILAR TO APT; TYPICALLY CHARGE WEEKLY RATES & LESS EXPENSIVE THAN FULL-SERVICE HOTEL",
	H1: "LUXURY HOTEL",
	H2: "FULL SERVICE HOTEL",
	H3: "LIMITED SERVICE; MANY AFFILIATED WITH NATIONAL CHAIN",
	H4: "MOTEL",
	H5: "HOTEL; PRIVATE CLUB, LUXURY TYPE",
	H6: "APARTMENT HOTEL",
	H7: "APARTMENT HOTEL - COOPERATIVELY OWNED",
	H8: "DORMITORY",
	H9: "MISCELLANEOUS HOTEL",
	I: "HOSPITALS AND HEALTH FACILITIES",
	I1: "HOSPITAL, SANITARIUM, MENTAL INSTITUTION",
	I2: "INFIRMARY",
	I3: "DISPENSARY",
	I4: "HOSPITAL; STAFF FACILITY",
	I5: "HEALTH CENTER, CHILD CENTER, CLINIC",
	I6: "NURSING HOME",
	I7: "ADULT CARE FACILITY",
	I9: "MISCELLANEOUS HOSPITAL, HEALTH CARE FACILITY",
	J: "THEATRES",
	J1: "THEATRE; ART TYPE LESS THAN 400 SEATS",
	J2: "THEATRE; ART TYPE MORE THAN 400 SEATS",
	J3: "MOTION PICTURE THEATRE WITH BALCONY",
	J4: "LEGITIMATE THEATRE, SOLE USE",
	J5: "THEATRE IN MIXED-USE BUILDING",
	J6: "TELEVISION STUDIO",
	J7: "OFF BROADWAY TYPE THEATRE",
	J8: "MULTIPLEX PICTURE THEATRE",
	J9: "MISCELLANEOUS THEATRE",
	K: "STORE BUILDINGS",
	K1: "ONE STORY RETAIL BUILDING",
	K2: "MULTI-STORY RETAIL BUILDING (2 OR MORE)",
	K3: "MULTI-STORY DEPARTMENT STORE",
	K4: "PREDOMINANT RETAIL WITH OTHER USES",
	K5: "STAND-ALONE FOOD ESTABLISHMENT",
	K6: "SHOPPING CENTER WITH OR WITHOUT PARKING",
	K7: "BANKING FACILITIES WITH OR WITHOUT PARKING",
	K8: "BIG BOX RETAIL: NOT AFFIXED & STANDING ON OWN LOT W/PARKING, E.G. COSTCO & BJ'S",
	K9: "MISCELLANEOUS STORE BUILDING",
	L: "LOFTS",
	L1: "LOFT; OVER 8 STORIES (MID MANH. TYPE)",
	L2: "LOFT; FIREPROOF AND STORAGE TYPE WITHOUT STORES",
	L3: "LOFT; SEMI-FIREPROOF",
	L8: "LOFT; WITH RETAIL STORES OTHER THAN TYPE ONE",
	L9: "MISCELLANEOUS LOFT",
	M: "RELIGIOUS FACILITIES",
	M1: "CHURCH, SYNAGOGUE, CHAPEL",
	M2: "MISSION HOUSE (NON-RESIDENTIAL)",
	M3: "PARSONAGE, RECTORY",
	M4: "CONVENT",
	M9: "MISCELLANEOUS RELIGIOUS FACILITY",
	N: "ASYLUMS AND HOMES",
	N1: "ASYLUM",
	N2: "HOME FOR INDIGENT CHILDREN, AGED, HOMELESS",
	N3: "ORPHANAGE",
	N4: "DETENTION HOUSE FOR WAYWARD GIRLS",
	N9: "MISCELLANEOUS ASYLUM, HOME",
	O: "OFFICE BUILDINGS",
	O1: "OFFICE ONLY - 1 STORY",
	O2: "OFFICE ONLY 2 - 6 STORIES",
	O3: "OFFICE ONLY 7 - 19 STORIES",
	O4: "OFFICE ONLY WITH OR WITHOUT COMM - 20 STORIES OR MORE",
	O5: "OFFICE WITH COMM - 1 TO 6 STORIES",
	O6: "OFFICE WITH COMM 7 - 19 STORIES",
	O7: "PROFESSIONAL BUILDINGS/STAND ALONE FUNERAL HOMES",
	O8: "OFFICE WITH APARTMENTS ONLY (NO COMM)",
	O9: "MISCELLANEOUS AND OLD STYLE BANK BLDGS.",
	P: "INDOOR PUBLIC ASSEMBLY & CULT. FACILITIES",
	P1: "CONCERT HALL",
	P2: "LODGE ROOM",
	P3: "YWCA, YMCA, YWHA, YMHA, PAL",
	P4: "BEACH CLUB",
	P5: "COMMUNITY CENTER",
	P6: "AMUSEMENT PLACE, BATH HOUSE, BOAT HOUSE",
	P7: "MUSEUM",
	P8: "LIBRARY",
	P9: "MISCELLANEOUS INDOOR PUBLIC ASSEMBLY",
	Q: "OUTDOOR RECREATIONAL FACILITIES",
	Q1: "PARKS/RECREATION FACILTY",
	Q2: "PLAYGROUND",
	Q3: "OUTDOOR POOL",
	Q4: "BEACH",
	Q5: "GOLF COURSE",
	Q6: "STADIUM, RACE TRACK, BASEBALL FIELD",
	Q7: "TENNIS COURT",
	Q8: "MARINA, YACHT CLUB",
	Q9: "MISCELLANEOUS OUTDOOR RECREATIONAL FACILITY",
	R: "CONDOMINIUMS",
	RA: "CULTURAL, MEDICAL, EDUCATIONAL, ETC.",
	RB: "OFFICE SPACE",
	RG: "INDOOR PARKING",
	RH: "HOTEL/BOATEL",
	RK: "RETAIL SPACE",
	RP: "OUTDOOR PARKING",
	RR: "CONDOMINIUM RENTALS",
	RS: "NON-BUSINESS STORAGE SPACE",
	RT: "TERRACES/GARDENS/CABANAS",
	RW: "WAREHOUSE/FACTORY/INDUSTRIAL",
	R0: "SPECIAL CONDOMINIUM BILLING LOT",
	R1: "CONDO; RESIDENTIAL UNIT IN 2-10 UNIT BLDG.",
	R2: "CONDO; RESIDENTIAL UNIT IN WALK-UP BLDG.",
	R3: "CONDO; RESIDENTIAL UNIT IN 1-3 STORY BLDG.",
	R4: "CONDO; RESIDENTIAL UNIT IN ELEVATOR BLDG.",
	R5: "MISCELLANEOUS COMMERCIAL",
	R6: "CONDO; RESID.UNIT OF 1-3 UNIT BLDG-ORIG CLASS 1",
	R7: "CONDO; COMML.UNIT OF 1-3 UNIT BLDG-ORIG CLASS 1",
	R8: "CONDO; COMML.UNIT OF 2-10 UNIT BLDG.",
	R9: "CO-OP WITHIN A CONDOMINIUM",
	S: "PRIMARILY RES. - MIXED USE",
	S0: "PRIMARILY 1 FAMILY WITH 2 STORES OR OFFICES",
	S1: "PRIMARILY 1 FAMILY WITH 1 STORE OR OFFICE",
	S2: "PRIMARILY 2 FAMILY WITH 1 STORE OR OFFICE",
	S3: "PRIMARILY 3 FAMILY WITH 1 STORE OR OFFICE",
	S4: "PRIMARILY 4 FAMILY WITH 1 STORE OROFFICE",
	S5: "PRIMARILY 5-6 FAMILY WITH 1 STORE OR OFFICE",
	S9: "SINGLE OR MULTIPLE DWELLING WITH STORES OR OFFICES",
	T: "TRANSPORTATION FACILITIES",
	T1: "AIRPORT, AIRFIELD, TERMINAL",
	T2: "PIER, DOCK, BULKHEAD",
	T9: "MISCELLANEOUS TRANSPORTATION FACILITY",
	U: "UTILITY BUREAU PROPERTIES",
	U0: "UTILITY COMPANY LAND AND BUILDING",
	U1: "BRIDGE, TUNNEL, HIGHWAY",
	U2: "GAS OR ELECTRIC UTILITY",
	U3: "CEILING RAILROAD",
	U4: "TELEPHONE UTILITY",
	U5: "COMMUNICATION FACILITY OTHER THAN TELEPHONE",
	U6: "RAILROAD - PRIVATE OWNERSHIP",
	U7: "TRANSPORTATION - PUBLIC OWNERSHIP",
	U8: "REVOCABLE CONSENT",
	U9: "MISCELLANEOUS UTILITY PROPERTY",
	V: "VACANT LAND",
	V0: "ZONED RESIDENTIAL; NOT MANHATTAN",
	V1: "ZONED COMMERCIAL OR MANHATTAN RESIDENTIAL",
	V2: "ZONED COMMERCIAL ADJACENT TO CLASS 1 DWELLING: NOT MANHATTAN",
	V3: "ZONED PRIMARILY RESIDENTIAL; NOT MANHATTAN",
	V4: "POLICE OR FIRE DEPARTMENT",
	V5: "SCHOOL SITE OR YARD",
	V6: "LIBRARY, HOSPITAL OR MUSEUM",
	V7: "PORT AUTHORITY OF NEW YORK AND NEW JERSEY",
	V8: "NEW YORK STATE OR US GOVERNMENT",
	V9: "MISCELLANEOUS VACANT LAND",
	W: "EDUCATIONAL FACILITIES",
	W1: "PUBLIC ELEMENTARY, JUNIOR OR SENIOR HIGH",
	W2: "PAROCHIAL SCHOOL, YESHIVA",
	W3: "SCHOOL OR ACADEMY",
	W4: "TRAINING SCHOOL",
	W5: "CITY UNIVERSITY",
	W6: "OTHER COLLEGE AND UNIVERSITY",
	W7: "THEOLOGICAL SEMINARY",
	W8: "OTHER PRIVATE SCHOOL",
	W9: "MISCELLANEOUS EDUCATIONAL FACILITY",
	Y: "GOVERNMENT/CITY DEPARTMENTS",
	Y1: "FIRE DEPARTMENT",
	Y2: "POLICE DEPARTMENT",
	Y3: "PRISON, JAIL, HOUSE OF DETENTION",
	Y4: "MILITARY AND NAVAL INSTALLATION",
	Y5: "DEPARTMENT OF REAL ESTATE",
	Y6: "DEPARTMENT OF SANITATION",
	Y7: "DEPARTMENT OF PORTS AND TERMINALS",
	Y8: "DEPARTMENT OF PUBLIC WORKS",
	Y9: "DEPARTMENT OF ENVIRONMENTAL PROTECTION",
	Z: "MISC. BUILDING CLASSIFICATIONS",
	Z0: "TENNIS COURT, POOL, SHED, ETC.",
	Z1: "COURT HOUSE",
	Z2: "PUBLIC PARKING AREA",
	Z3: "POST OFFICE",
	Z4: "FOREIGN GOVERNMENT",
	Z5: "UNITED NATIONS",
	Z7: "EASEMENT",
	Z8: "CEMETERY",
	Z9: "OTHER MISCELLANEOUS",
};

export const getBuildingCategory = (cls='') => {
	let firstChar = cls[0];
	if (!firstChar) {
		return null;
	}
	let txt = buildingClassifications[firstChar];
	if (!txt) {
		return null;
	}
	return txt;

};

export const getBuildingClassification = (cls='') => {
	let txt = buildingClassifications[cls];
	if (!txt) {
		return `Building class "${cls}" not found`;
	}
	return txt;
};

