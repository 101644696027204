// import {Link} from "react-router-dom";
// import AddressSearch from 'components/addressSearch/AddressSearch';
// import {usePageTitle} from 'utils/pageTitle';
// import Logo from 'components/appHeader/Logo';
// import './_rd_LandingPage.css';

// new landing page imports
// import Navbar from './landingPage/navbar/Navbar'
import Hero from "./landingPage/hero/Hero";
import NotificationAndSearch from "./landingPage/notificationAndSearch/NotificationAndSearch";
import DataSources from "./landingPage/dataSources/DataSources";
import ToolsAndCompliance from "./landingPage/toolsAndCompliance/ToolsAndCompliance";
// import Clientele from "./landingPage/clientele/Clientele";
import Footer from "./landingPage/footer/Footer";


const LandingPage = (props) => {


    return (
        <div style={{overflowY: "auto", height: "100%", overflowX: "hidden"}}>
            <Hero/>
            <NotificationAndSearch/>
            <DataSources/>
            <ToolsAndCompliance/>
            {/*<Clientele/>*/}
            <Footer/>
        </div>
    )
}

// const LandingPage = (props) => {
// 	usePageTitle();
//
// 	const search = (
// 		<div className="rd-landing-page-search">
// 			<Logo />
// 			<div className="tagline">Property compliance just got easier</div>
//
// 			<div className="address-search-wrapper">
// 				<AddressSearch
// 					addressSearchLabel="Find an address"
// 					hideLabel
// 				/>
// 			</div>
// 		</div>
// 	);
//
// 	const statueOfLiberty = (
// 		<div>
// 			<div><img className="statue-of-liberty" src="/statue-of-liberty.jpeg" alt="statue-of-liberty" /></div>
// 			<div className="statue-text">Violerts is the home of New York’s violation alerts, which doesn’t need to be a headache-inducing experience when you have access to the right information, fast, easy, and all in one place.</div>
// 		</div>
// 	);
//
// 	const screenshot = (
// 		<div>
// 			<div><img className="screenshot" src="/app-screenshot-overview-page-1.png" alt="screenshot" /></div>
// 		</div>
// 	);
//
// 	const sellingPoint = (
// 		<div className="selling-point">
// 			<h2>Property Dashboard</h2>
// 			<div>Violerts is a premier database that provides our clients an easy segway to all the important information about their properties; allowing them to stay informed timeously while cutting out the hassle of having to travel to multiple City websites for information that typically can feel like a wild goose chase.</div>
// 		</div>
// 	);
//
// 	const contactInfo = (
// 		<div className="contact-info">
// 			<div className="affiliated">
// 				<div>Affiliated Businesses</div>
// 				<div className="logos">
// 					<img src="/azark-logo.png" alt="azark-logo" />
// 					<img src="/arkco-logo.png" alt="arkco-logo" />
// 					<img src="/bvs-logo.png" alt="bvs-logo" />
// 					<img src="/azarkian-architecture-logo.png" alt="azarkian-architecture-logo" />
// 					<img src="/vivid-print-services-logo.png" alt="vivid-print-services-logo" />
// 				</div>
// 			</div>
// 			<div><i className="bi bi-telephone-fill" /> +1-212-401-6011</div>
// 			<div><i className="bi bi-envelope-fill" /> admin@violerts.com</div>
// 			<Link to="/use-policy">Acceptable Use Policy</Link>
// 		</div>
// 	);
//
//
// 	return (
// 		<div className="rd-landing-page">
// 			<div className="rd-landing-page-content">
//
// 				<div className="rd-landing-page-section">
// 					<div className="rd-landing-page-column">
// 						{search}
// 					</div>
// 					<div className="rd-landing-page-column">
// 						{statueOfLiberty}
// 					</div>
// 				</div>
//
// 				<div className="rd-landing-page-section">
// 					<div className="rd-landing-page-column">
// 						{screenshot}
// 					</div>
// 					<div className="rd-landing-page-column">
// 						{sellingPoint}
// 					</div>
// 				</div>
//
// 				<div className="rd-landing-page-section">
// 					{contactInfo}
// 				</div>
//
//
//
// 			</div>
// 		</div>
// 	);
// }

export default LandingPage;
