//todo: the following data sets have not been tested:
// elevator permit
// board of standards and appeals
// dob sign applications
// boilers
// hpd complaints
// hpd repair/vacate

function DateFormatter(props) {
	let { text } = props;
	let date;

	if (/^\d{8}$/.test(text)) {
		//some are formatted like `20201213`, which is 12/13/2020
		//open data dob violations and open data ecb violations are like this
		let YYYY = parseInt(text.substring(0, 4));
		let MM = parseInt(text.substring(4, 6)) - 1;
		let DD = parseInt(text.substring(6, 8));
		date = new Date(YYYY, MM, DD);
	} else {
		date = new Date(text);
	}

	// console.log('text', text);
	// console.log('date', date);

	let [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];

	let formattedString = `${month}/${day}/${year}`;

	if (isNaN(month) || isNaN(day) || isNaN(year)) {
		formattedString = text;
	}

	return (
		<span title={text}>{formattedString}</span>
	);
}

export default DateFormatter;
