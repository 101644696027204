import "./Logo.css";

const Logo = () => {
	return (
		<svg className="violerts-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="50" viewBox="0 0 720.073 288" enableBackground="new 0 0 720.073 288" xmlSpace="preserve">
			<g id="VIOLERTS_TEXT">
				<g>
					<path fill="#fff" d="M64.795,154.557h27.3l12.807,55.611h0.337l27.131-55.611h25.446l-43.814,81.9H87.208L64.795,154.557z" />
					<path fill="#fff" d="M167.421,154.557h25.278l-11.459,81.9h-25.277L167.421,154.557z M168.938,128.774 c0.337-2.022,1.011-3.931,2.022-5.729c1.011-1.796,2.275-3.342,3.792-4.634c1.517-1.291,3.228-2.331,5.14-3.118 c1.909-0.784,3.876-1.179,5.898-1.179c4.044,0,7.272,1.432,9.689,4.297c2.415,2.864,3.341,6.319,2.781,10.363 c-0.227,2.022-0.843,3.934-1.854,5.729c-1.011,1.799-2.275,3.344-3.792,4.635c-1.517,1.293-3.231,2.333-5.14,3.117 c-1.912,0.787-3.876,1.18-5.898,1.18c-2.022,0-3.876-0.393-5.562-1.18c-1.685-0.784-3.091-1.824-4.213-3.117 c-1.124-1.291-1.938-2.836-2.443-4.635C168.854,132.708,168.712,130.796,168.938,128.774z" />
					<path fill="#fff" d="M202.135,195.507c0.897-6.515,2.891-12.413,5.982-17.694c3.089-5.279,6.936-9.801,11.543-13.566 c4.605-3.763,9.83-6.656,15.672-8.679c5.84-2.021,11.907-3.033,18.2-3.033c6.291,0,12.075,1.012,17.357,3.033 c5.279,2.022,9.716,4.916,13.313,8.679c3.594,3.766,6.206,8.287,7.836,13.566c1.627,5.281,1.993,11.18,1.095,17.694 c-0.9,6.516-2.923,12.415-6.066,17.694c-3.146,5.281-7.022,9.803-11.628,13.566c-4.608,3.764-9.832,6.656-15.672,8.678 c-5.843,2.021-11.91,3.033-18.2,3.033c-6.293,0-12.078-1.012-17.357-3.033c-5.282-2.021-9.69-4.914-13.229-8.678 c-3.539-3.764-6.125-8.285-7.751-13.566C201.601,207.922,201.234,202.023,202.135,195.507z M227.413,195.507 c-0.9,6.18,0.14,11.18,3.118,14.999c2.975,3.82,7.667,5.729,14.071,5.729c6.403,0,11.654-1.908,15.756-5.729 c4.1-3.818,6.598-8.818,7.499-14.999c0.897-6.178-0.169-11.178-3.202-14.998c-3.033-3.818-7.751-5.73-14.155-5.73 s-11.628,1.912-15.672,5.73C230.783,184.33,228.311,189.33,227.413,195.507z" />
					<path fill="#fff" d="M320.432,109.058h25.277l-17.863,127.399h-25.277L320.432,109.058z" />
					<path fill="#fff" d="M425.416,222.301c-4.717,5.17-10.363,9.158-16.936,11.965s-13.173,4.213-19.8,4.213 c-6.293,0-12.078-1.012-17.357-3.033c-5.282-2.021-9.69-4.914-13.229-8.678c-3.539-3.764-6.125-8.285-7.752-13.566 c-1.63-5.279-1.996-11.178-1.095-17.694c0.898-6.515,2.891-12.413,5.982-17.694c3.088-5.279,6.936-9.801,11.543-13.566 c4.605-3.763,9.829-6.656,15.672-8.679c5.84-2.021,11.907-3.033,18.2-3.033c5.84,0,11.01,1.012,15.504,3.033 c4.492,2.022,8.145,4.916,10.953,8.679c2.807,3.766,4.746,8.287,5.814,13.566c1.066,5.281,1.15,11.18,0.252,17.694l-1.18,7.92 h-58.643c0.337,4.832,1.993,8.678,4.971,11.543c2.976,2.865,6.936,4.297,11.881,4.297c4.155,0,7.778-0.926,10.87-2.781 c3.088-1.854,5.924-4.238,8.51-7.16L425.416,222.301z M409.07,186.239c0.674-4.269-0.227-7.921-2.695-10.954 c-2.473-3.033-6.012-4.55-10.618-4.55c-2.809,0-5.337,0.45-7.583,1.348c-2.248,0.901-4.213,2.052-5.897,3.455 c-1.686,1.406-3.092,3.033-4.214,4.887c-1.124,1.854-1.911,3.792-2.358,5.814H409.07z" />
					<path fill="#fff" d="M454.232,154.557h25.277l-1.854,13.145h0.336c3.371-5.056,7.078-8.848,11.123-11.375 s8.873-3.792,14.492-3.792c1.459,0,2.92,0.059,4.381,0.169c1.459,0.113,2.807,0.337,4.045,0.674l-3.201,23.087 c-1.799-0.561-3.539-0.982-5.225-1.264c-1.686-0.279-3.484-0.422-5.393-0.422c-4.832,0-8.764,0.675-11.797,2.022 c-3.033,1.349-5.449,3.231-7.246,5.646c-1.797,2.417-3.176,5.309-4.129,8.679c-0.955,3.37-1.713,7.078-2.273,11.123l-4.719,34.209 h-25.277L454.232,154.557z" />
					<path fill="#fff" d="M575.732,174.779h-22.244l-3.877,27.299c-0.336,2.25-0.504,4.299-0.504,6.152s0.307,3.453,0.926,4.803 c0.617,1.348,1.686,2.387,3.201,3.117c1.518,0.732,3.623,1.096,6.32,1.096c1.348,0,3.117-0.141,5.309-0.422 c2.189-0.279,3.902-0.928,5.139-1.938l-2.863,21.064c-2.924,1.012-5.928,1.686-9.016,2.021c-3.092,0.338-6.096,0.506-9.016,0.506 c-4.271,0-8.148-0.449-11.629-1.348c-3.482-0.898-6.432-2.33-8.848-4.297c-2.416-1.965-4.156-4.521-5.223-7.668 c-1.07-3.145-1.322-6.965-0.76-11.459l5.562-38.928h-16.178l2.865-20.222h16.176l3.371-24.267h25.277l-3.369,24.267h22.244 L575.732,174.779z" />
					<path fill="#fff" d="M634.375,178.655c-3.033-4.604-7.641-6.909-13.818-6.909c-2.135,0-4.27,0.506-6.402,1.517 c-2.137,1.012-3.371,2.755-3.709,5.225c-0.336,2.022,0.506,3.513,2.529,4.466c2.021,0.955,4.549,1.798,7.582,2.527 c3.033,0.732,6.32,1.517,9.859,2.359s6.766,2.164,9.689,3.96c2.92,1.799,5.195,4.242,6.824,7.331 c1.627,3.09,2.107,7.219,1.434,12.385c-0.674,5.283-2.418,9.635-5.225,13.061c-2.811,3.428-6.15,6.18-10.027,8.258 c-3.875,2.08-8.117,3.539-12.723,4.381c-4.607,0.842-9.1,1.264-13.48,1.264c-5.73,0-11.404-0.816-17.021-2.443 c-5.619-1.627-10.168-4.521-13.65-8.678l17.695-17.021c2.021,2.924,4.352,5.141,6.994,6.656c2.637,1.518,5.869,2.275,9.689,2.275 c2.92,0,5.615-0.422,8.088-1.264c2.471-0.842,3.877-2.385,4.213-4.635c0.338-2.133-0.479-3.732-2.443-4.803 c-1.967-1.066-4.494-1.963-7.582-2.695c-3.092-0.73-6.404-1.518-9.943-2.359c-3.539-0.844-6.799-2.107-9.773-3.792 c-2.979-1.686-5.309-4.071-6.994-7.162c-1.686-3.089-2.135-7.218-1.348-12.387c0.674-4.829,2.246-8.986,4.719-12.47 c2.469-3.481,5.477-6.346,9.016-8.595c3.539-2.246,7.441-3.902,11.711-4.971c4.27-1.066,8.537-1.602,12.809-1.602 c5.393,0,10.727,0.787,16.008,2.359c5.279,1.575,9.549,4.382,12.809,8.426L634.375,178.655z" />
				</g>
			</g>
			<g id="VIOLERTS_SIGNAL_ICON">
				<g>
					<path fill="#fff" d="M202.003,89.243c-6.708-2.763-14.169-3.687-21.463-2.403c-7.294,1.285-14.425,4.779-20.475,10.861 c2.875,2.857,5.752,5.716,8.627,8.575c4.136-4.157,9.014-6.548,14.002-7.423c4.99-0.881,10.096-0.25,14.683,1.645 c4.589,1.881,8.661,5.018,11.591,9.156c2.928,4.133,4.717,9.262,4.73,15.127c4.057-0.01,8.112-0.025,12.163-0.033 c-0.019-8.576-2.632-16.074-6.916-22.119C214.665,96.587,208.71,92,202.003,89.243z" />
					<path fill="#fff" d="M215.173,57.201c-12.742-5.237-26.912-6.992-40.773-4.557c-13.859,2.441-27.401,9.079-38.893,20.631 c2.873,2.854,5.753,5.715,8.629,8.574c9.575-9.627,20.867-15.158,32.42-17.193c11.555-2.029,23.369-0.568,33.994,3.799 c10.618,4.367,20.049,11.639,26.832,21.209c6.784,9.568,10.922,21.443,10.957,35.023c4.055-0.01,8.105-0.021,12.162-0.029 c-0.043-16.293-5.001-30.537-13.138-42.016C239.228,71.162,227.915,62.443,215.173,57.201z" />
					<path fill="#fff" d="M275.778,62.65c-11.993-16.919-28.656-29.763-47.434-37.484c-18.775-7.72-39.66-10.308-60.082-6.715 c-20.423,3.594-40.384,13.374-57.315,30.391c2.876,2.862,5.752,5.722,8.626,8.584c15.019-15.104,32.727-23.778,50.844-26.963 c18.117-3.188,36.645-0.889,53.301,5.957c16.655,6.848,31.443,18.242,42.081,33.251c10.636,15.01,17.122,33.634,17.179,54.931 c4.057-0.016,8.109-0.025,12.164-0.035C295.076,100.558,287.766,79.57,275.778,62.65z" />
				</g>
			</g>
		</svg>
	);
};

export default Logo;