import { useDispatch } from 'react-redux';

import { closeModal } from 'reducers/modalReducer';

import StandardModal from 'modals/StandardModal';

const ConfirmModal = ({ options }) => {
	const dispatch = useDispatch();
	const title = options.title || "Are you sure?";
	const color = options.color || "primary";
	const confirmLabel = options.confirmLabel || "Confirm";
	const closeLabel = options.closeLabel || "Cancel";
	const onConfirm = options.onConfirm || (() => {});
	const message = options.message || "Are you sure?";
	const children = options.children;

	const handleConfirm = () => {
		onConfirm();
		dispatch(closeModal());
	};

	return (
		<StandardModal
			title={title}
			size="md"
			closeOnOutsideClick={false}
			onConfirm={handleConfirm}
			confirmText={confirmLabel}
			closeLabel={closeLabel}
			confirmColor={color}
		>
			<div className="">
				{children || message}
			</div>
		</StandardModal>
	);
};

export default ConfirmModal;
