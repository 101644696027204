import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import { closeModal, openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { getPdfExportUrl, getExcelExportUrl } from 'data/services/exportService';
import { dataConfig } from 'data/dataConfig';
import { tableConfig } from 'view/dataTables';

import StandardModal from 'modals/StandardModal';

import './ExportReportModal.css';

function initCheckboxState() {
	let state = {};
	Object.keys(dataConfig).forEach((dataSet) => {
		state[dataSet] = false;
	});
	return state;
}

const getSelectedDataSets = selectedDataSets => Object.keys(selectedDataSets).filter(dataSet => selectedDataSets[dataSet]);

const ExportReportModal = ({ options }) => {
	const dispatch = useDispatch();
	let {
		propertyId,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
	}));

	let [selectedDataSets, setSelectedDataSets] = useState(initCheckboxState());
	let isNothingSelected = !Object.keys(selectedDataSets).some(dataSet => selectedDataSets[dataSet]);
	
	const onClose = () => {
		dispatch(closeModal());
	}

	const handleCheck = (dataSet, isSelected) => {
		let state = Object.assign({}, selectedDataSets);
		state[dataSet] = isSelected;
		setSelectedDataSets(state);
	};

	const handleSelectAll = () => {
		let state = {};
		Object.keys(dataConfig).forEach((dataSet) => {
			state[dataSet] = true;
		});
		setSelectedDataSets(state);
	}

	const onDownload = (type) => {
		let dataSetArray = getSelectedDataSets(selectedDataSets);
		if (type === 'excel') {
			let url = getExcelExportUrl(propertyId, dataSetArray);
			window.open(url, '_blank').focus();
		} else if (type === 'pdf') {
			let url = getPdfExportUrl(propertyId, dataSetArray);
			window.open(url, '_blank').focus();
		}
	}

	const onEmail = () => {
		let dataSetArray = getSelectedDataSets(selectedDataSets);
		dispatch(openModal(MODAL_TYPES.EMAIL_EXPORT_REPORT, {
			propertyId,
			dataSetArray,
		}));
	}

	let footerButtons = [
		<button key="excel" className="btn btn-primary" disabled={isNothingSelected} onClick={() => onDownload('excel')}>Download Excel</button>,
		<button key="pdf" className="btn btn-primary" disabled={isNothingSelected} onClick={() => onDownload('pdf')}>Download PDF</button>,
		<button key="email" className="btn btn-primary" disabled={isNothingSelected} onClick={() => onEmail()}>Email</button>
	];

	let modalContent = (
		<div>
			<div className="mb-3">
				<Link to="/airtable-instructions" onClick={onClose}>How to export to Airtable</Link>
			</div>
			<div className="select-all-buttons-container">
				<button className="btn btn-light" onClick={() => handleSelectAll()}>
					Select All
				</button>
				<button className="btn btn-light" onClick={() => setSelectedDataSets(initCheckboxState())}>
					Deselect All
				</button>
			</div>
			<div>
				{Object.keys(dataConfig).map((dataSet) => {
					if (!dataConfig[dataSet].exportName) {
						return null;
					}
					return (
						<div className="form-group form-check" key={dataSet}>
							<input
								type="checkbox"
								className="form-check-input"
								id={dataSet}
								value={selectedDataSets[dataSet]}
								checked={selectedDataSets[dataSet]}
								onChange={ev => handleCheck(dataSet, ev.target.checked)}
							/>
							<label className="form-check-label" htmlFor={dataSet}>{tableConfig[dataSet].title}</label>
						</div>
					);
				})}
			</div>
		</div>
	);

	return (
		<StandardModal
			title="Select Records to Export"
			size="md"
			closeOnOutsideClick
			footerButtons={footerButtons}
		>
			{modalContent}
		</StandardModal>
	);
};

export default ExportReportModal;
