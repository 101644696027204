// import { useHistory, Link } from "react-router-dom";
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useLocation } from "react-router-dom";

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import OutboundLink from 'components/OutboundLink';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

 
import './ToolbarIcons.css';

const ToolbarIcons = (props) => {
	const { address } = useParams();
	// const isMobile = useMediaPredicate(MOBILE_SIZE);
	const dispatch = useDispatch();
	// const history = useHistory();
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	// const pathParts = location?.pathname?.split('/') || [];
	// const currentRoute = pathParts[3];
	// const isMobileNavOpen = currentRoute === 'nav';
	const {
		// propertyId,
		propertyData,
		firstLoadTasks,
	} = useSelector(state => ({
		// propertyId: state.property.propertyData?.id,
		propertyData: state.property.propertyData,
		firstLoadTasks: state.firstLoadTasks.data,
	}));
	let showRoadview = !!propertyData?.lat && !!propertyData?.long;
	let roadviewUrl = "https://roadview.planninglabs.nyc/view/"+ propertyData?.long +"/" + propertyData?.lat;
	let ecbUrl = "https://ecb.vio.nyc";
	const areFirstLoadTasksComplete = useMemo(() => {
		if (Array.isArray(firstLoadTasks)) {
			return firstLoadTasks.every(task => task.complete === 1);
		}
		return false;
	}, [firstLoadTasks]);

	const toolbarButtons = (
		<div className="toolbar-buttons">
			{showRoadview &&
				<span className="ms-3 toolbar-icon-wrapper">
					<OutboundLink url={roadviewUrl} noIcon >
						<Tooltip text="Road View" direction="bottom">
							<i className="bi bi-eye-fill"/>
						</Tooltip>
					</OutboundLink>
				</span>
			}
			<span className="ms-3 toolbar-icon-wrapper">
				<Link to={`/property/${address}/notifications`}>
					<Tooltip text="Email Alerts" direction="bottom">
						<i className="bi bi-bell-fill" />
					</Tooltip>
				</Link>
			</span>

			<span className="ms-3 toolbar-icon-wrapper">
				<Link to={`/property/${address}/tasks`}>
					<Tooltip text="Property Data Progress" direction="bottom">
						<span className={`fancy-hourglass ${areFirstLoadTasksComplete ? 'complete' : 'in-progress'}`}>
							<i className="bi bi-hourglass-split" />
							{areFirstLoadTasksComplete ?
								<i className="bi bi-check-circle-fill check" />
								: <Spinner size="sm" color="primary" /> 
							}
						</span>
					</Tooltip>
				</Link>
			</span>

			{/*!isMobile && (
				<span className="ms-3 toolbar-icon-wrapper">
					<Link to={`/property/${address}/calendar`}>
						<Tooltip text="Calendar" direction="bottom">
							<i className="bi bi-calendar3"/>
						</Tooltip>
					</Link>
				</span>
			)*/}


			<span className="ms-3 toolbar-icon-wrapper">
				<Link to={`/backend-search`}>
					<Tooltip text="Backend Search" direction="bottom">
						<i className="bi bi-door-open-fill"/>
					</Tooltip>
				</Link>
			</span>

			<span className="ms-3 toolbar-icon-wrapper">
				<button
					className="btn-hyperlink toolbar-button"
					onClick={() => dispatch(openModal(MODAL_TYPES.EXPORT_REPORT))}
					disabled={isLandingPage}
				>
					<Tooltip text="Export Report" direction="bottom">
						<i className="bi bi-cloud-download-fill"/>
					</Tooltip>
				</button>
			</span>

			<span className="ms-3 toolbar-icon-wrapper">
					<OutboundLink url={ecbUrl} noIcon >
						<Tooltip text="ECB" direction="bottom">
							<i className="bi bi-bank2"/>
						</Tooltip>
					</OutboundLink>
			</span>

		</div>
	);

	return (
		<div className="rd-toolbar-icons">
			{toolbarButtons}
		</div>
	);	


}

export default ToolbarIcons;
