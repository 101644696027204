import { Route } from "react-router-dom";

import AppContainer from 'view/containers/AppContainer';
// import Admin from 'pages/dashboard/Admin';
import AdminFilePage from 'pages/dashboard/AdminFilePage';
// import RecordSearchPage from 'pages/RecordSearchPage';
import BackendSearchPage from 'pages/backendSearch/BackendSearchPage';
// import Dashboard from 'pages/dashboard/Dashboard';
import CartPage from 'pages/cart/CartPage';
import CheckoutPage from 'pages/cart/CheckoutPage';
// import ConfirmationPage from 'pages/cart/ConfirmationPage';
import AccountSettings from 'pages/AccountSettings';
import AirtableInstructionsPage from 'pages/Airtable';
// import PropertySearch from 'pages/PropertySearch';
import PropertyNotFound from 'pages/PropertyNotFound';
import AcceptableUsePolicy from 'pages/AcceptableUsePolicy';
import Disclaimer from 'pages/Disclaimer';
import MyDocuments from 'pages/MyDocuments';
import SignIn from 'pages/auth/SignIn';
import SignUp from 'pages/auth/SignUp';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import MobileSearchPage from 'pages/mobile/MobileSearchPage';
// import LandingPage from 'pages/LandingPage';
import LandingPage from 'pages/_rd_LandingPage';

import Portfolio from 'pages/_rd_dashboard/Portfolio';
import Alerts from 'pages/_rd_dashboard/Alerts';
import Compliance from 'pages/_rd_dashboard/Compliance';
import Settings from 'pages/_rd_dashboard/Settings';

import AdminTopVios from 'pages/_rd_dashboard/AdminTopVios';
import AdminFiles from 'pages/_rd_dashboard/AdminFiles';
import AdminPortfolios from 'pages/_rd_dashboard/AdminPortfolios';
import AdminNotifications from 'pages/_rd_dashboard/AdminNotifications';
import AdminUsers from 'pages/_rd_dashboard/AdminUsers';

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

// function LandingPage() {
// 	const isMobile = useMediaPredicate(MOBILE_SIZE);
// 	if (isMobile) {
// 		return <MobileSearchPage />;
// 	}
// 	return <PropertySearch />;
// }

const routes = {
	"/property-not-found": PropertyNotFound,
	"/sign-in": SignIn,
	"/sign-up": SignUp,
	"/forgot-password": ForgotPassword,
	"/recover-user/:resetToken": ResetPassword,
	"/use-policy": AcceptableUsePolicy,
	"/disclaimer": Disclaimer,
	"/my-documents": MyDocuments,
	"/dashboard/portfolio": Portfolio,
	"/dashboard/alerts": Alerts,
	"/dashboard/compliance": Compliance,
	"/dashboard/settings": Settings,
	"/dashboard": Portfolio,
	"/admin/top-violations": AdminTopVios,
	"/admin/users": AdminUsers,
	"/admin/portfolios": AdminPortfolios,
	"/admin/notifications": AdminNotifications,
	"/admin/files": AdminFiles,
	"/admin": AdminPortfolios,
	"/file-admin": AdminFilePage,
	"/account-settings": AccountSettings,
	"/backend-search": BackendSearchPage,
	"/search": MobileSearchPage,
	"/airtable-instructions": AirtableInstructionsPage,
	"/cart": CartPage,
	"/checkout": CheckoutPage,
	// "/order-confirmation": ConfirmationPage,
	"/": LandingPage,
};

function NonPropertyRoutes() {
	return Object.keys(routes).map((path) => {
		const Component = routes[path];

		// let hideHeaderSearch = false;
		// if (path === '/') {
		// 	hideHeaderSearch = true;
		// }

		return (
			<Route key={path} path={path}>
				<AppContainer showSidebar={false}>
					<Component />
				</AppContainer>
			</Route>
		);
	});
}

export default NonPropertyRoutes;
