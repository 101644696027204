import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import AdminLayout from 'pages/_rd_dashboard/AdminLayout';

import FilesWidget from 'components/userFiles/FilesWidget';

const AdminFiles = (props) => {
	usePageTitle('Admin | Documents');
	const history = useHistory();
	const {
		isLoggedIn,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
	}));

	useEffect(() => {
		if (!isLoggedIn) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, history]);


	return (
		<AdminLayout>
			<div className="dashboard-portfolio">
				<h1>User Document Administration</h1>
				<div className="pb-4">
					<FilesWidget parentType="admin" />
				</div>
			</div>
		</AdminLayout>
	);
}

export default AdminFiles;
