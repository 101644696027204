import { ASC, DESC, getNextSort } from 'utils/sort';

import './SortableHeader.css';

function SortableHeader({ column, sortField, sortDirection, onSort, defaultSort }) {
	let icon = null;
	let labelClass = '';
	let { formatter } = column;

	let isSortable = true;
	if (formatter === 'ResolutionRequest' || formatter === 'AEU2Link' || formatter === 'AEU20Link') {
		isSortable = false;
	}

	if (isSortable && sortField === column.key && !!sortDirection) {
		if (sortDirection === ASC) {
			labelClass = 'sort-asc';
			//icon = <i className="bi bi-chevron-down" />;
			icon = <i className="bi bi-caret-up-fill" />;
		} else if (sortDirection === DESC) {
			labelClass = 'sort-desc';
			//icon = <i className="bi bi-chevron-up" />;
			icon = <i className="bi bi-caret-down-fill" />;
		}
	}


	const handleSortClick = () => {
		if (!isSortable) {
			return;
		}
		const nextSort = getNextSort(column.key, sortField, sortDirection, defaultSort);
		onSort(nextSort);
	}

	return (
		<th>
			<button className={`table-header-sort-button ${isSortable ? '' : 'is-unsortable'}`} onClick={handleSortClick}>
				<span className={`header-label ${labelClass}`}>{column.label}</span>
				{icon && <span className="header-sort-direction-icon">{icon}</span>}
			</button>
		</th>
	);
}

export default SortableHeader;