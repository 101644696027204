import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRecordDetails } from 'reducers/recordDetailsReducer';
import { getDetailsApiPath, getRecordTitle, isPropertyHidden } from 'utils/recordDetailsUtils';
import Spinner from 'components/Spinner';
import OutboundLink from 'components/OutboundLink';
import StandardModal from 'modals/StandardModal';
import HousingComplaingProblems from 'formatters/HousingComplaintProblems';
import { getOpenDataLink } from 'utils/openDataQueryLinks';

import './RecordDetailsModal.css';

//make underscore character line breakable
const UnderscoreBreak = ({ text }) => {
	let parts = text.split('_');
	let content = [];

	for (let i = 0; i < parts.length; i++) {
		content.push(parts[i]);
		if (i < parts.length - 1) {
			content.push('_');
			content.push(<wbr key={`${i}-${text}`} />);
		}
	}

	return (
		<span>
			{content}
		</span>
	);
}

const RecordDetailsModal = ({ options }) => {
	const dispatch = useDispatch();
	const { id, dataSet } = options;
	const detailsUrlPath = getDetailsApiPath(dataSet);
	const {
		isLoading,
		data,
	} = useSelector(state => ({
		isLoading: state.recordDetails.isLoading,
		data: state.recordDetails.data || {},
	}));

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		dispatch(fetchRecordDetails(id, detailsUrlPath));
	}, [id, detailsUrlPath]);

	let openDataUrl = getOpenDataLink(dataSet, data);

	return (
		<StandardModal
			title={`${getRecordTitle(dataSet)} Details`}
			size="lg"
			closeOnOutsideClick
		>
			{isLoading ? <Spinner /> :
				<div>
					{openDataUrl ? <OutboundLink className="mb-2 d-inline-block" url={openDataUrl} label="View on OpenData" /> : null}
					<table className="table table-striped table-bordered record-details-table">
						<tbody>
							{Object.keys(data).map((key, i) => {
								if (isPropertyHidden(key)) {
									return null;
								}

								let value = data[key];
								
								//ecb complaints have a nested structure. 1 "complaint" has many "problems"
								// http://localhost:3000/property/660%20Madison%20Ave%2C%20New%20York%2C%20NY%2010065/hpdHousingComplaintsOpenData
								if (dataSet === 'hpdHousingComplaints' && key === 'od_hpd_housing_complaint_problems') {
									value = <HousingComplaingProblems problems={data[key]} />;
								}

								return (
									<tr key={key}>		
										{/*<td className="key-cell">{prettifyPropName(key)}</td>*/}
										<td className="key-cell"><UnderscoreBreak text={key} /></td>
										<td className="value-cell">{value || '-'}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			}
		</StandardModal>
	);
};

export default RecordDetailsModal;
