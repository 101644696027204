import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { fetchBinsWithTopViolations } from 'reducers/topViolationsReducer';
import { getTopViolationsExcelExportUrl } from 'data/services/exportService';
import AdminLayout from 'pages/_rd_dashboard/AdminLayout';

import OutboundLink from 'components/OutboundLink';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

const TopVios = (props) => {
	usePageTitle('Admin | Top Violations');
	const dispatch = useDispatch();
	let {
		topViolations,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		topViolations: state.topViolations.data?.data || [],
		isLoading: state.topViolations.isLoading,
		currentPage: state.topViolations.data?.current_page,
		totalPages: state.topViolations.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchBinsWithTopViolations());
	}, [ dispatch]);

	
	return (
		<AdminLayout>
			<div className="dashboard-portfolio">
				<h1>Marketing - Top BINs with Open Violations</h1>
				{isLoading ? <Spinner /> :
					<div className="pb-4">
						<div>
							{topViolations?.length ? <a className="export-link" href={getTopViolationsExcelExportUrl()}>Download Excel</a> : null}
						</div>
						
						<div className="dashboard-table-wrapper">
							<table className="table table-striped">
								<thead><tr>
									<th>BIN</th>
									<th>Address</th>
									<th>Open Violations</th>
								</tr></thead>
								<tbody>
									{topViolations.map((item, i) => (
										<tr key={`${item.bin}-${i}`}>
											<td>
												{item.bin ? (
													<OutboundLink
														url={`http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=${item.bin}&go2=+GO+&requestid=0`}
														label={item.bin}
													/>
												) : '-'}
											</td>
											<td>{item.address || '-'}</td>
											<td>{item.numViolations || '-'}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchBinsWithTopViolations('numViolations|desc', pageNum, 10))}
						/>
					</div>
				}
			</div>
		</AdminLayout>
	);	


}

export default TopVios;
