import { useDispatch, useSelector } from 'react-redux';

import { uploadDataSetItemFile } from 'reducers/fileReducer';

import { openModal, closeModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import './FileUploadButton.css';

function FileUploadButton({ row, dataSet, dataSource }) {
	const dispatch = useDispatch();
	const {
		isLoggedIn,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
	}));

	const onUpload = (file) => {
		dispatch(uploadDataSetItemFile(dataSet, dataSource, row.id, file));
		dispatch(closeModal());
	}

	if (!isLoggedIn) {
		return null;
	}

	return (
		<button
			className="btn-hyperlink file-upload-button"
			onClick={() => dispatch(openModal(MODAL_TYPES.UPLOAD, { onUpload, recordId: row.id, dataSet, dataSource }))}
		>
			<i className="bi bi-cloud-arrow-up" />
			{row.numFiles > 0 && <span className="num-files">{row.numFiles}</span>}
		</button>
  );
}

export default FileUploadButton;
