import { useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import ExternalLinksList from 'components/externalLinks/ExternalLinksList';

// import './ExternalLinks.css';

function ExternalLinks(props) {
	const {
		propertyData,
	} = useSelector(state => ({
		propertyData: state.property.propertyData,
	}));
	usePageTitle('External Resources', propertyData);
	return (
		<div className="property-overview">
			<div className="card">
				<div className="card-header">
					External Resources
				</div>
				<div className="card-body">
					<ExternalLinksList />
				</div>
			</div>
		</div>
	);
}

export default ExternalLinks;
