import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { fetchPortfolio } from 'reducers/portfolioReducer';

import FilesWidget from 'components/userFiles/FilesWidget';

import './MyDocuments.css';

const MyDocuments = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	usePageTitle('My Documents');
	const {
		// isLoading,
		isLoggedIn,
		// portfolio,
		userId,
	} = useSelector(state => ({
		// isLoading: state.files.isLoading || state.files.isUploading,
		isLoggedIn: !!state.user.userData?.id,
		// portfolio: state.portfolio.portfolioData?.data,
		userId: state.user.userData?.id,
	}));

	useEffect(() => {
		if (userId) {
			dispatch(fetchPortfolio(userId));
		}
	}, [userId, dispatch]);

	useEffect(() => {
		if (!isLoggedIn) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, dispatch, history]);

	return (
		<div className="my-documents-page">
			<div className="card">
				<div className="card-header">
					My Documents
				</div>
				<div className="card-body">
					{/*{isLoading && <SpinnerOverlay isLoading={isLoading} />} */}
					<FilesWidget title="" parentType="user" />
{/*					{portfolio?.map(property => (
						<div className="my-4" key={property?.property?.id}>
							<FilesWidget
								parentType="property"
								propertyId={property?.property?.id}
								title={property?.property?.address}
							/>
						</div>
					))}*/}
				</div>
			</div>
		</div>
	);	
};

export default MyDocuments;
