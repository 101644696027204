import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { signUp, clearAuthErrors, signOut } from 'reducers/userReducer';

import Alert from 'components/Alert';
import Spinner from 'components/Spinner';

import './AuthPage.css';


const ValidationMessages = ({ messages }) => {
	if (!messages?.length) {
		return null;
	}

	return messages.map(message => (
		<div key={message} className="invalid-feedback">
			{message}
		</div>
	));
};

const SignUp = (props) => {
	usePageTitle('Sign Up');
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		isSigningUp,
		signUpErrors,
		isLoggedIn,
	} = useSelector(state => ({
		isSigningUp: state.user.isSigningUp,
		signUpErrors: state.user.signUpErrors,
		isLoggedIn: !!state.user.userData?.id,
	}));
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [phone, setPhone] = useState('');
	// const [property, setProperty] = useState('');
	// const onSubmit = () => dispatch(signUp(name, email, password, phone, property));
	const onSubmit = () => dispatch(signUp(name, email, password, phone)).then((res) => {
		if (res?.payload?.userData?.id) { //sign up success
			history.push("/dashboard");
		}
	});
	const onEnter = (event) => {
		if (event.key === 'Enter') {
      onSubmit();
    }
	};

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => dispatch(clearAuthErrors()), []);

	const hasErrors = Object.keys(signUpErrors).length > 0;

	let alreadySignedIn = (
		<div className="sign-in-wrapper">
			<div className="alert alert-success">You're already signed in to your account!</div>
			<div className="input-wrapper">
				<Link to="/dashboard">View your dashboard</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/property">Find a property</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/use-policy">Acceptable Use Policy</Link>
			</div>
			<div className="input-wrapper">
				<button onClick={() => dispatch(signOut())} className="btn btn-secondary">Sign Out</button>
			</div>
		</div>
	);

	let signUpForm = (
		<div className="sign-in-wrapper">
			{hasErrors && <Alert color='danger' text='Please fix the errors below and try again' />}

			<div className="input-wrapper">
				<label className="form-label">Email</label>
				<input
					type="text"
					value={email}
					onChange={ev => setEmail(ev.target.value)}
					className={`form-control ${signUpErrors.email ? ' is-invalid' : ''}`}
					placeholder="Email"
					disabled={isSigningUp}
					onKeyDown={onEnter}
					autoFocus
				/>
				<ValidationMessages messages={signUpErrors.email} />
			</div>

			<div className="input-wrapper">
				<label className="form-label">Name</label>
				<input
					type="text"
					value={name}
					onChange={ev => setName(ev.target.value)}
					className={`form-control ${signUpErrors.name ? ' is-invalid' : ''}`}
					placeholder="Full Name"
					disabled={isSigningUp}
					onKeyDown={onEnter}
				/>
				<ValidationMessages messages={signUpErrors.name} />
			</div>

			<div className="input-wrapper">
				<label className="form-label">Password</label>
				<input
					type="password"
					value={password}
					onChange={ev => setPassword(ev.target.value)}
					className={`form-control ${signUpErrors.password ? ' is-invalid' : ''}`}
					placeholder="Password"
					disabled={isSigningUp}
					onKeyDown={onEnter}
				/>
				<ValidationMessages messages={signUpErrors.password} />
			</div>

			<div className="input-wrapper">
				<label className="form-label">Phone (Optional)</label>
				<input
					type="text"
					value={phone}
					onChange={ev => setPhone(ev.target.value)}
					className={`form-control ${signUpErrors.phone ? ' is-invalid' : ''}`}
					placeholder="Phone"
					disabled={isSigningUp}
					onKeyDown={onEnter}
				/>
				<ValidationMessages messages={signUpErrors.phone} />
			</div>

{/*			<div className="input-wrapper">
				<label className="form-label">Property Address</label>
				<input
					type="text"
					value={property}
					onChange={ev => setProperty(ev.target.value)}
					className={`form-control ${signUpErrors.property ? ' is-invalid' : ''}`}
					placeholder="Property Address"
					disabled={isSigningUp}
					onKeyDown={onEnter}
				/>
				<ValidationMessages messages={signUpErrors.property} />
			</div>*/}

			<div className="input-wrapper">
				<button onClick={onSubmit} disabled={isSigningUp} className="btn btn-primary">Sign Up</button>
			</div>

			<div className="input-wrapper">
				<Link to="/sign-in">Already have an account? Sign in</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/use-policy">Acceptable Use Policy</Link>
			</div>
		</div>
	);

	return (
		<div className="auth-page">
			<div className="auth-page-content">
				<h1>Sign Up</h1>
				{isSigningUp && <Spinner />}
				{isLoggedIn ? alreadySignedIn : signUpForm}
			</div>
		</div>
	);	
}

export default SignUp;
