import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uploadPropertyFile } from 'reducers/fileReducer';
import { closeModal } from 'reducers/modalReducer';

import StandardModal from 'modals/StandardModal';
import Spinner from 'components/Spinner';


const PropertyFileUploadModal = ({ options }) => {
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState(null);
	const {
		isLoading,
	} = useSelector(state => ({
		isLoading: state.files.isUploading || state.files.isLoading,
	}));

	const onUpload = (file) => {
		dispatch(uploadPropertyFile(options.propertyId, file)).then(() => {
			dispatch(closeModal());
		});
	};

	const onFileChange = (e) => {
		let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    setSelectedFile(formData);
	}

	return (
		<StandardModal
			title={"Upload Document"}
			size="md"
			closeOnOutsideClick
		>
			<div>
				{isLoading ? <Spinner /> : 
					<div className="upload-wrapper">
						<div>Upload a File</div>
						<div>{options.address}</div>
						<input type="file" onChange={onFileChange} className="" />
						<button className="btn btn-primary btn-sm" disabled={!selectedFile} onClick={() => onUpload(selectedFile)}>Upload</button>
					</div>
				}
			</div>
		</StandardModal>
	);
};

export default PropertyFileUploadModal;
