//numeric key is the 'user_group'
let plansByUserGroup = {
	'1': 'Member',
	'2': 'Admin',
	'3': 'Client',
	'4': 'Employee',
}

export function getPlan(userGroup) {
	if (!userGroup) {
		return null;
	}
	return plansByUserGroup[userGroup] || null;
}

export function isAdminUser(user) {
	return getPlan(user?.user_group) === 'Admin';
}