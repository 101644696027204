import { get } from 'data/services/apiService';

const GET_INFRACTION_CODE_DETAILS_REQUEST = 'GET_INFRACTION_CODE_DETAILS_REQUEST';
const GET_INFRACTION_CODE_DETAILS_SUCCESS = 'GET_INFRACTION_CODE_DETAILS_SUCCESS';
const GET_INFRACTION_CODE_DETAILS_ERROR = 'GET_INFRACTION_CODE_DETAILS_ERROR';

const initialState = {
	codeId: null,
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchInfractionCodeDetails = (codeId) => (dispatch) => {
	let url = `https://ecb.vio.nyc/search/${codeId}`;
	dispatch(infractionCodeDetailsRequest(codeId));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(infractionCodeDetailsRequestSuccess(result))
		},
		onError: error => dispatch(infractionCodeDetailsRequestError(error)),
	});
};

export const infractionCodeDetailsRequest = (codeId) => ({
	type: GET_INFRACTION_CODE_DETAILS_REQUEST,
	payload: {
		codeId,
		isLoading: true,
		data: null,
	}
});

export const infractionCodeDetailsRequestSuccess = (data) => ({
	type: GET_INFRACTION_CODE_DETAILS_SUCCESS,
	payload: {
		isLoading: false,
		hasError: false,
		data,
		error: null,
	}
});

export const infractionCodeDetailsRequestError = (error) => ({
	type: GET_INFRACTION_CODE_DETAILS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const ecbInfractionCodeReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_INFRACTION_CODE_DETAILS_REQUEST:
		case GET_INFRACTION_CODE_DETAILS_SUCCESS:
		case GET_INFRACTION_CODE_DETAILS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default ecbInfractionCodeReducer;
