import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import { regenerateApiKey, fetchUser, updateUser } from 'reducers/userReducer';
import { getPlan } from 'utils/subscriptionPlans';
import { isValidEmail } from 'utils/validators';
import Alert from 'components/Alert';
import Spinner from 'components/Spinner';

import './AccountSettings.css';

const AccountSettings = (props) => {
	usePageTitle('Account Settings');
	let dispatch = useDispatch();
	let {
		isLoggedIn,
		apiKey,
		planName,
		isRegeneratingApiKey,
		apiKeyRegenError,
		isLoading,
		// isUpdating,
		userData,
		authToken,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		apiKey: state.user.userData?.api_key?.api_key || '',
		planName: getPlan(state.user.userData?.user_group),
		isRegeneratingApiKey: state.user.isRegeneratingApiKey,
		apiKeyRegenError: state.user.apiKeyRegenError,
		isLoading: state.user.isLoading || state.user.isUpdating,
		// isUpdating: state.user.isUpdating,
		userData: state.user.userData,
		authToken: state.user.authToken,
	}));

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [isEmailConfRequired, setIsEmailConfRequired] = useState(false);

	useEffect(() => {
		if (userData?.email) {
			setEmail(userData.email);
			setIsEmailValid(isValidEmail(userData.email));
		}
		if (userData?.name) {
			setName(userData.name);
		}
		if (userData?.phone) {
			setPhone(userData.phone);
		}
	}, [userData]);

	useEffect(() => {
		if (authToken) {
			dispatch(fetchUser(authToken));
		}
	}, [dispatch, authToken]);

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	const onSubmit = () => {
		setIsSuccess(false);
		dispatch(updateUser({
			name,
			email,
			phone,
		})).then((res) => {
			if (res?.type === "UPDATE_USER_SUCCESS") {
				console.log('res', res)
				if (res.apiResponse.email_change_verification_required) {
					setIsEmailConfRequired(true);
				} else { 
					setIsEmailConfRequired(false);
				}
				setIsSuccess(true);	
			}
		});
	};

	//TODO: redirect to login page if user isn't logged in

	const userForm = (
		<div className="p-3">
			<div className="form-group mb-3">
				<label>Name</label>
				<input
					type="text"
					className="form-control"
					value={name}
					disabled={isLoading}
					onChange={ev => setName(ev.target.value)}
					placeholder="Your name"
				/>
			</div>
			<div className="form-group mb-3">
				<label>Email</label>
				<input
					type="email"
					placeholder="Email"
					className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
					value={email}
					disabled={isLoading}
					onChange={(ev) => onChangeEmail(ev.target.value)}
					autoFocus
				/>
			</div>
			<div className="form-group mb-3">
				<label>Phone Number</label>
				<input
					type="text"
					className="form-control"
					value={phone}
					disabled={isLoading}
					onChange={ev => setPhone(ev.target.value)}
					placeholder="(555)-555-5555"
				/>
			</div>
			<div className="form-group mb-3">
				<button className="btn btn-primary" onClick={onSubmit}>Save</button>
			</div>
		</div>
	);

	const successAlert = (
		<Alert color={isEmailConfRequired ? 'info' : 'success'}>
			{isEmailConfRequired ? (
				<div>Email verification required to complete this update. Check your email for instructions.</div>
			) : (
				<div>User info updated successfully!</div>
			)}
		</Alert>
	);

	const accountInfoCard = (
		<div className="card">
			<div className="card-header">
				Account Info
			</div>
			<div>
				{userForm}
			</div>
		</div>
	);

	const airtableCard = (
		<div className="card">
			<div className="card-header">
				Airtable Access
			</div>
			<div className="card-body">
				{isRegeneratingApiKey && <Spinner />}
				<div className="form-group mb-4">
					{apiKeyRegenError && <Alert text={apiKeyRegenError} color="danger" />}
					<label className="">Airtable Key</label>
					<div>
						<input type="text" readOnly className="form-control-plaintext api-key-input" value={apiKey} />
					</div>
				</div>
				<button disabled={isRegeneratingApiKey} className="btn btn-primary" onClick={() => dispatch(regenerateApiKey())}>
					Regenerate Airtable Key
				</button>
				<div className="mt-3 mb-3">
					<Link to="/airtable-instructions">How to connect to Airtable</Link>
				</div>
			</div>
		</div>
	);

	const planCard = (
		<div className="card">
			<div className="card-header">
				Plan Details
			</div>
			<div className="card-body">
				<div className="mb-4">
					{`You're on the ${planName} plan`}
				</div>
				<button disabled className="btn btn-primary" onClick={() => {}}>
					Change Plan
				</button>
			</div>
		</div>
	)

	let loggedInContent = (
		<div className="card-wrapper">
			{isSuccess && successAlert}
			{accountInfoCard}
			{airtableCard}
			{planCard}
		</div>
	);

	let loggedOutContent = (<div>You're not logged in!</div>);

	return (
		<div className="account-settings">
			<div className="account-settings-content">
				<h2 className="text-center">Account Settings</h2>
				{isLoading ? <Spinner /> : 
					<div>
						{isLoggedIn ? loggedInContent : loggedOutContent}
					</div>
				}
			</div>
		</div>
	);	


}

export default AccountSettings;
