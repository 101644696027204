import { Switch } from "react-router-dom";

import DataSetRoutes from "routes/DataSetRoutes";
import NonPropertyRoutes from "routes/NonPropertyRoutes";
import PropertyRoutes from "routes/PropertyRoutes";
import RecordDetailRoutes from "routes/RecordDetailRoutes";

function Routes() {
	return (
		<Switch>
			{RecordDetailRoutes()}
			{DataSetRoutes()}
			{PropertyRoutes()}
			{NonPropertyRoutes()}
		</Switch>
	);
}

export default Routes;
