const EXPAND_SIDEBAR_GROUPING = 'EXPAND_SIDEBAR_GROUPING';
const COLLAPSE_SIDEBAR_GROUPING = 'COLLAPSE_SIDEBAR_GROUPING';

const initialState = {
	expandedItems: {},
};


export const expand = (sidebarGrouping) => (dispatch, getState) => {
	let state = getState();
	let expandedItems = state.sidebar.expandedItems;
	expandedItems[sidebarGrouping] = true;
	return dispatch({
		type: EXPAND_SIDEBAR_GROUPING,
		payload: {
			expandedItems
		}
	});
};

export const collapse = (sidebarGrouping) => (dispatch, getState) => {
	let state = getState();
	let expandedItems = state.sidebar.expandedItems;
	delete expandedItems[sidebarGrouping];
	return dispatch({
		type: COLLAPSE_SIDEBAR_GROUPING,
		payload: {
			expandedItems
		}
	});
};

const sidebarReducer = (state = initialState, action) => {
	switch (action.type) {
		case EXPAND_SIDEBAR_GROUPING:
		case COLLAPSE_SIDEBAR_GROUPING:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default sidebarReducer;
