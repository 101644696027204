export const DESC = 'desc';
export const ASC = 'asc';

export const DEFAULT_OWNERS_SORT = { field: 'date', direction: DESC };
export const DEFAULT_DATASET_SORT = { field: 'id', direction: DESC };
export const RECENTLY_CREATED = { field: 'created_at', direction: DESC };

export function getNextDirection(currentDirection) {
	if (currentDirection === ASC) {
		return DESC;
	} else if (currentDirection === DESC) {
		return null;
	}
	return ASC;
}

export function getNextSort(nextField, currentField, currentDirection, defaultSort=DEFAULT_DATASET_SORT) {
	if (nextField === currentField) {
		let nextDirection = getNextDirection(currentDirection);
		if (nextDirection === null) {
			if (nextField === defaultSort.field) {
				//handle getting stuck in default_field|desc loop
				return { field: nextField, direction: ASC };
			}
			return defaultSort;
		}
		return { field: nextField, direction: nextDirection };
	} else if (nextField) {
		return { field: nextField, direction: ASC };
	}

	return defaultSort;

	//is this a good default? should it be (id desc)?, (created_at desc)?
	// return { field: null, direction: null };
}

export function sortToString(sortObject={}, defaultSort=DEFAULT_DATASET_SORT) {
	const { field, direction } = sortObject;
	if (field && direction && (direction === ASC || direction === DESC)) {
		return `${field}|${direction}`;
	}
	return `${defaultSort.field}|${defaultSort.direction}`;
}
