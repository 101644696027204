/*
	list of navigation items for sidebar in desktop app, and mobile nav page for mobile
*/
export const getNavRoutes = (address, isLoggedIn) => {
	let routes = [
		{
			label: 'Property Overview',
			url: `/property/${address}/overview`,
		},
		// {
		// 	label: 'Tasks',
		// 	url: `/property/${address}/tasks`, 
		// },
		// {
		// 	label: 'Representatives',
		// 	url: `/property/${address}/representatives`,
		// },
		{
			label: 'My Documents',
			url: `/my-documents`,
			requireLogin: true,
		},
		{
			label: 'Request Documents',
			url: `/property/${address}/property-documents`,
		},
		{
			label: 'Compliance Dates',
			url: `/property/${address}/compliance-dates`,
		},
		{
			label: 'External Resources',
			url: `/property/${address}/external-links`,
		},
		// {
		// 	label: 'Notifications',
		// 	url: `/property/${address}/notifications`, 
		// },
		// {
		// 	label: 'Backend Search',
		// 	url: `/backend-search`, 
		// },
	];

	return routes.filter(route => {
		if (route.requireLogin && !isLoggedIn) {
			return false;
		}
		return true;
	});
};