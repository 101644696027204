import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import MonthCalendar from 'view/components/complianceCalendar/MonthCalendar';

import Spinner from 'components/Spinner';

import './CalendarPage.css';


const CalendarPage = (props) => {
	const dispatch = useDispatch();
	const {
		propertyId,
		isLoading,
		propertyData,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoading: state.property.isLoading,
		propertyData: state.property.propertyData,
	}));

	usePageTitle('Calendar', propertyData);


	const onEventClick = (e) => {
		return dispatch(openModal(MODAL_TYPES.CALENDAR_EVENT, { 
			eventTitle: e.event.title,
		}));
	}

	return (
		<div className="calendar-page">
			{isLoading ? <Spinner /> : <MonthCalendar height={500} propertyId={propertyId} onEventClick={onEventClick} /> }
		</div>
	);	
}

export default CalendarPage;
