import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { fetchFirstLoadTasks } from 'reducers/firstLoadTasksReducer';

import FirstLoadTasksCard from 'components/firstLoadTasks/FirstLoadTasksCard';

import './Tasks.css';

const Tasks = (props) => {
	const dispatch = useDispatch();

	let {
		propertyId,
		propertyData,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		propertyData: state.property.propertyData,
	}));

	usePageTitle('Tasks', propertyData);

	useEffect(() => {
		if (propertyId) {
			dispatch(fetchFirstLoadTasks(propertyId));
		}
	}, [propertyId, dispatch]);

	return (
		<div className="first-load-tasks">
			<FirstLoadTasksCard />
		</div>
	);	
}

export default Tasks;
