import './Badge.css';

function Badge({ count, className, color='danger', children }) {
	if (children) {
		return (
			<span className={`vio-badge badge bg-${color} ${className}`}>{children}</span>
		);
	}


	if (!count || count === '0') {
		return null;
	}

	return (
		<span className={`vio-badge badge bg-${color} ${className}`}>{count}</span>
	);
}

export default Badge;