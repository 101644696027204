import './FilterButtons.css';

function FilterButtons(props) {
	let {
		onFilter,
		filteredStatus,
		numOpen,
		numClosed,
		isDisabled,
	} = props;

	let openText = `${numOpen} Open`;
	let closedText = `${numClosed} Resolved`;
	let allText = `${numOpen + numClosed} All`;

	//if data is missing but not zero
	if (!numOpen && !numOpen !== 0 && !numClosed && !numClosed !== 0) {
		openText = 'Open';
		closedText = 'Resolved';
		allText = 'All';
	}


	//todo: handle loading state, nums will be undefined/null/nan
	return (
		<div className="btn-group">
			<button disabled={isDisabled} className={`filter-button btn btn-sm btn-${filteredStatus === 'open' ? '' : 'outline-'}danger px-2`} onClick={() => onFilter('open')}>{openText}</button>
			<button disabled={isDisabled} className={`filter-button btn btn-sm btn-${filteredStatus === 'closed' ? '' : 'outline-'}success`} onClick={() => onFilter('closed')}>{closedText}</button>
			<button disabled={isDisabled} className={`filter-button btn btn-sm btn-${filteredStatus === 'all' ? '' : 'outline-'}success px-3`} onClick={() => onFilter('all')}>{allText}</button>
		</div>
	);
}

export default FilterButtons;
