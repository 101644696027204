import { DATA_SET_OPTIONS } from 'pages/backendSearch/backendSearchConstants';

export const DataSetSelectDesktop = ({ selectedDataSet, onSelect }) => {
	return (
		<div className="data-set-select">
			<h2>Select Data Set</h2>
			<div className="data-set-options-desktop">
				{DATA_SET_OPTIONS.map((option) => (
					<label key={option.value} className={`data-set-radio-option ${selectedDataSet === option.value ? 'is-selected' : ''}`}>
						<input
							type="radio"
							name="dataSet"
							key={option.value}
							value={option.value}
							checked={selectedDataSet === option.value}
							onChange={onSelect}
						/>
						<span className="label-text">{option.label}</span>
					</label>
				))}				
			</div>
		</div>
	);
}

export const DataSetSelectMobile = ({ selectedDataSet, onSelect }) => {
	return (
		<select 
			className="form-select" 
			placeholder="Select Data Set" 
			value={selectedDataSet} 
			onChange={onSelect}
			autoFocus
		>
			{DATA_SET_OPTIONS.map((option) => (
				<option key={option.value} value={option.value}>{option.label}</option>
			))}
		</select>
	);
}