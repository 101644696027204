import { useState } from 'react';
// import { useDispatch } from 'react-redux';

import { getEmailExportUrl } from 'data/services/exportService';
import { isValidEmail } from 'utils/validators';
import StandardModal from 'modals/StandardModal';

// import './ExportEmailReportModal.css';

const ExportEmailReportModal = ({ options }) => {
	// const dispatch = useDispatch();
	const { propertyId, dataSetArray } = options;
	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');

	const onSend = () => {
		let url = getEmailExportUrl(propertyId, dataSetArray, email);
		console.log('url', url);
		window.open(url, '_blank').focus();
	}

	let footerButtons = [
		<button key="excel" className="btn btn-primary" disabled={!isEmailValid} onClick={() => onSend()}>Send</button>
	];

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	let modalContent = (
		<div>
			<div className="mb-3">
				Enter email address to receive selected report(s)
			</div>
			<div>
				<div className="form-group">
					<label>Email Address</label>
					<input
						type="email"
						placeholder="Email"
						className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
						required
						value={email}
						onChange={(ev) => onChangeEmail(ev.target.value)}
						autoFocus
					/>
				</div>
			</div>
		</div>
	);

	return (
		<StandardModal
			title="Email Report"
			size="md"
			closeOnOutsideClick
			footerButtons={footerButtons}
			closeLabel="Cancel"
		>
			{modalContent}
		</StandardModal>
	);
};

export default ExportEmailReportModal;
