import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

const initialState = {
	notificationsData: null,
	isLoading: false,
	error: null,
};

export const fetchNotifications = (userId, sort='created_at|desc', page=1, perPage=10) => (dispatch, getState) => {
	let state = getState();
	let url = `${API_URL}/api/users/${userId}/notifications?sort=${sort}&page=${page}&per_page=${perPage}`;
	dispatch(getNotificationsRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getNotificationsSuccess(result))
		},
		onError: error => dispatch(getNotificationsError(error)),
	});
};

export const getNotificationsRequest = () => ({
	type: GET_NOTIFICATIONS_REQUEST,
	payload: {
		isLoading: true,
		notificationsData: null,
	}
});

export const getNotificationsSuccess = (data) => ({
	type: GET_NOTIFICATIONS_SUCCESS,
	payload: {
		isLoading: false,
		notificationsData: data,
		error: null,
	}
});

export const getNotificationsError = (error) => ({
	type: GET_NOTIFICATIONS_ERROR,
	payload: {
		isLoading: false,
		notificationsData: null,
		error: error,
	}
});

const portfolioReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_NOTIFICATIONS_REQUEST:
		case GET_NOTIFICATIONS_SUCCESS:
		case GET_NOTIFICATIONS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default portfolioReducer;
