import { BOROUGHS } from 'utils/boroughs';


export const GOOGLE_MAPS_API_KEY = 'AIzaSyBBqQ6GSDSaPtXXR0ksIIQCCmma48UY8Lw'; //new violerts 


const HOUSE_NUM_INDEX = 0;
const STREET_NAME_INDEX = 1;
const MANHATTAN_BOROUGH_INDEX = 2;
const OUTER_BOROUGH_INDEX = 3;

export const getHouseNumber = (place) => {
	return place.address_components[HOUSE_NUM_INDEX].long_name;
};

export const getStreetName = (place) => {
	return place.address_components[STREET_NAME_INDEX].long_name;
};

const isInBorough = (place, borough) => {
	if (!place || !borough) {
		return false;
	}
	let boroughComponent = place?.address_components?.[OUTER_BOROUGH_INDEX];
	if (borough.toLowerCase() === 'manhattan') {
		//special case for manhattan addresses. borough is in a different spot in the array of address parts
		boroughComponent = place?.address_components?.[MANHATTAN_BOROUGH_INDEX];
	}

	//use .includes() because we sometimes see "Bronx" vs "The Bronx"
	return !!boroughComponent?.long_name?.toLowerCase()?.includes(borough.toLowerCase());
}

export const getBoroughNum = (place) => {
	if (isInBorough(place, 'Manhattan')) {
		return BOROUGHS.MANHATTAN;
	}
	//use "Bronx" rather than "The Bronx". the "The" is sometimes missing from google's addresses
	if (isInBorough(place, 'Bronx')) {
		return BOROUGHS.BRONX;
	}
	if (isInBorough(place, 'Brooklyn')) {
		return BOROUGHS.BROOKLYN;
	}
	if (isInBorough(place, 'Queens')) {
		return BOROUGHS.QUEENS;
	}
	if (isInBorough(place, 'Staten Island')) {
		return BOROUGHS.STATEN_ISLAND;
	}

	return -1; //todo: this is sort of an error state, handle it
};

export const getAddressWithoutUSA = (place) => {
	return place.formatted_address.replace(', USA', '');
}
