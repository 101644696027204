import DateFormatter from 'formatters/DateFormatter';
import OutboundLink from 'components/OutboundLink';

function CalendarLink(props) {
	let { text, rowData, propertyData } = props;
	let { address } = propertyData;
	let violationNum = rowData.ecb_violation_number;
	let description = rowData.violation_description;
	let details = 'ECB Violation Hearing'; //ECB violation is hardcoded. currently its the only thing that can be added to a calendar

	let hearingEndTime = parseInt(rowData.hearing_time) + 100; //+100 for 1 hour later
	let startDate = `${rowData.hearing_date}T${rowData.hearing_time}`;
	let endDate = `${rowData.hearing_date}T${hearingEndTime}`;
	let hearingSchedule = `${startDate}%2F${endDate}`; // %2F is a url encoded forward slash: "/"

	let baseUrl = 'https://calendar.google.com/calendar/render';
	let href = `${baseUrl}?action=TEMPLATE&dates=${hearingSchedule}&details=${details}%0AProperty: ${address}%0AViolation Number: ${violationNum}%0ADescription: ${description}&text=${details}`;

	return (
		<OutboundLink url={href}>
			<DateFormatter text={text} /> <i className="bi bi-calendar2-plus" />
		</OutboundLink>
	);
}

export default CalendarLink;
