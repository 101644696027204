import { useState } from 'react';

import Badge from 'components/Badge';
import OutboundLink from 'components/OutboundLink';

import './ComplianceDatesCollapse.css';

function buildCalendarLink(address, complianceItem, sectionName) {
	let { date, title } = complianceItem;
	title = title || 'Compliance date';
	let formattedDate = date.replaceAll('-', '');
	let dates = `${formattedDate}%2F${formattedDate}`;
	let baseUrl = 'https://calendar.google.com/calendar/render';
	return `${baseUrl}?action=TEMPLATE&dates=${dates}&details=${title}%0ADepartment: ${sectionName}%0AProperty: ${address}&text=${title}`;
}

const CalendarLink = ({ address, complianceItem, sectionName }) => {
	if (!complianceItem.month || !complianceItem.day) {
 		return 'TBD'
	} else if (!address || !complianceItem.date) {
		return `${complianceItem.month}/${complianceItem.day}`;
	}

	return (								
		<OutboundLink url={buildCalendarLink(address, complianceItem, sectionName)}>
			{`${complianceItem.month}/${complianceItem.day}`}
		</OutboundLink>
	);
}

const ComplianceDatesCollapse = ({ sectionName, data, address }) => {
	let [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<div className="compliance-dates-collapse">
			<div
				onClick={() => setIsCollapsed(!isCollapsed)}
				className="compliance-checklist-header"
			>
				<span>{isCollapsed ? '+' : '-'}</span> <strong>{sectionName}</strong> <Badge count={data.length} color="danger" />
			</div>
			{!isCollapsed && 
				<div className="compliance-checklist-section">
					{data.map((item, i) => (
						<div key={i} className="compliance-checklist-item">
							<div className="compliance-checklist-date">
								<CalendarLink complianceItem={item} address={address} sectionName={sectionName}/>
							</div>
							<div className="compliance-checklist-text">{item.title}</div>
						</div>
					))}
				</div>
			}
		</div>
	);
}

export default ComplianceDatesCollapse;
