import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { fetchPortfolio } from 'reducers/portfolioReducer';
import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import MonthCalendar from 'view/components/complianceCalendar/MonthCalendar';
import ComplianceDatesChecklist from 'components/complianceCalendar/ComplianceDatesChecklist';

import Spinner from 'components/Spinner';
 


const Compliance = (props) => {
	usePageTitle('Compliance');
	const dispatch = useDispatch();
	let {
		// propertyData,
		isLoading,
		userId,
		portfolio,
		// isLoadingPortfolio,
		// currentPage,
		// totalPages,
	} = useSelector(state => ({
		// propertyData: state.property.propertyData,
		isLoading: state.property.isLoading || state.portfolio.isLoading,
		userId: state.user.userData?.id,
		portfolio: state.portfolio.portfolioData?.data || [],
		// isLoadingPortfolio: state.portfolio.isLoading,
		// currentPage: state.portfolio.portfolioData?.current_page,
		// totalPages: state.portfolio.portfolioData?.last_page,
	}));

	const [propertyId, setPropertyId] = useState('');


	useEffect(() => {
		return dispatch(fetchPortfolio(userId)).then((action) => {
			let firstProperty = action?.payload?.portfolioData?.data?.[0]?.property;
			if (firstProperty?.id) {
				setPropertyId(firstProperty.id);	
			}
		});
	}, [userId, dispatch]);


	// useEffect(() => {
	// 	setPropertyId(propertyData?.id);
	// }, [propertyData]);

	const onEventClick = (e) => {
		return dispatch(openModal(MODAL_TYPES.CALENDAR_EVENT, { 
			eventTitle: e.event.title,
		}));
	}

	const onSelectProperty = (propertyId) => {
		setPropertyId(propertyId);
	}

	return (
		<DashboardLayout>
			<div className="">
				<h1>Compliance</h1>
				<div>
					{isLoading ? <Spinner /> : 
					<div>
						<div className="mb-3">
							<select 
								className="form-select" 
								placeholder="Select Property" 
								value={propertyId} 
								onChange={(ev) => onSelectProperty(ev.target.value)}
							>
								{portfolio.map((folio) => (
									<option key={folio.property.id} value={folio.property.id}>{folio.property.address}</option>
								))}
							</select>
						</div>

						<MonthCalendar height={500} propertyId={propertyId} onEventClick={onEventClick} /> 
						<ComplianceDatesChecklist propertyId={propertyId} hideSpinner />
					</div>
					}
				</div>
			</div>
		</DashboardLayout>
	);	


}

export default Compliance;




