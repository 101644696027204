import './StatusBadge.css';

function getColor(isOpen) {
	if (isOpen === 1) {
		return 'danger';
	} else if (isOpen === 0) {
		return 'success';
	}
	return 'secondary';
}

function StatusBadge(props) {
	const { text, rowData } = props;
	const color = getColor(rowData?.isOpen);

	return (
		<span className={`status-badge badge bg-${color}`}>{text}</span>
	);
}

export default StatusBadge;
