import { Link } from "react-router-dom";

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import GoogleAddressSearch from 'components/addressSearch/GoogleAddressSearch';
import BlockAndLotSearch from 'components/addressSearch/BlockAndLotSearch';

import './HeaderSearch.css';

const HeaderSearch = (props) => {
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const hideHeaderSearch = props.hideHeaderSearch;
	const showHeaderHint = props.showHeaderHint;

	if (isMobile) {
		return (
			<span className="mobile-search-link me-3">
				<Link to="/search"><i className="bi bi-search" /></Link>
			</span>
		);
	}

	//desktop only
	return (
		<div className="app-header-desktop-search">
			{!hideHeaderSearch && <GoogleAddressSearch hideLabel placeholder="Address" showHeaderHint={showHeaderHint} />}
			{!hideHeaderSearch && <BlockAndLotSearch hideLabel />}
		</div>
	);
};

export default HeaderSearch;
