import Pusher from 'pusher-js';

const PUSHER_APP_KEY = 'b8c608fe1acb92d46ba6';
const APP_CLUSTER = 'us2';

//singleton
let pusher = null;

export function initPusher() {
	if (pusher === null) {
		pusher = new Pusher(PUSHER_APP_KEY, {
			cluster: APP_CLUSTER,
		});
	}
}

export function unsubscribe(propertyId) {
	if (pusher && propertyId) {
		let channelName = `properties.${propertyId}`;
		pusher.unsubscribe(channelName);
		// console.log("unsubscribe: " + channelName);
	}
}

export function subscribe({ propertyId, onFirstLoadTaskComplete, onUpdate }) {
	if (pusher === null) {
		initPusher();
	}

	if (propertyId) {
		let channelName = `properties.${propertyId}`;
		let channel = pusher.subscribe(channelName);

		// console.log("Subscribed to updates: " + channelName);
		// console.log("channel: ", channel);

		if (typeof onFirstLoadTaskComplete === 'function') {
			channel.bind('App\\Events\\PropertyFirstLoadTaskComplete', onFirstLoadTaskComplete);
		}

		if (typeof onUpdate === 'function') {
			channel.bind('App\\Events\\PropertyDatasetUpdated', onUpdate);
		}
	}

}


