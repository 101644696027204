import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { addItemToCart } from 'reducers/cartReducer';
import { isItemInCart } from 'selectors/cartSelectors';
import { closeModal } from 'reducers/modalReducer';

import Alert from 'view/components/Alert';
import StandardModal from 'modals/StandardModal';

// import './AddToCartModal.css';

const AddToCartModal = ({ options }) => {
	const dispatch = useDispatch();
	const { documentName, extra } = options;
	const {
		address,
		propertyId,
		alreadyInCart,
	} = useSelector(state => ({
		address: state.property.propertyData?.address,
		propertyId: state.property.propertyData?.id,
		alreadyInCart: isItemInCart(state, state.property.propertyData?.address, documentName, extra),
	}));

	const addToCart = () => dispatch(addItemToCart({ address, documentName, propertyId, extra }));

	const reqForm = (
		<div>
			<div className="form-group">
				<label>Document</label>
				<input type="text" className="form-control" value={documentName} disabled />
			</div>
			{extra && (
				<div className="form-group">
					<label>Details</label>
					<input type="text" className="form-control" value={extra} disabled />
				</div>
			)}
			<div className="form-group">
				<label>Address</label>
				<input type="text" className="form-control" value={address} disabled />
			</div>
		</div>
	);

	const addedToCartAlert = (
		<Alert color="success">
			<p><strong>Added to cart!</strong></p>
			<div>{`A request for ${documentName} document(s) for ${address} has been added to your cart.`}</div>
			<div><Link to="/cart" onClick={() => dispatch(closeModal())}>View Cart</Link></div>
		</Alert>
	);

	return (
		<StandardModal
			title="Add Document to Cart"
			size="md"
			closeOnOutsideClick
			onConfirm={addToCart}
			disableConfirm={alreadyInCart}
			confirmText="Add to Cart"
			closeLabel="Close"
		>
			<div className="docuemnt-request-modal-content">
				{alreadyInCart && addedToCartAlert}
				<div>
					Add the following document to your cart?
				</div>
				{reqForm}
			</div>
		</StandardModal>
	);
};

export default AddToCartModal;
