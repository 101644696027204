import { VENDOR_LINKS } from 'utils/propertyLinks';

import OutboundLink from 'components/OutboundLink';

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import './VendorsCard.css';

function VendorsCard() {
	const links = [
		{
			label: 'Architecture',
			imageFile: 'aapc-monogram',
			url: VENDOR_LINKS.AAPC,
		},
		{
			label: 'Construction',
			imageFile: 'arkco-monogram',
			url: VENDOR_LINKS.ARKCO,
		},
		{
			label: 'Title Search',
			imageFile: 'az-title-monogram',
			url: VENDOR_LINKS.AzTitle,
		},
		{
			label: 'Inspections',
			imageFile: 'azark-monogram',
			url: VENDOR_LINKS.AZARK,
		},
		{
			label: 'Expediting',
			imageFile: 'bvs-monogram',
			url: VENDOR_LINKS.BVS,
		},
		{
			label: 'Printing',
			imageFile: 'vps-monogram',
			url: VENDOR_LINKS.VPS,
		},

	]

	return (
		<div className="overview-card overview-card-small vendors-card">
			<div className="overview-card-content">
				<div className="fw-bold">Vendors</div>
				<div className="vendor-links">
					{links.map(link => (
						
							<OutboundLink className={`vendor-link`} noIcon url={link.url} key={link.url}>
								<span>{link.label}</span>
								<img className="" src={`/${link.imageFile}.png`} alt={`${link.imageFile}`} />
							</OutboundLink>
						
					))}
				</div>

			</div>
		</div>
	);
}

export default VendorsCard;
