import { useEffect, useMemo } from 'react';
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { fetchFirstLoadTasks } from 'reducers/firstLoadTasksReducer';

import OutboundLink from 'components/OutboundLink';
import Tooltip from 'components/Tooltip';
import Spinner from 'components/Spinner';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';


import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import './PropertyToolbar.css';

function PropertyToolbar(props) {
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const isLandingPage = location.pathname === '/';
	const pathParts = location?.pathname?.split('/') || [];
	const currentRoute = pathParts[3];
	const isMobileNavOpen = currentRoute === 'nav';
	const { address } = useParams();
	const {
		propertyId,
		propertyData,
		firstLoadTasks,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		propertyData: state.property.propertyData,
		firstLoadTasks: state.firstLoadTasks.data,
	}));

	useEffect(() => {
		if (propertyId) {
			dispatch(fetchFirstLoadTasks(propertyId));
		}
	}, [propertyId, dispatch]);

	const areFirstLoadTasksComplete = useMemo(() => {
		if (Array.isArray(firstLoadTasks)) {
			return firstLoadTasks.every(task => task.complete === 1);
		}
		return false;
	}, [firstLoadTasks]);

	//dummy toolbar if desktop landing page
	if (isLandingPage && !isMobile) {
		return (
			<div className="toolbar navbar navbar-light bg-light">
				<div className="toolbar-left">
{/*					<div className="toolbar-current-property dummy-property">
						First, Find a Property
					</div>*/}

					<div className="toolbar-buttons dummy-buttons">
							<span className="ms-3 toolbar-icon-wrapper">
								<Tooltip text="Road View" direction="bottom">
									<i className="bi bi-eye-fill"/>
								</Tooltip>
							</span>
						<span className="ms-3 toolbar-icon-wrapper">
							<Tooltip text="Email Alerts" direction="bottom">
								<i className="bi bi-bell-fill" />
							</Tooltip>
						</span>

						<span className="ms-3 toolbar-icon-wrapper">
							<Tooltip text="Property Data Progress" direction="bottom">
								<i className="bi bi-hourglass-split" />
							</Tooltip>
						</span>

									
						{!isMobile && 
							<span className="ms-3 toolbar-icon-wrapper">
								<Link to={`/backend-search`}>
									<Tooltip text="Backend Search" direction="bottom">
										<i className="bi bi-door-open-fill"/>
									</Tooltip>
								</Link>
							</span>
						}
					</div>
				</div>
			</div>

		);
	}

	if (!address) {
		//empty toolbar if no address in url
		return <div className="toolbar navbar navbar-light bg-light" />;
	}

	let showRoadview = !!propertyData?.lat && !!propertyData?.long;
	let roadviewUrl = "https://roadview.planninglabs.nyc/view/"+ propertyData?.long +"/" + propertyData?.lat;

	let addressLabel = propertyData?.address || '';
	const [street, ...rest] = addressLabel.split(',');

	let mobileNavTo = {
		pathname: `/property/${propertyData?.address}/nav`,
		state: { fromApp: true }
	};

	if (isMobileNavOpen) {
		mobileNavTo = `/property/${propertyData?.address}/overview`;
	}

	const mobileNavClick = (ev) => {
		if (isMobileNavOpen && history.location.state?.fromApp) {
			ev.preventDefault();
			history.goBack();
		}
	}

	const mobileNavButton = (
		<Link to={mobileNavTo} onClick={mobileNavClick}>
			<div className="mobile-address">
				<button className={`btn btn-sm btn-primary menu-button ${isMobileNavOpen ? 'is-active' : ''}`}>
					<i className="bi bi-list" />
				</button>
				<div>
					<div className="toolbar-street-address">{street}{isMobileNavOpen ? <i className="bi bi-chevron-up" /> : <i className="bi bi-chevron-down" />}</div>
					<div className="toolbar-city-state">{rest.join(',')}</div>
				</div>
			</div>
		</Link>
	);

	const toolbarButtons = (
		<div className="toolbar-buttons">
			{showRoadview &&
				<span className="ms-3 toolbar-icon-wrapper">
					<OutboundLink url={roadviewUrl} noIcon >
						<Tooltip text="Road View" direction="bottom">
							<i className="bi bi-eye-fill"/>
						</Tooltip>
					</OutboundLink>
				</span>
			}
			<span className="ms-3 toolbar-icon-wrapper">
				<Link to={`/property/${address}/notifications`}>
					<Tooltip text="Email Alerts" direction="bottom">
						<i className="bi bi-bell-fill" />
					</Tooltip>
				</Link>
			</span>

			<span className="ms-3 toolbar-icon-wrapper">
				<Link to={`/property/${address}/tasks`}>
					<Tooltip text="Property Data Progress" direction="bottom">
						<span className={`fancy-hourglass ${areFirstLoadTasksComplete ? 'complete' : 'in-progress'}`}>
							<i className="bi bi-hourglass-split" />
							{areFirstLoadTasksComplete ?
								<i className="bi bi-check-circle-fill check" />
								: <Spinner size="sm" color="dark" /> 
							}
						</span>
					</Tooltip>
				</Link>
			</span>

			{!isMobile && 
				<span className="ms-3 toolbar-icon-wrapper">
					<Link to={`/backend-search`}>
						<Tooltip text="Backend Search" direction="bottom">
							<i className="bi bi-door-open-fill"/>
						</Tooltip>
					</Link>
				</span>
			}
			{!isMobile && 
				<span className="ms-3 toolbar-icon-wrapper">
					<button
						className="btn-hyperlink toolbar-button"
						onClick={() => dispatch(openModal(MODAL_TYPES.EXPORT_REPORT))}
						disabled={isLandingPage}
					>
						<Tooltip text="Export Report" direction="bottom">
							<i className="bi bi-cloud-download-fill"/>
						</Tooltip>
					</button>
				</span>
			}

		</div>
	);

	return (
		<div className="toolbar navbar navbar-light bg-light">
			<div className="toolbar-left">
				<div className="toolbar-current-property">
					{isMobile ? mobileNavButton : 
						<Link to={`/property/${propertyData?.address}/overview`}>{propertyData?.address || ''}</Link>
					}
				</div>
				{toolbarButtons}
			</div>
{/*			<div className="toolbar-right">
			</div>*/}
		</div>
	);
}

export default PropertyToolbar;
