import { useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import OutboundLink from 'components/OutboundLink';
import { prettyBorough, isManhattan } from 'utils/propertyUtils';

import AddToPortfolioButton from 'pages/_rd_overview/toolbar/AddToPortfolioButton';
import LinkDropdownButton from 'pages/_rd_overview/toolbar/LinkDropdownButton';
import ToolbarIcons from 'pages/_rd_overview/toolbar/ToolbarIcons';
 

import './OverviewToolbar.css';

const OverviewToolbar = (props) => {
	const isMobile = useMediaPredicate(MOBILE_SIZE);

	let {
		propertyData,
		zoningData,
	} = useSelector(state => ({
		propertyData: state.property.propertyData,
		zoningData: state.zoning.data || {},
	}));

	let bin = propertyData?.bin || null;
	let block = zoningData?.block || null;
	let lot = zoningData?.lot || null;
	let blockAndLot = null;
	if (block && lot) {
		blockAndLot = `${block}/${lot}`;
	}

	let binLink = null;
	if (bin) {
		binLink = (
			<OutboundLink url={`http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${propertyData?.borough}&houseno=${propertyData?.house_number}&street=${propertyData?.street_name}`}>
				{bin}
			</OutboundLink>
		);
	}

	let addressTitle = propertyData?.address;
	if (addressTitle && !isManhattan(propertyData)) {
		let borough = prettyBorough(propertyData);
		addressTitle = addressTitle?.replace('New York, NY', `${borough}, NY`)
	}

	let desktopToolbar = (
		<div className="rd-overview-toolbar">
			<div className="rd-overview-toolbar-left">
				<ToolbarIcons />
			</div>
			<h1 className="rd-overview-address-header">
				{addressTitle}
				{blockAndLot && <span className="block-lot-bin-header">[Block/Lot - {blockAndLot}]</span>}
				{binLink && (<span className="block-lot-bin-header">[BIN - {binLink}]</span>)}
			</h1>
			<div className="rd-overview-toolbar-right">
				<LinkDropdownButton listName="quickLinks" property={propertyData} />
				<LinkDropdownButton listName="codes" property={propertyData} />
				<AddToPortfolioButton />
			</div>
		</div>
	);

	let mobileToolbar = (
		<div className="rd-overview-toolbar">
			<h1 className="rd-overview-address-header">
				{addressTitle}
				<div>
					{blockAndLot && <span className="block-lot-bin-header">[Block/Lot - {blockAndLot}]</span>}
					{binLink && <span className="block-lot-bin-header">[BIN - {binLink}]</span>}
				</div>
			</h1>
			<div className="rd-overview-toolbar-mobile-buttons">
				<ToolbarIcons />
				<div>
					<LinkDropdownButton listName="quickLinks" property={propertyData} iconOnly />
					<LinkDropdownButton listName="codes" property={propertyData} iconOnly />
					<AddToPortfolioButton />
				</div>
			</div>
		</div>
	);

	if (isMobile) {
		return mobileToolbar;
	}
	return desktopToolbar;


}

export default OverviewToolbar;

