function HousingComplaintProblems({ problems }) {

	return problems.map((problem, i) => {
		return (
			<div className="mb-3" key={i}>
				<div className="mb-1"><strong>{`Problem ${problem.problemid || ''}`}</strong></div>
				{Object.keys(problem).map((key) => {
					let value = problem[key];
					return (
						<div key={key}>		
							<span className="me-1">{key}:</span>
							<span>{value || '-'}</span>
						</div>
					);
				})}
			</div>
		);
	});
}

export default HousingComplaintProblems;