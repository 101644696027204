import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import { signIn, clearAuthErrors, resetJustSignedOut, signOut } from 'reducers/userReducer';

import Alert from 'components/Alert';
import Spinner from 'components/Spinner';

import './AuthPage.css';


const SignIn = (props) => {
	usePageTitle('Sign In');
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		isSigningIn,
		signInErrors,
		justSignedOut,
		isLoggedIn,
	} = useSelector(state => ({
		isSigningIn: state.user.isSigningIn,
		signInErrors: state.user.signInErrors,
		justSignedOut: state.user.justSignedOut,
		isLoggedIn: !!state.user.userData?.id,
	}));
	const [emailAddress, setEmailAddress] = useState('');
	const [password, setPassword] = useState('');
	const onSubmit = () => {
		dispatch(resetJustSignedOut());
		return dispatch(signIn(emailAddress, password)).then((res) => {
			if (res?.payload?.userData?.id) { //sign in success
				history.push("/dashboard");
			}
		});
	};
	const onEnter = (event) => {
		if (event.key === 'Enter') {
      onSubmit();
    }
	};

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => dispatch(clearAuthErrors()), []);

	let alreadySignedIn = (
		<div className="sign-in-wrapper">
			<div className="alert alert-success">You're already signed in!</div>
			<div className="input-wrapper">
				<Link to="/dashboard">View your dashboard</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/property">Find a property</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/use-policy">Acceptable Use Policy</Link>
			</div>
			<div className="input-wrapper">
				<button onClick={() => dispatch(signOut())} className="btn btn-secondary">Sign Out</button>
			</div>
		</div>
	);

	let signInForm = (
		<div className="sign-in-wrapper">
			{isSigningIn && <Spinner />}
			{signInErrors.map(message => <Alert color='danger' text={message} key={message} />)}
			{justSignedOut && 
				<div className="alert alert-success">
					Signed out successfully!
				</div>
			}

			<div className="input-wrapper">
				<label className="form-label">Email</label>
				<input
					type="text"
					value={emailAddress}
					onChange={ev => setEmailAddress(ev.target.value)}
					className="form-control"
					placeholder="Email"
					disabled={isSigningIn}
					onKeyDown={onEnter}
					autoFocus
				/>
			</div>

			<div className="input-wrapper">
				<label className="form-label">Password</label>
				<input
					type="password"
					value={password}
					onChange={ev => setPassword(ev.target.value)}
					className="form-control"
					placeholder="Password"
					disabled={isSigningIn}
					onKeyDown={onEnter}
				/>
			</div>

			<div className="input-wrapper">
				<button onClick={onSubmit} disabled={isSigningIn} className="btn btn-primary">Sign In</button>
			</div>

			<div className="input-wrapper">
				<Link to="/forgot-password">Forgot your password?</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/sign-up">Don't have an account? Sign up!</Link>
			</div>
			<div className="input-wrapper">
				<Link to="/use-policy">Acceptable Use Policy</Link>
			</div>
		</div>
	);

	return (
		<div className="auth-page">
			<div className="auth-page-content">
				<h1>Sign In</h1>
				{isLoggedIn ? alreadySignedIn : signInForm}
			</div>
		</div>
	);	
}

export default SignIn;
