import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';

import FilesWidget from 'components/userFiles/FilesWidget';

import './Admin.css';

const MyDocuments = (props) => {
	const history = useHistory();
	usePageTitle('Document Administration');
	const {
		isLoggedIn,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
	}));

	useEffect(() => {
		if (!isLoggedIn) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, history]);

	return (
		<div className="my-documents-page">
			<div className="card m-4">
				<div className="card-header">
					User Document Administration
				</div>
				<div className="card-body">
					<FilesWidget parentType="admin" />
				</div>
			</div>
		</div>
	);	
};

export default MyDocuments;
