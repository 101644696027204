import { useSelector } from 'react-redux';

import getPropertyLinks from 'utils/propertyLinks'
// import Spinner from 'components/Spinner';
// import OutboundLink from 'components/OutboundLink';


function AcrisLink({ /*uri, fields, property,*/ children, label, buttonClass }) {
	const {
		acris,
		property,
	} = useSelector(state => ({
		acris: getPropertyLinks(state.property.propertyData)['Acris'],
		property: state.property.propertyData,
	}));

	let labelEl = "Acris";
	if (!property) {
		//to prevent the <form> re-rendering before/after property is loaded, which will cause a react 'uncontrolled form' error
		return <span><span className="link-disabled">{labelEl}</span></span>;
	}

	if (children) {
		labelEl = children;
	} else if (label) {
		labelEl = label;
	} else {
		labelEl = <span>Acris <i className="bi bi-box-arrow-up-right" /></span>;
	}

	let buttonType = buttonClass || "btn-hyperlink";

	return (
		<span className="acris-wrapper">
			<form className="d-inline" action={acris.uri} method="POST" target="_blank">
			{Object.keys(acris.fields).map((fieldName) => (
				<input key={fieldName} type="hidden" name={fieldName} value={acris.fields[fieldName]} />
			))}
			<button className={`${buttonType} text-left`} type="submit">{labelEl}</button>
			</form>
		</span>
	);
}

export default AcrisLink;
