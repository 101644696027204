export const DEFAULT_API_URL = `https://api.violerts.com`;
export const API_DOMAIN_LOCAL_STORAGE_KEY = 'API_DOMAIN';

export let API_URL = `https://api.violerts.com`;
// export const API_URL = `https://api-new.violerts.com`;


/* These functions are for debugging different API servers from the UI */

export const setApiUrl = (url='https://api.violerts.com') => {
	API_URL = url;
};

export const getApiUrl = () => {
	return API_URL;
};

export const resetApiUrl = () => {
	API_URL = DEFAULT_API_URL;
	setApiDomain(DEFAULT_API_URL);
};

export const initApiUrlFromLocalStorage = () => {
	let domain = localStorage.getItem(API_DOMAIN_LOCAL_STORAGE_KEY);
	if (domain) {
		setApiUrl(domain);
	}
}

export const setApiDomain = (domain) => {
	if (domain) {
		localStorage.setItem(API_DOMAIN_LOCAL_STORAGE_KEY, domain);
		setApiUrl(domain);
	} else {
		localStorage.removeItem(API_DOMAIN_LOCAL_STORAGE_KEY);
		resetApiUrl();
	}
};

window.setApiUrl = setApiUrl;
window.getApiUrl = getApiUrl;
window.resetApiUrl = resetApiUrl;
window.setApiDomain = setApiDomain;
