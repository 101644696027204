import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDatasetItemFiles } from 'reducers/fileReducer';

import Spinner from 'components/Spinner';
import StandardModal from 'modals/StandardModal';
import FileTable from 'components/userFiles/FileTable';
import Paginator from 'components/Paginator';

const UploadModal = ({ options }) => {
	const dispatch = useDispatch();
	const { onUpload, dataSet, dataSource, recordId } = options;
	const [selectedFile, setSelectedFile] = useState(null);

	const {
		isLoading,
		files,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		isLoading: state.files.isUploading || state.files.isLoading,
		files: state.files.datasetItemFiles?.data || [],
		currentPage: state.files.datasetItemFiles?.current_page,
		totalPages: state.files.datasetItemFiles?.last_page,
	}));

	const onFileChange = (e) => {
		let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    setSelectedFile(formData);
	}

	useEffect(() => {
		dispatch(getDatasetItemFiles(dataSet, dataSource, recordId, 1));
	}, [recordId, dataSet, dataSource, dispatch])

	return (
		<StandardModal
			title="File Upload"
			size="md"
			closeOnOutsideClick
			onConfirm={() => onUpload(selectedFile)}
			disableConfirm={!selectedFile}
			confirmText="Upload"
			closeLabel="Cancel"
		>
			<div>
				<div className="upload-wrapper">
					<div>Select a file to upload</div>
					<input type="file" onChange={onFileChange} className="" disabled={isLoading} />
				</div>
				{isLoading && <Spinner isLoading={isLoading} />} 
				{files?.length > 0 && (
					<div>
						<FileTable files={files} parentType={'datasetItem'} />
						{totalPages > 1 && (
							<Paginator
								currentPage={currentPage}
								totalPages={totalPages}
								setPage={(pageNum) => dispatch(getDatasetItemFiles(dataSet, dataSource, recordId, pageNum))}
							/>
						)}
					</div>
				)}
			</div>
		</StandardModal>
	);
};

export default UploadModal;
