import "./NothingFoundCardContent.css";

function NothingFoundCardContent({ text }) {
	return (
		<div className="nothing-found-card-content">
			<div>{text || 'No data found'}</div>
		</div>
	);
}

export default NothingFoundCardContent;
