// import { useState, useEffect } from 'react';

import VerticalButtons from 'pages/_rd_overview/VerticalButtons';

import './VerticalTabCard.css';


function VerticalTabCard(props) {
	return (
		<div className="vertical-button-card">
			<div className="vertical-button-section">
				<VerticalButtons buttons={props.buttons} />
			</div>
			<div className="vertical-button-card-content">
				{props.children}
			</div>
		</div>
	);
}

export default VerticalTabCard;
