import { useDispatch } from 'react-redux';
import { usePlacesWidget } from "react-google-autocomplete";

import { GOOGLE_MAPS_API_KEY } from 'utils/googlePlaceUtils';
import { checkAddressByGooglePlace } from 'reducers/addressReducer';

import './GoogleAddressSearch.css';

function GoogleAddressSearch(props) {
	const dispatch = useDispatch();

	let { label, hideLabel, placeholder, showHeaderHint } = props;
	label = label || 'Address Search'
	placeholder = placeholder || 'Find an Address'

	const onPlaceSelected = (place) => {
		// console.log('google place', place);
		dispatch(checkAddressByGooglePlace(place));
		if (props.onSelect) {
			props.onSelect(place);
		}
	}

	const { ref } = usePlacesWidget({
		apiKey: GOOGLE_MAPS_API_KEY,
		onPlaceSelected,
		options: {
      types: ["address"],
      componentRestrictions: {
      	country: "us",
      },
    },
	});

	return (
		<div className="google-address-search">
			{!hideLabel && <label className="form-label">{label}</label>}
			<input
			ref={ref}
			type="text"
			className={`form-control ${showHeaderHint ? 'header-hint' : ''}`}
			placeholder={placeholder}
			autoFocus={showHeaderHint}
		/>
		</div>
	);
}

export default GoogleAddressSearch;
