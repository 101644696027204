import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_FIRST_LOAD_TASKS_REQUEST = 'GET_FIRST_LOAD_TASKS_REQUEST';
const GET_FIRST_LOAD_TASKS_SUCCESS = 'GET_FIRST_LOAD_TASKS_SUCCESS';
const GET_FIRST_LOAD_TASKS_ERROR = 'GET_FIRST_LOAD_TASKS_ERROR';

const initialState = {
	propertyId: null,
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchFirstLoadTasks = (propertyId) => (dispatch, getState) => {
	let url = `${API_URL}/api/properties/${propertyId}/first-load-tasks`;
	dispatch(getFirstLoadTasksRequest(propertyId));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(getFirstLoadTasksSuccess(result));
		},
		onError: error => dispatch(getFirstLoadTasksError(error)),
	});
};

export const getFirstLoadTasksRequest = (propertyId) => (dispatch, getState) => {
	let state = getState();
	let nextData = null;
	if (state.firstLoadTasks.propertyId === propertyId) {
		//keep current data if we're re-loading the same property
		nextData = state.firstLoadTasks.data;
	}
	return dispatch({
		type: GET_FIRST_LOAD_TASKS_REQUEST,
		payload: {
			propertyId,
			data: nextData,
			isLoading: true,
			hasError: false,
			error: null,
		}
	});
}

export const getFirstLoadTasksSuccess = (data) => ({
	type: GET_FIRST_LOAD_TASKS_SUCCESS,
	payload: {
		isLoading: false,
		data,
		hasError: false,
		error: null,
	}
});

export const getFirstLoadTasksError = (error) => ({
	type: GET_FIRST_LOAD_TASKS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const firstLoadTasksReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_FIRST_LOAD_TASKS_REQUEST:
		case GET_FIRST_LOAD_TASKS_SUCCESS:
		case GET_FIRST_LOAD_TASKS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default firstLoadTasksReducer;
