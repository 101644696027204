import { Route } from "react-router-dom";

import AppContainer from 'containers/AppContainer';
import PropertyContainer from 'containers/PropertyContainer';
import RecordDetailsPage from 'pages/RecordDetailsPage';

import { dataConfig } from 'data/dataConfig';
import { OPEN_DATA, CITYPAY, NYC_GOV } from 'data/dataSourceConstants';

function RecordDetailRoutes() {
	let routes = [];
	Object.keys(dataConfig).forEach((dataSet) => {
		let config = dataConfig[dataSet];

		if (config.dataSources.includes(OPEN_DATA) || config.dataSources.includes(CITYPAY) || config.dataSources.includes(NYC_GOV)) {
			let path = `/${config.urlPath}/:id`;
			routes.push(
				<Route key={path} path={path}>
					<AppContainer>
						<PropertyContainer>
							<RecordDetailsPage dataSet={dataSet} />
						</PropertyContainer>
					</AppContainer>
				</Route>
			);
		}
	});
	return routes;
}

export default RecordDetailRoutes;
