import { get, post, put, signInApiRequest } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

export const SIGN_OUT = 'SIGN_OUT';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const CLEAR_PASSWORD_RESET_FIELDS = 'CLEAR_PASSWORD_RESET_FIELDS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';

export const REGEN_API_KEY_REQUEST = 'REGEN_API_KEY_REQUEST';
export const REGEN_API_KEY_SUCCESS = 'REGEN_API_KEY_SUCCESS';
export const REGEN_API_KEY_ERROR = 'REGEN_API_KEY_ERROR';

export const initialState = {
	userData: {},
	isLoading: false,
	isUpdating: false,
	error: null,
	authToken: null,

	isRegeneratingApiKey: false,
	apiKeyRegenError: null,

	justSignedOut: false, //for displaying 'signed out successfully' msg

	isSigningIn: false,
	signInErrors: [],

	isSigningUp: false,
	signUpErrors: {}, //keys are field names, values are array of error message strings

	isResettingPassword: false,
	wasResetSuccessful: null,
	passwordResetMessages: [],
};

export const regenerateApiKey = () => (dispatch, getState) => {
	let state = getState();
	dispatch(regenApiKeyRequest());

	return get({
		dispatch,
		url: `${API_URL}/api/user/apiKey/regenerate`,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			let userData = Object.assign({}, state.user.userData);
			userData.api_key = result;
			return dispatch(regenApiKeySuccess(userData));
		},
		onError: error => dispatch(regenApiKeyError(error)),
	});
};

export const silentSignOut = () => {
	return ({
		type: SIGN_OUT,
		payload: {
			userData: {},
			authToken: null,
		},
	});
}
export const signOut = () => ({
	type: SIGN_OUT,
	payload: {
		userData: {},
		authToken: null,
		justSignedOut: true,
	},
});

export const resetJustSignedOut = () => ({
	type: SIGN_OUT,
	payload: {
		justSignedOut: false,
	},
});

export const signUp = (name, email, password, phone, property) => (dispatch) => {
	dispatch(signUpRequest());
	// let url = `${API_URL}/api/signup?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&phone=${encodeURIComponent(phone)}&property=${encodeURIComponent(property)}`;

	let data = { name, email, password }
	if (phone) {
		data.phone = phone;
	}

	return post({
		dispatch,
		url: `${API_URL}/api/signup`,
		// data: { name, email, password, phone, property },
		data: data,
		onSuccess: (result) => {
			dispatch(signUpSuccess(result));
			//sign in locally after sign up success
			return dispatch(signIn(email, password));
		},
		onError: error => dispatch(signUpError(error)),
	});
};

export const signIn = (emailAddress, password) => (dispatch) => {
	dispatch(signInRequest());

	return signInApiRequest({
		dispatch,
		url: `${API_URL}/api/signin`,
		data: {
			email: emailAddress,
			password: password,
		},
		onSuccess: (result) => {
			let authToken = result.headers.get('authorization');
			return result.json().then((res) => {
				dispatch(signInSuccess(res, authToken));
				if (res.status === 'success') {
					return dispatch(fetchUser(authToken));
				}
			});
		},
		onError: error => dispatch(signInError(error)),
	});
};

export const clearResetFields = () => ({
	type: CLEAR_PASSWORD_RESET_FIELDS,
	payload: {
		isResettingPassword: false,
		passwordResetMessages: [],
		wasResetSuccessful: null,
	}
});

export const clearAuthErrors = () => ({
	type: CLEAR_AUTH_ERRORS,
	payload: {
		signInErrors: [],
		signUpErrors: {},
	}
});

export const forgotPassword = (emailAddress) => (dispatch) => {
	dispatch(passwordResetRequest());

	return post({
		dispatch,
		url: `${API_URL}/api/user/forgot-password`,
		data: {
			email: emailAddress,
		},
		onSuccess: result => dispatch(passwordResetSuccess(result)),
		onError: error => dispatch(passwordResetError(error)),
	});
};

export const resetPassword = (newPassword, confirmNewPassword, resetToken) => (dispatch) => {
	dispatch(passwordResetRequest());
	let url = `${API_URL}/api/user/forgot-password/reset/${resetToken}`

	return post({
		dispatch,
		url,
		data: {
			password: newPassword,
			password_confirmation: confirmNewPassword,
		},
		onSuccess: result => dispatch(passwordResetSuccess(result)),
		onError: error => dispatch(passwordResetError(error)),
	});
};

export const fetchUser = (authToken) => (dispatch) => {
	let url = `${API_URL}/api/auth/user`;
	dispatch(userRequest());
	return get({
		dispatch,
		url,
		authToken,
		onSuccess: result => dispatch(userRequestSuccess(result.data)),
		onError: error => dispatch(userRequestError(error)),
	});
};

export const updateUser = (userData) => (dispatch, getState) => {
	let state = getState();
	let authToken = state.user.authToken;
	let url = `${API_URL}/api/user`;
	dispatch(updateUserRequest());
	return put({
		dispatch,
		data: userData,
		url,
		authToken,
		onSuccess: (result) => {
			dispatch(fetchUser(authToken))
			return dispatch(updateUserSuccess(result));
		},
		onError: error => dispatch(updateUserError(error)),
	});
};

export const userRequest = () => ({
	type: GET_USER_REQUEST,
	payload: {
		userData: {},
		isLoading: true,
	}
});

export const userRequestSuccess = (userData) => ({
	type: GET_USER_SUCCESS,
	payload: {
		userData: userData,
		isLoading: false,
		error: null,
	}
});

export const userRequestError = (error) => ({
	type: GET_USER_ERROR,
	payload: {
		isLoading: false,
		userData: {},
		error,
	}
});

export const updateUserRequest = () => ({
	type: UPDATE_USER_REQUEST,
	payload: {
		isUpdating: true,
	}
});

export const updateUserSuccess = (response) => ({
	type: UPDATE_USER_SUCCESS,
	apiResponse: response,
	payload: {
		// userData: userData,
		isUpdating: false,
		error: null,
	}
});

export const updateUserError = (error) => ({
	type: UPDATE_USER_ERROR,
	payload: {
		isUpdating: false,
		userData: {},
		error,
	}
});

export const signInRequest = () => ({
	type: SIGN_IN_REQUEST,
	payload: {
		isSigningIn: true,
		signInErrors: [],
	}
});

export const signInSuccess = (response, authToken) => {
	let signInErrors = [];

	if (response?.status === 'error') {
		signInErrors = [response?.msg];
	}

	return {
		type: response?.status === 'success' ? SIGN_IN_SUCCESS : SIGN_IN_ERROR,
		payload: {
			isSigningIn: false,
			signInErrors,
			authToken,
		}
	};
};

export const signInError = (error) => {
	return {
		type: SIGN_IN_ERROR,
		payload: {
			isSigningIn: false,
			signInErrors: ['Oops, looks like something went wrong'],
		}
	};
};

export const signUpRequest = () => ({
	type: SIGN_UP_REQUEST,
	payload: {
		isSigningUp: true,
		signUpErrors: {},
	}
});

export const signUpSuccess = (response) => {
	if (response?.data?.id) { //sign up success
		return {
			type: SIGN_UP_SUCCESS,
			payload: {
				isSigningUp: false,
				isLoading: false,
				error: null,
				id: response.data.id,
				userData: response.data,
				signUpErrors: {},
			}
		};
	} else {
		return {
			type: SIGN_UP_ERROR,
			payload: {
				isSigningUp: false,
				signUpErrors: response?.errors || {},
			}
		};		
	}
};

export const signUpError = (error) => {
	return {
		type: SIGN_UP_ERROR,
		payload: {
			isSigningUp: false,
			signUpErrors: { _general: ['Oops, looks like something went wrong'] },
		}
	};
};

export const passwordResetRequest = () => ({
	type: PASSWORD_RESET_REQUEST,
	payload: {
		isResettingPassword: true,
		passwordResetMessages: [],
		wasResetSuccessful: null,
	}
});

export const regenApiKeyRequest = () => ({
	type: REGEN_API_KEY_REQUEST,
	payload: {
		isRegeneratingApiKey: true,
	},
});
export const regenApiKeySuccess = (userData) => ({
	type: REGEN_API_KEY_SUCCESS,
	payload: {
		isRegeneratingApiKey: false,
		apiKeyRegenError: null,
		userData,
	},
});
export const regenApiKeyError = () => ({
	type: REGEN_API_KEY_ERROR,
	payload: {
		isRegeneratingApiKey: false,
		apiKeyRegenError: 'Oops, there was an error generating your API key',
	},
});

//need to look for message strings, because api doesn't always clarify if a response is an error or success
const RESET_REQUEST_SUCCESS_MESSAGE = 'The password for the account associated with this email address has been reset. Please check your email.';
const PASSWORD_CHANGE_SUCCESS_MESSAGE = 'You have successfully changed your password.';
const INVALID_RESET_TOKEN_MESSAGE = 'The password for this account has already been changed.';
export const passwordResetSuccess = (response) => {
	let isSuccess = false;
	let messages = [];
	
	if (response?.msg === RESET_REQUEST_SUCCESS_MESSAGE) {
		isSuccess = true;
		messages = [response.msg];
	} else if (response?.msg === PASSWORD_CHANGE_SUCCESS_MESSAGE) {
		isSuccess = true;
		messages = [response.msg];
	} else if (response?.msg === INVALID_RESET_TOKEN_MESSAGE) {
		isSuccess = false;
		messages = [response.msg];
	} else if (response?.errors) {
		if (response?.errors?.email?.length) {
			//will probably be: 
			//  "The email field is required."
			//  "The email must be a valid email address."
			//  "The email address entered isn't linked to an account."
			messages = messages.concat(response.errors.email);
			isSuccess = false;
		}
		if (response?.errors?.password?.length) {
			//will probably be: 
			//  "The password must be at least 6 characters."
			//  "The password confirmation does not match."
			messages = messages.concat(response.errors.password);
			isSuccess = false;
		}
	} else if (response?.msg) {
		//if there are no errors, and a message we don't recognize, show the user the message and assume its an error
		isSuccess = false;
		messages = [response.msg];
	}

	return {
		type: isSuccess ? PASSWORD_RESET_SUCCESS : PASSWORD_RESET_ERROR,
		payload: {
			isResettingPassword: false,
			passwordResetMessages: messages,
			wasResetSuccessful: isSuccess,
		}
	};
};

export const passwordResetError = (error) => ({
	type: PASSWORD_RESET_ERROR,
	payload: {
		isResettingPassword: false,
		passwordResetMessages: ['Oops, looks like something went wrong.'],
		wasResetSuccessful: false,
	}
});

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SIGN_OUT:
		case GET_USER_REQUEST:
		case GET_USER_SUCCESS:
		case GET_USER_ERROR:
		case UPDATE_USER_REQUEST:
		case UPDATE_USER_SUCCESS:
		case UPDATE_USER_ERROR:
		case PASSWORD_RESET_REQUEST:
		case PASSWORD_RESET_SUCCESS:
		case PASSWORD_RESET_ERROR:
		case SIGN_IN_REQUEST:
		case SIGN_IN_SUCCESS:
		case SIGN_IN_ERROR:
		case SIGN_UP_REQUEST:
		case SIGN_UP_SUCCESS:
		case SIGN_UP_ERROR:
		case CLEAR_PASSWORD_RESET_FIELDS:
		case CLEAR_AUTH_ERRORS:
		case REGEN_API_KEY_REQUEST:
		case REGEN_API_KEY_SUCCESS:
		case REGEN_API_KEY_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
