import { useSelector } from 'react-redux';
import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';
import { getLabelFromValue } from 'pages/backendSearch/backendSearchConstants';
import { DataSetSelectMobile } from 'pages/backendSearch/DataSetSelect';

const BackendSearchControls = ({ onSearch, page, setPage, searchTerm, onSelectDataSet, selectedDataSet, setSearchTerm }) => {
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const {
		isLoading,
		data,
	} = useSelector(state => ({
		isLoading: state.recordSearch.isLoading,
		data: state.recordSearch.data || [],
	}));

	const goToPage = (e) => {
		const pageNum = parseInt(e.target.value);
		if (pageNum > 0 && !isNaN(pageNum)) {
			setPage(pageNum);
		}
	}

	const onEnter = (event) => {
		if (event.key === 'Enter') {
      onSearch();
    }
	};

	const onNext = () => {
		setPage(page + 1);
		onSearch(page + 1);
	}

	const onPrevious = () => {
		if (page > 1) {
			setPage(page - 1);
			onSearch(page - 1);
		}
	}

	let pageButtons = (
		<div className="page-buttons-container">
			<button key="prev" className="btn btn-primary" onClick={onPrevious} disabled={isLoading || page <= 1}>
				Previous
			</button>
			<input type="number" className="form-control" value={page} onChange={goToPage} onKeyPress={onEnter} disabled={isLoading} />
			<button key="next" className="btn btn-primary" onClick={onNext} disabled={isLoading || !data?.length}>
				Next
			</button>
		</div>
	);

	let searchInput = (
		<div className="input-group">
			<input
				type="text"
				className="form-control search-input"
				placeholder={`Search ${getLabelFromValue(selectedDataSet)}`}
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				onKeyPress={onEnter}
			/>
			<button 
				className="btn btn-primary"
				onClick={() => { onSearch(); }}
				disabled={!searchTerm || !selectedDataSet}
			>
				Search
			</button>
		</div>
	);
	return (
		<div className="backend-search-controls">	
			{isMobile ? <DataSetSelectMobile selectedDataSet={selectedDataSet} onSelect={onSelectDataSet} /> : null}
			{searchInput}
			{pageButtons}
		</div>
	);
};

export default BackendSearchControls;