import { silentSignOut } from 'reducers/userReducer';

const sessionExpiredHandler = (dispatch) => {
	if (dispatch) {
		dispatch(silentSignOut());
	}
}
 
export const get = ({ url, onSuccess, onError, authToken, dispatch }) => {
	let fetchParams = {};

	if (authToken) {
		fetchParams.headers = {
			'authorization': `Bearer ${authToken}`,
			// 'credentials': 'include',
		};
	}

	return fetch(new Request(url, fetchParams))
		.then((result) => {
			if (result.status === 401) {
				sessionExpiredHandler(dispatch);
			}
			if (!result.ok) {
				throw new Error(result.json());
			}
			return result.json();
		})
		.then(
			result => onSuccess(result),
			(error) => {
				return onError(error);
			}
		);
};

export const post = ({ url, data, onSuccess, onError, authToken, dispatch }) => {
	let fetchParams = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json, text/plain, */*',
		},
	};

	if (data) {
		fetchParams.body = JSON.stringify(data);
	}

	if (authToken) {
		fetchParams.headers.authorization = `Bearer ${authToken}`;
	}

	return fetch(url, fetchParams)
		.then((result) => {
			if (result.status === 401) {
				sessionExpiredHandler(dispatch);
			}

			if (!result.ok) {
				throw new Error(result.json());
			}

			return result.json();
		})
		.then(
			result => onSuccess(result),
			error => onError(error)
		);	
};

export const put = ({ url, data, onSuccess, onError, authToken, dispatch }) => {
	let fetchParams = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json, text/plain, */*',
		},
	};

	if (data) {
		fetchParams.body = JSON.stringify(data);
	}

	if (authToken) {
		fetchParams.headers.authorization = `Bearer ${authToken}`;
	}

	return fetch(url, fetchParams)
		.then((result) => {
			if (result.status === 401) {
				sessionExpiredHandler(dispatch);
			}

			if (!result.ok) {
				throw new Error(result.json());
			}

			return result.json();
		})
		.then(
			result => onSuccess(result),
			error => onError(error)
		);	
};

export const del = ({ url, onSuccess, onError, authToken, dispatch }) => {
	let fetchParams = {
		method: 'DELETE',
	};

	if (authToken) {
		fetchParams.headers = {
			'authorization': `Bearer ${authToken}`,
		};
	}

	return fetch(new Request(url, fetchParams))
		.then((result) => {
			if (result.status === 401) {
				sessionExpiredHandler(dispatch);
			}
			if (!result.ok) {
				throw new Error("Error deleting");
			}
			return result;
		})
		.then(
			() => onSuccess(),
			error => onError(error)
		);
};

export const upload = ({ url, data, onSuccess, onError, authToken, dispatch }) => {
	let fetchParams = {
		method: 'POST',
		headers: {
			'Accept': 'application/json, text/plain, */*',
		},
		body: data,
	};

	if (authToken) {
		fetchParams.headers.authorization = `Bearer ${authToken}`;
	}

	return fetch(url, fetchParams)
		.then((result) => {
			if (result.status === 401) {
				sessionExpiredHandler(dispatch);
			}

			if (!result.ok) {
				throw new Error(result.json());
			}

			return result.json();
		})
		.then(
			result => onSuccess(result),
			error => onError(error)
		);	
};

export const signInApiRequest = ({ url, data, onSuccess, onError, dispatch }) => {
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json, text/plain, */*',
		},
		body: JSON.stringify(data),
	})
	.then(result => onSuccess(result))
	.catch(error => onError(error));	
};
 
export const download = ({ url, filename, onSuccess, onError, authToken, dispatch }) => {
	let fetchParams = {};
	if (authToken) {
		fetchParams.headers = {
			'authorization': `Bearer ${authToken}`,
		};
	}

	return fetch(new Request(url, fetchParams))

	.then(response => response.blob())
	.then(blob => {
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		a.click();    
		a.remove();  //afterwards we remove the element again         
	});
};