import GoogleAddressSearch from 'components/addressSearch/GoogleAddressSearch';
import BlockAndLotSearch from 'components/addressSearch/BlockAndLotSearch';

import './AddressSearch.css';

function AddressSearch(props) {
	let { addressSearchLabel, blockAndLotSearchLabel, addressSearchPlaceholder, hideLabel } = props;
	addressSearchLabel = addressSearchLabel || 'Address Search';

	let hideBlockAndLotSearchLabel = !blockAndLotSearchLabel;

	return (
		<div className="address-search">
			<GoogleAddressSearch
				hideLabel={hideLabel}
				label={addressSearchLabel}
				placeholder={addressSearchPlaceholder}
				onSelect={props.onSelect}
				showHeaderHint={props.showHeaderHint}
			/>
			<BlockAndLotSearch
				hideLabel={hideBlockAndLotSearchLabel}
				label={blockAndLotSearchLabel}
				onSelect={props.onSelect}
			/>
		</div>
	);
}

export default AddressSearch;
