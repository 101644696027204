import { useParams, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import CollapsibleGroup from 'components/sidebar/CollapsibleGroup';
import NavLink from 'components/sidebar/NavLink';
import dataTables from 'view/dataTables';
import { dataConfig } from 'data/dataConfig';
import { prettifyDataSource, DATA_SOURCES, BIS, getAppRoute } from 'data/dataSourceConstants';

import './DataSetLinks.css';

const getSortOrder = (component) => {
	//number is the order allen wants the links in
	const rank = {
		'DOB Violations': 2,
		'ECB Violations': 1,
		'Job Applications': 7,
		'Electrical-(OPEN_DATA)': 9,
		'Elevator-(OPEN_DATA)': 9.1,
		'Boilers-(OPEN_DATA)': 9.2,
		'Board of Standards and Appeals Applications-(OPEN_DATA)': 11,
		'CityPay Tickets-(CITYPAY)': 6,
		'Sign Application Filings-(OPEN_DATA)': 11.5, //added to make it appear next to others in the same category
		'Tax Lien Sales-(OPEN_DATA)': 1300,
		'ACRIS Legals-(OPEN_DATA)': 1200,
		'Complaints': 5,
		'Department of Buildings': 8,
		'HPD': 4,
		'FDNY': 3,
	};

	//if something is missing, just put it towards the bottom
	if (!component?.key) {
		return 1000;
	}
	return rank[component.key] || 1000;
}

const linkComparator = (a, b) => getSortOrder(a) - getSortOrder(b);

const countDataSources = (dataSetConfig) => {
	let dataSourceCount = 0;
	DATA_SOURCES.forEach((dataSource) => {
		if (dataSetConfig[dataSource]) {
			dataSourceCount += 1;
		}
	});
	return dataSourceCount;
}


function DataSetLinks(props) {
	let { address } = useParams();
	let { isLandingPage } = props;
	let location = useLocation();
	let pathParts = location?.pathname?.split('/') || [];
	let currentDataSetRoute = pathParts[3];

	let propertyData = useSelector(state => state.property.propertyData || {});

	let navItems = [];
	let groupings = {};
	let groupingCategories = {};
	let groupingSums = {};
	let activeGroup = null;

	//data set loop
	Object.keys(dataTables).forEach((dataSet) => {
		let config = dataTables[dataSet];
		let title = config.navTitle || config.title;
		let groupName = dataTables[dataSet].grouping;
		let category = dataTables[dataSet].dataCategory;
		let isViolation = !!config.isViolation;
		let dataSourceCount = countDataSources(config);
		let multiSourceLinks = []; //multiSource = when a dataset has open-data and bis sources
		let multiSourceViolationCounts = [];
		let bisViolationCount = 0;
		let hasBIS = false;
		let hasActiveChild = false;


		//data source sub-loop
		DATA_SOURCES.forEach((dataSource) => {
			if (config[dataSource]) {
				let prettyTitle = title;
				let openIssueCount = 0;
				let openIssueCountField = dataConfig[dataSet]?.openIssueCountFields[dataSource];
				let dataSetRoute = getAppRoute(dataSet, dataSource);
				let isActive = dataSetRoute === currentDataSetRoute;
				hasActiveChild = isActive || hasActiveChild;
				if (dataSourceCount > 1) {
					let prettyDataSource = prettifyDataSource(dataSource);
					prettyTitle = `${title} (${prettyDataSource})`;
				}

				// console.log(dataSet, dataSource, config);

				//only show open issues if the data set is some type of violation (disabled)
				if (openIssueCountField/* && isViolation*/) {
					openIssueCount = propertyData[openIssueCountField];
					if (dataSource === BIS) {
						hasBIS = true;
						bisViolationCount = parseInt(openIssueCount)
					}
				}

				if (groupName) { //custom grouping
					if (isActive) {
						activeGroup = groupName;
					}
					groupingCategories[groupName] = category;
					if (groupings[groupName]) {
						groupingSums[groupName] += parseInt(openIssueCount);
						groupings[groupName].push(<NavLink isViolation={isViolation} category={category} isDisabled={isLandingPage} key={`${dataSet}-${dataSource}`} isActive={isActive} url={`/property/${address}/${dataSetRoute}`} label={prettyTitle} violationCount={openIssueCount} />);
					} else {
						groupingSums[groupName] = openIssueCount;
						let btn = <NavLink isViolation={isViolation} category={category} isDisabled={isLandingPage} key={`${dataSet}-${dataSource}`} isActive={isActive} url={`/property/${address}/${dataSetRoute}`} label={prettyTitle} violationCount={openIssueCount} />;
						groupings[groupName] = [btn];
					}
				} else if (dataSourceCount === 1) { //ungrouped top level dataset, single data source
					navItems.push(
						<li key={`${title}-(${dataSource})`}>
							<NavLink isViolation={isViolation} category={category} isDisabled={isLandingPage} isActive={isActive} url={`/property/${address}/${dataSetRoute}`} label={prettyTitle} violationCount={openIssueCount} />
						</li>
					);
				} else if (dataSourceCount > 1) { //auto-grouping for multi data source: open-data + bis
					if (!isLandingPage) {
						multiSourceViolationCounts.push(parseInt(openIssueCount));
					}
					multiSourceLinks.push(<NavLink isViolation={isViolation} category={category} isDisabled={isLandingPage} key={prettyTitle} isActive={isActive} url={`/property/${address}/${dataSetRoute}`} label={prettyTitle} violationCount={openIssueCount} />);
				}
			}
		});

		if (multiSourceLinks.length > 1) {
			let isEqualVioCounts = multiSourceViolationCounts.every(n => n === multiSourceViolationCounts[0]);
			let violationCount = isEqualVioCounts ? multiSourceViolationCounts[0] : '?';
			if (hasBIS) {
				//always use bis count, if available. even if multiple counts don't reconcile
				violationCount = bisViolationCount;
			}
			navItems.push(<CollapsibleGroup category={category} key={title} label={title} violationCount={violationCount} hasActiveChild={hasActiveChild} subLinks={multiSourceLinks} />);
		}
	});

	Object.keys(groupings).forEach(groupName => {
		let isActiveGroup = groupName === activeGroup;
		navItems.push(<CollapsibleGroup category={groupingCategories[groupName]} key={groupName} label={groupName} violationCount={groupingSums[groupName]} hasActiveChild={isActiveGroup} subLinks={groupings[groupName]} />);
	});

	navItems.sort(linkComparator);
	return navItems;
}

export default DataSetLinks;
