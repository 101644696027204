// import { VENDOR_LINKS } from 'utils/propertyLinks';

import OutboundLink from 'components/OutboundLink';

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import './SignageCard.css';


const SIGNAGE_LINKS = {
	EXIT: 'https://vps.nyc/',
	COMPLIANCE: 'https://vps.nyc/',
	OSHA: 'https://vps.nyc/',
	FDNY: 'https://vps.nyc/',
};

function SignageCard() {
	const links = [
		// {
		// 	label: 'Exit',
		// 	imageFile: 'signage-exit',
		// 	url: SIGNAGE_LINKS.EXIT,
		// },
		{
			label: 'OSHA',
			imageFile: 'signage-osha',
			url: SIGNAGE_LINKS.OSHA,
		},
		{
			label: 'Fire',
			imageFile: 'signage-fdny',
			url: SIGNAGE_LINKS.FDNY,
		},
		{
			label: 'Compliance',
			imageFile: 'signage-compliance',
			url: SIGNAGE_LINKS.COMPLIANCE,
		},
	];

	return (
		<div className="overview-card overview-card-small signage-card">
			<div className="overview-card-content">
				<div className="fw-bold">Compliance Signage</div>
				<div className="signage-links">
					{links.map(link => (
						<OutboundLink className={`signage-link`} noIcon url={link.url} key={link.imageFile}>
							<img className="" src={`/${link.imageFile}.png`} alt={`${link.imageFile}`} />
							<span>{link.label}</span>
						</OutboundLink>
					))}
				</div>

			</div>
		</div>
	);
}

export default SignageCard;
