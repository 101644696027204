import Spinner from 'components/Spinner';

import './SpinnerOverlay.css';

function SpinnerOverlay({ text, isLoading }) {
	if (!isLoading) {
		return null;
	}

	return (
		<div className="spinner-overlay">
			<Spinner text={text} />
		</div>
	);
}

export default SpinnerOverlay;
