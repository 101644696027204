import { useState } from 'react';
import { useDispatch } from 'react-redux';


import VerticalTabCard from 'pages/_rd_overview/VerticalTabCard';

import { getReducerName } from 'data/dataSourceConstants';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import DataSetsList from 'pages/_rd_overview/dataSetsCard/DataSetsList';


import './DataSetsCardMobile.css';


function DataSetsCardMobile() {
	const dispatch = useDispatch();

	const open = (item) => {
		let options = {
			dataSet: item.dataSet,
			reducerName: getReducerName(item.dataConfig, item.dataSource),
			dataSource: item.dataSource,
			title: item.config.title,
			columns: item.config[item.dataSource].columns,
		};

		dispatch(openModal(MODAL_TYPES.DATA_TABLE, options));
	};


	let [content, setContent] = useState('Violations');

	let buttons = [
		{
			label: 'Violations',
			onClick: () => setContent('Violations'),
			isSelected: content === 'Violations',
		},
		{
			label: 'Infrastructure',
			onClick: () => setContent('Infrastructure'),
			isSelected: content === 'Infrastructure',
		},
		{
			label: 'Certifications',
			onClick: () => setContent('Certifications'),
			isSelected: content === 'Certifications',
		},
	];

	function getContent(contentName) {
		if (content === 'Violations') {
			return <DataSetsList category={'violation'} onSelectDataSet={open} />;
		} else if (content === 'Infrastructure') {
			return <DataSetsList category={'infrastructure'} onSelectDataSet={open} />;
		} else if (content === 'Certifications') {
			return <DataSetsList category={'certification'} onSelectDataSet={open} />;
		}
	}

	// return (
	// 	<div className="rd-overview-data-sets-card-mobile">
	// 		<div className="category-section">
	// 			<div className="data-set-category-title">Violations</div>
	// 			<DataSetsList category={'violation'} onSelectDataSet={open} />
	// 		</div>
	// 		<div className="category-section">
	// 			<div className="data-set-category-title">Infrastructure</div>
	// 			<DataSetsList category={'infrastructure'} onSelectDataSet={open} />
	// 		</div>
	// 		<div className="category-section">
	// 			<div className="data-set-category-title">Certifications</div>
	// 			<DataSetsList category={'certification'} onSelectDataSet={open} />
	// 		</div>
			
	// 	</div>
	// );
	return (
		<div className="rd-overview-data-sets-card-mobile">
			<VerticalTabCard buttons={buttons} >
				{getContent(content)}
			</VerticalTabCard>
		</div>
	);
}

export default DataSetsCardMobile;
