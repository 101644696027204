import { Link } from "react-router-dom";

import Badge from 'components/Badge';

const NavLink = ({ label, violationCount, url, isActive, isDisabled, category, isViolation }) => {
	if (isDisabled) {
		return (
			<span className={`data-set-nav-button link-disabled category-${category}`}>
				<span className="dataset-item-wrapper">
					<span className="dataset-title">{label}</span>
				</span>
			</span>
		);
	}
	let badgeColor = 'danger';
	if (!isViolation) {
		badgeColor = 'secondary';
	}

	return (
		<Link to={url} className={`data-set-nav-button ${isActive ? 'is-active' : ''} category-${category}`}>
			<span className="dataset-item-wrapper">
				<span className="dataset-title">{label}</span> <Badge count={violationCount} color={badgeColor} />
			</span>
		</Link>
	);
};

export default NavLink;