import ReactToggle from 'react-toggle'
import 'react-toggle/style.css'

import './Toggle.css';

function Toggle({ isEnabled, onChange, label }) {
	
	return (
		<div className="toggle-switch-wrapper">
			<label className="toggle-switch-label">
				<ReactToggle
					defaultChecked={isEnabled}
					icons={false}
					onChange={(ev) => onChange(ev.target.checked)} />
				<span className="toggle-switch-text">{label}</span>
			</label>
		</div>
	);
}

export default Toggle;
