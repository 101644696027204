import { createSelector } from 'reselect';

const cartItems = state => state.cart.items;
const addressParam = (state, address, documentName) => address;
const documentNameParam = (state, address, documentName) => documentName;
const extraParam = (state, address, documentName, extra) => extra || null;

const _getCartItem = (cartItems, address, documentName, extra) => {
	for (let i = 0; i < cartItems.length; i++) {
		if (cartItems[i].address === address && cartItems[i].documentName === documentName && cartItems[i].extra === extra) {
			return cartItems[i];
		}
	}
	return null;
}

const _isItemInCart = (cartItems, address, documentName, extra) => {
	return !!_getCartItem(cartItems, address, documentName, extra);
}


export const getCartItem = createSelector([cartItems, addressParam, documentNameParam], _getCartItem);
export const isItemInCart = createSelector([cartItems, addressParam, documentNameParam, extraParam], _isItemInCart);
