const SELECT_DATASET = 'SELECT_DATASET';
const CLOSE_DATASET = 'CLOSE_DATASET';

const initialState = {
	// selectedDataSet: null,
	dataSet: null,
	reducerName: null,
	dataSource: null,
	title: null,
	columns: null,
};


export const selectDataSet = ({ dataSet,	reducerName,	dataSource,	title,	columns }) => ({
	type: SELECT_DATASET,
	payload: {
		dataSet,	reducerName,	dataSource,	title,	columns
	}
});

export const closeDataSet = () => ({
	type: CLOSE_DATASET,
	payload: {
		dataSet: null,
		reducerName: null,
		dataSource: null,
		title: null,
		columns: null,
	}
});

const overviewDataSetReducer = (state = initialState, action) => {
	switch (action.type) {
		case SELECT_DATASET:
		case CLOSE_DATASET:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default overviewDataSetReducer;
