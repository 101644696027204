
export const complianceDataToEvents = (complianceData) => {
	let events = [];
	Object.entries(complianceData).forEach(([department, complianceDates]) => {
		complianceDates.forEach(complianceDate => {
			events.push({
				date: complianceDate.date,
				title: `${department} - ${complianceDate.title}`
			});
		});
	});
	return events;
}
