import { dataConfig } from 'data/dataConfig';
import { sortToString, DEFAULT_OWNERS_SORT } from 'utils/sort';
import { API_URL } from 'data/services/apiConstants';

const dataSetsToQueryString = (dataSets) => {
	let urlStrings = [];
	dataSets.forEach(dataSet => {
		urlStrings.push(`sets%5B%5D=${dataConfig[dataSet].exportName}`);
	});
	let queryString = urlStrings.join('&');	
	return queryString;
}

export const getPdfExportUrl = (propertyId, dataSets) => {
	let query = dataSetsToQueryString(dataSets);
	return `${API_URL}/api/property/${propertyId}/export?${query}&type=Download+PDF`;
};

export const getExcelExportUrl = (propertyId, dataSets) => {
	let query = dataSetsToQueryString(dataSets);
	return `${API_URL}/api/property/${propertyId}/export?${query}&type=Download+Excel`;
};

export const getEmailExportUrl = (propertyId, dataSets, emailAddress) => {
	let query = dataSetsToQueryString(dataSets);
	// let email = encodeURIComponent(emailAddress);
	let email = emailAddress;
	return `${API_URL}/api/property/${propertyId}/export?${query}&type=Email&email=${email}`;
};

export const getBackendSearchExcelExportUrl = (dataSet, searchString, pageNum=1) => {
	return `${API_URL}/api/open-data/${dataSet}/search/download?query=${searchString}&page=${pageNum}`;
};

export const getTopViolationsExcelExportUrl = (sort='numViolations|desc', page=1, perPage=100) => {
	return `${API_URL}/api/marketing/top-100-violations-bins/download?sort=${sort}&page=${page}&per_page=${perPage}`;
};

export const getOwnersExcelExportUrl = (propertyId, { sort, page=1, dataSource=null, perPage=10 }) => {
	let sortString = sortToString(sort, DEFAULT_OWNERS_SORT);
	let url = `${API_URL}/api/property/${propertyId}/owners/download?sort=${sortString}&page=${page}&per_page=${perPage}`;
	if (dataSource) {
		url += `&set=${dataSource}`;
	}
	return url;
};
