import {trackPageView} from 'utils/googleAnalytics';

const pageTrackerMiddleware = ({ getState, dispatch }) => {
	return next => action => {
		if (action.type === '@@router/LOCATION_CHANGE') {
			trackPageView(action?.payload?.location?.pathname);
		}
		return next(action);
	}
}

export default pageTrackerMiddleware;