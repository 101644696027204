export const specialDistricts = {
	'125th': 'Special 125th Street District',
	'BNY': 'Special Brooklyn Navy Yard District',
	'BPC': 'Special Battery Park City District',
	'BR': 'Special Bay Ridge District',
	'BSC': 'Special Bay Street Corridor District',
	'C': 'Special Grand Concourse Preservation District',
	'CD': 'Special City Island District',
	'CI': 'Special Coney Island District',
	'CL': 'Special Clinton District',
	'CO': 'Special Coney Island Mixed Use District',
	'CP': 'Special College Point District',
	'CR-n': 'Special Coastal Risk District, where n is the number of the district',
	'DB': 'Special Downtown Brooklyn District',
	'DFR': 'Special Downtown Far Rockaway District',
	'DJ': 'Special Downtown Jamaica District',
	'EC-n': 'Special Enhanced Commercial District, where n is the number of the district',
	'EHC': 'East Harlem Corridors',
	'FH': 'Special Forest Hills District',
	'FW': 'Special Flushing Waterfront District',
	'G': 'Special Gowanus Mixed-Use District',
	'GC': 'Special Garment Center District',
	'GI': 'Special Governors Island District',
	'HP': 'Special Hunts Point District',
	'HRP': 'Special Hudson River Park District',
	'HRW': 'Special Harlem River Waterfront District',
	'HS': 'Special Hillsides Preservation District',
	'HSQ': 'Special Hudson Square District',
	'HY': 'Special Hudson Yards District',
	'IN': 'Special Inwood District',
	'J': 'Jerome Avenue District',
	'L': 'Special Lincoln Square District',
	'LC': 'Special Limited Commercial District',
	'LI': 'Special Little Italy District',
	'LIC': 'Special Long Island City Mixed Use District',
	'LM': 'Special Lower Manhattan District',
	'MiD': 'Special Midtown District',
	'MMU': 'Special Manhattanville Mixed Use District',
	'MP': 'Special Madison Avenue Preservation District',
	'MX-n': 'Special Mixed Use District, where n is the number of the district',
	'NA-n': 'Special Natural Area District, where n is the number of the district',
	'NA-4': 'Special Fort Totten Natural Area District-4',
	'OP': 'Special Ocean Parkway District',
	'PC': 'Special Planned Community Preservation District',
	'PI': 'Special Park Improvement District',
	'SB': 'Special Sheepshead Bay District',
	'SG': 'Special St. George District',
	'SHP': 'Special Southern Hunters Point District',
	'SNX': 'Special SoHo-NoHo Mixed Use District',
	'SRD': 'Special South Richmond Development District',
	'SRI': 'Special Southern Roosevelt Island District',
	'SV-1': 'Special Scenic View District',
	'SW': 'Special Stapleton Waterfront District',
	'TA': 'Special Transit Land Use District',
	'TMU': 'Special Tribeca Mixed Use District',
	'U': 'Special United Nations Development District',
	'US': 'Special Union Square District',
	'WCh': 'Special West Chelsea District',
	'WP': 'Special Willets Point District',
}