import { CLEAR_CART, ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART, initialState } from 'reducers/cartReducer';

const CART_UPDATERS = [CLEAR_CART, ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART];

export const getCartState = () => {
	let cartItemsString = localStorage.getItem('cartItems');

	if (cartItemsString) {
		//todo: try catch parse and clear localstorage if its in a bad state
		let cartItems = JSON.parse(cartItemsString);
		let cart = Object.assign({}, initialState, {
			items: cartItems,
		});
		return { cart };
	} else {
		return {};
	}
}

const cartMiddleware = ({ getState, dispatch }) => {
	return next => action => {

		if (CART_UPDATERS.includes(action.type)) {
			const result = next(action);
			const updatedCart = getState().cart;
			localStorage.setItem('cartItems', JSON.stringify(updatedCart.items));
			return result;		
		}

		return next(action);
	}
}

export default cartMiddleware;
