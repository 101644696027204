import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
// import { searchOwner } from 'data/services/ownerSearchService';
import { getOwnersExcelExportUrl } from 'data/services/exportService';
import { fetchOwners } from 'reducers/ownersReducer';
import SortableHeader from 'components/dataTable/SortableHeader';
import { DEFAULT_OWNERS_SORT } from 'utils/sort';

import PhoneNumber from 'formatters/PhoneNumber';
import Spinner from 'view/components/Spinner';
import Paginator from 'components/Paginator';

import './Owners.css';

const DATA_SOURCE_OPTIONS = [
	{
		label: 'All data sources',
		value: 'all',
	},
	{
		label: 'DOB',
		value: 'dob',
	},
	{
		label: 'DOS',
		value: 'dos',
	},
	{
		label: 'FDNY',
		value: 'fdny',
	},
	{
		label: 'SLA',
		value: 'sla',
	},
];

const Owners = (props) => {
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const {
		propertyId,
		isLoading,
		owners,
		currentPage,
		totalPages,
		propertyData,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoading: state.owners.isLoading || state.property.isLoading,
		owners: state.owners.data?.data || [],
		currentPage: state.owners.data?.current_page,
		totalPages: state.owners.data?.last_page,
		propertyData: state.property.propertyData,
	}));
	const [dataSource, setDataSource] = useState(null);
	const [currentSort, setCurrentSort] = useState(Object.assign({}, DEFAULT_OWNERS_SORT));
	usePageTitle('Representatives', propertyData);

	const onDataSourceSelect = (ev) => {
		let newDataSource = ev.target.value;
		if (newDataSource === 'all') {
			newDataSource = null;
		}
		setDataSource(newDataSource);
		dispatch(fetchOwners(propertyId, { dataSource: newDataSource }));
	}

	useEffect(() => {
		if (propertyId) {
			dispatch(fetchOwners(propertyId));
		}
	}, [propertyId, dispatch]);

	const onSearch = (searchTerm) => {
		// searchOwner(searchTerm);
	};

	const onSort = (nextSort) => {
		setCurrentSort(nextSort);
		dispatch(fetchOwners(propertyId, { sort: nextSort, currentPage, perPage: 10, dataSource }));
	};

	let exportLink = (owners?.length ? 
 		<a className="export-link" href={getOwnersExcelExportUrl(propertyId, { sort: currentSort, currentPage, dataSource })}><i className="bi bi-cloud-download" /></a>
			: null
	);

	const HeaderCell = ({ colKey, label }) => (
		<SortableHeader
			column={{ key: colKey, label }}
			sortField={currentSort.field}
			sortDirection={currentSort.direction}
			onSort={onSort}
			defaultSort={DEFAULT_OWNERS_SORT}
		/>
	);

	return (
		<div className="property-overview owners">

			<div className="card">
				<div className="card-header">
					Representatives
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className={`${ isMobile ? 'col' : 'col-7'} d-flex`}>
							<select className="form-select" onChange={onDataSourceSelect}>
								{DATA_SOURCE_OPTIONS.map((option) => (
									<option key={option.value} value={option.value}>{option.label}</option>
								))}
							</select>
						{exportLink}
						</div>
					</div>
					{isLoading ? <Spinner text="Loading representatives" className="mt-4" /> : 
						<div>
							<div>
								<table className="table table-striped data-table">
									<thead>
										<tr>
											<HeaderCell colKey='name' label='Name' />
											<HeaderCell colKey='email' label='Email' />
											<HeaderCell colKey='phone' label='Phone' />
											<HeaderCell colKey='date' label='Date' />
											<HeaderCell colKey='source' label='Source' />

{/*											<th>Name</th>
											<th>Email</th>
											<th>Phone</th>
											<th>Date</th>
											<th>Source</th>*/}
										</tr>
									</thead>
									<tbody>
										{owners.map((row, i) => (
											<tr key={`${row.name}-${i}`}>
												<td className="owner-name-row">
													<span onClick={() => onSearch(row.name)} >
														{row.name || '-'}
													</span>
												</td>
												<td className="owner-email-row">{row.email || '-'}</td>
												<td className="owner-phone-row"><PhoneNumber text={row.phone || '-'} /></td>
												<td className="owner-date-row">{row.dateString || '-'}</td>
												<td className="owner-source-row">{row.source || '-'}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>

							<Paginator
								currentPage={currentPage}
								totalPages={totalPages}
								setPage={(page) => dispatch(fetchOwners(propertyId, { sort: currentSort, page, perPage: 10, dataSource }))}
							/>
						</div>
					}
				
				</div>
			</div>


		</div>
	);	
}

export default Owners;
