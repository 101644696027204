import { Route } from "react-router-dom";

import AppContainer from 'containers/AppContainer';
import PropertyContainer from 'containers/PropertyContainer';
import DataTablePage from 'pages/DataTablePage';

import dataTables from 'view/dataTables';
import { dataConfig } from 'data/dataConfig';
import { DATA_SOURCES, getAppRoute, getReducerName } from 'data/dataSourceConstants';

function DataSetRoutes() {
	let routes = [];

	Object.keys(dataTables).forEach((dataSet) => {
		let tableConfig = dataTables[dataSet];

		DATA_SOURCES.forEach((dataSource) => {
			if (tableConfig[dataSource]) {
				let reducerName = getReducerName(dataConfig[dataSet], dataSource);
				let path = `/property/:address/${getAppRoute(dataSet, dataSource)}`;
				let title = tableConfig.title;
				let columns = tableConfig[dataSource].columns;
				routes.push(
					<Route key={path} path={path}>
						<AppContainer>
							<PropertyContainer>
								<DataTablePage
									title={title}
									columns={columns}
									dataSet={dataSet}
									dataSource={dataSource}
									reducerName={reducerName}
								/>
							</PropertyContainer>
						</AppContainer>
					</Route>
				);
			}
		});
	});

	return routes;
}

export default DataSetRoutes;
