import AddressSearch from 'components/addressSearch/AddressSearch';
import { usePageTitle } from 'utils/pageTitle';

import './PropertyNotFound.css';


const PropertyNotFound = (props) => {
	usePageTitle('Not Found');
	return (
		<div className="property-not-found-page">
			<div className="property-not-found-page-content">
				<h1 className="text-center"><i className="bi bi-emoji-frown" /></h1>
				<h2 className="text-center">We couldn't find the property you're looking for</h2>
				<p className="text-center">Try searching for another property.</p>

				<div className="address-search-wrapper">
					<AddressSearch
						addressSearchLabel="Search by Address"
						blockAndLotSearchLabel="Search by Block and Lot"
					/>
				</div>
			</div>
		</div>
	);
};

export default PropertyNotFound;
