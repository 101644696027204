import Routes from 'routes/Routes';
import { initDarkMode } from 'data/services/darkMode';
import { initApiUrlFromLocalStorage } from 'data/services/apiConstants';
import { initGoogleAnalytics } from 'utils/googleAnalytics';

import './App.css';
import './Theme.css';

function App() {
	initDarkMode();
	initApiUrlFromLocalStorage();
	initGoogleAnalytics();
	return <Routes />;
}

export default App;
