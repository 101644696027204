import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { fetchComplianceDates } from 'reducers/complianceDatesReducer';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';


import Spinner from 'components/Spinner';
import NothingFoundCardContent from 'components/NothingFoundCardContent';
import ComplianceDatesChecklist from 'components/complianceCalendar/ComplianceDatesChecklist';
import MonthCalendar from 'components/complianceCalendar/MonthCalendar';

import './ComplianceDates.css';



function ComplianceDates(props) {
	// const isMobile = useMediaPredicate(MOBILE_SIZE);
	const dispatch = useDispatch();
	const {
		propertyId,
		isLoading,
		isLoadingProperty,
		complianceData,
		address,
	} = useSelector(state => ({
		propertyId: state.property.propertyData?.id,
		isLoading: state.complianceDates.isLoading || state.property.isLoading,
		isLoadingProperty: state.property.isLoading,
		complianceData: state.complianceDates.data || {},
		address: state.property.propertyData?.address,
	}));

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (propertyId) {
			dispatch(fetchComplianceDates(propertyId));
		} else if (props.isLandingPage) {
			//fetch blank compliance categories for the landing page
			dispatch(fetchComplianceDates(null));
		}
	}, [propertyId, dispatch]);

	let nothingFoundText = null;

	if (props.isLandingPage) {
		nothingFoundText = "Search a property to view compliance checklist";
	}


	const onEventClick = (e) => {
		return dispatch(openModal(MODAL_TYPES.CALENDAR_EVENT, { 
			eventTitle: e.event.title,
		}));
	}

	return (
		<div className="rd-compliance-dates">
			<div className="mb-2 rd-compliance-dates-header building-info-card-header">
				<h2 className="rd-compliance-dates-title building-info-card-title">Compliance</h2>
				<Link to={`/property/${address}/calendar`}><i className="bi bi-calendar3" /></Link>
			</div>
			{isLoadingProperty && <Spinner />}
			{!isLoading && !Object.keys(complianceData).length && <NothingFoundCardContent text={nothingFoundText} />}
			{!isLoadingProperty && (
				<div className="d-flex compliance-dates-content">
					<div className="calendar-wrapper">
						<MonthCalendar height={405} propertyId={propertyId} onEventClick={onEventClick} eventColor='#355E3B' />
					</div>
					<div className="dates-list-wrapper">
						<h3>Compliance Dates</h3>
						<ComplianceDatesChecklist propertyId={propertyId} />
					</div>
				</div>
			)}
		</div>
	);
}

export default ComplianceDates;
