import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';
 
import { fetchNotifications } from 'reducers/notificationsReducer';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

// import './_rd_Dashboard.css';

const Alerts = (props) => {

	usePageTitle('Alerts');
	const dispatch = useDispatch();
	let {
		userId,
		notifications,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		notifications: state.notifications.notificationsData?.data,
		isLoading: state.notifications.isLoading,
		currentPage: state.notifications.notificationsData?.current_page,
		totalPages: state.notifications.notificationsData?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchNotifications(userId));
	}, [userId, dispatch]);

	return (
		<DashboardLayout>
			<div className="">
				<h1>Alerts</h1>
				<div>
					
					{isLoading ? <Spinner /> : 
						<div className="pb-4">
							<div className="dashboard-table-wrapper">
								<table className="table table-striped rd-table">
									<thead><tr>
										<th>Property</th>
										<th>Notification</th>
										<th>Notification Type</th>
										<th>Time</th>
									</tr></thead>
									<tbody>
										{(!notifications || !notifications.length) && (
											<tr><td colSpan={5}>No alerts</td></tr>
										)}
										{notifications?.map((item) => (
											<tr key={item.id}>
												<td className="dashboard-table-property-address-cell">
													{item.property_name?.address ? (
														<Link to={`/property/${item.property_name?.address}`}>{item.property_name?.address}</Link>
													) : '-'}
												</td>
												<td>{item.notification || '-'}</td>
												<td className="dashboard-notification-type-cell">{item.notification_type?.name || '-'}</td>
												<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Paginator
								currentPage={currentPage}
								totalPages={totalPages}
								setPage={(pageNum) => dispatch(fetchNotifications(userId, 'created_at|desc', pageNum, 10))}
							/>
						</div>
					}

				</div>
			</div>
		</DashboardLayout>
	);	


}

export default Alerts;
