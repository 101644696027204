import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { fetchComplianceDates } from 'reducers/complianceDatesReducer';

import Spinner from 'components/Spinner';
import NothingFoundCardContent from 'components/NothingFoundCardContent';
import ComplianceDatesCollapse from 'components/complianceCalendar/ComplianceDatesCollapse';

// import './ComplianceDatesChecklist.css';

function ComplianceDatesChecklist(props) {
	const dispatch = useDispatch();
	const { propertyId, hideSpinner } = props;
	const {
		// propertyId,
		isLoading,
		complianceData,
		address,
	} = useSelector(state => ({
		// propertyId: state.property.propertyData?.id,
		isLoading: state.complianceDates.isLoading || state.property.isLoading,
		complianceData: state.complianceDates.data || {},
		address: state.property.propertyData?.address,
	}));

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (propertyId) {
			dispatch(fetchComplianceDates(propertyId));
		} else if (props.isLandingPage) {
			//fetch blank compliance categories for the landing page
			dispatch(fetchComplianceDates(null));
		}
	}, [propertyId, dispatch]);

	let nothingFoundText = null;

	if (props.isLandingPage) {
		nothingFoundText = "Search a property to view compliance checklist";
	}

	return (
		<div>
			{(isLoading && !hideSpinner) && <Spinner />}
			{props.showCalendarLink ? <div className="mb-2">
				<Link to={`/property/${address}/calendar`}>View in Calendar</Link>
			</div> : null}
			{!isLoading && !Object.keys(complianceData).length && <NothingFoundCardContent text={nothingFoundText} />}
			{Object.keys(complianceData).map((section) => (
				<ComplianceDatesCollapse key={section} sectionName={section} data={complianceData[section]} address={address} />
			))}
		</div>
	);
}

export default ComplianceDatesChecklist;
