export const display = (value) => {
	if (value === null || value === undefined) {
		return '-';
	}
	return value;
}

export const displayLotSize = (zoningData) => {
	if (!zoningData?.lotfront || !zoningData?.lotdepth) {
		return '-';
	}
	return `${zoningData.lotfront} x ${zoningData.lotdepth}`
}