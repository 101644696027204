

import { usePageTitle } from 'utils/pageTitle';

import './Disclaimer.css';

export default function Disclaimer() {
	usePageTitle('Disclaimer');
	return (
		<div className="use-policy-container">
			<div className="use-policy">
				<p>
					<strong>Disclaimer and Limitation of Liability:</strong>
				</p>			
				<p>
					The information contained on this website is for general informational purposes only and is not a substitute for professional legal advice. We make no warranties about the completeness or accuracy of this information. This website and the information it contains are provided "as is" without warranty of any kind, express or implied. All warranties, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, are expressly disclaimed. We do not assume any responsibility for any errors or omissions in the content of this website. The data is provided "as is" and users are responsible for interpreting it in the context of their specific needs. In no event shall we be liable for any special, direct, indirect, consequential, or incidental damages arising out of or in connection with the use of the information presented. You should not act or refrain from acting on the basis of any information contained herein without seeking legal advice from a qualified attorney. We strongly recommend that you consult with an attorney regarding any specific legal matter. You agree to use this website at your own risk and to hold us harmless from any claims or lawsuits arising from that use.
				</p>

				<p>
					<strong>To use on CPR Report and any reports Violerts generates (or office for that matter)</strong>
				</p>
				<p>
					Disclaimer: The property data provided herein is for general informational purposes only and is not a guarantee of accuracy or completeness. It should not be considered a substitute for professional advice from a qualified real estate professional or attorney. We make no warranties, express or implied, regarding the information's accuracy, and assume no responsibility for errors or omissions. The data is provided "as is" and users are responsible for interpreting it in the context of their specific needs. We recommend consulting with a qualified professional to ensure proper interpretation of the data and to avoid any misinterpretations. You should not rely solely on this data for any decision-making and strongly recommend consulting with a qualified professional for specific property-related matters.
				</p>

			</div>
		</div>
	);
}