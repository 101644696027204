import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAdminUser, editAdminUser } from 'reducers/adminUsersReducer';
// import { closeModal } from 'reducers/modalReducer';
import { isValidEmail } from 'utils/validators';
import StandardModal from 'modals/StandardModal';
import Spinner from 'components/Spinner';
import Alert from 'view/components/Alert';

// const UserEditForm = ({ user, userId, onChange }) => {
// 	const [userData, setUserData] = useState({
// 		name: '',
// 		email: '',
// 		phone: '',
// 	});
// 	const [isEmailValid, setIsEmailValid] = useState(false);
// 	// const [email, setEmail] = useState('');
// 	// const [name, setName] = useState('');
// 	// const [phone, setPhone] = useState('');
// 	// const [isSuccess, setIsSuccess] = useState(false);

// 	const updateValue = (propName, newVal) => {
// 		let newData = Object.assign({}, userData);
// 		if (propName === 'email') {
// 			setIsEmailValid(isValidEmail(newVal));
// 		}
// 		if (propName === 'name' || propName === 'email' || propName === 'phone') {
// 			newData = Object.assign({}, newData, {
// 				[propName]: newVal,
// 			}));
// 			setUserData(newData);
// 			onChange(newData);
// 		}
// 	}

// 	// const onChangeEmail = (emailString) => {
// 	// 	setEmail(emailString);
// 	// 	setIsEmailValid(isValidEmail(emailString));
// 	// }

// 	useEffect(() => {
// 		let initialData = {
// 			name: '',
// 			email: '',
// 			phone: '',
// 		};
// 		if (user?.email) {
// 			initialData.email = user.email;
// 			setIsEmailValid(isValidEmail(emailString));
// 		}
// 		if (user?.name) {
// 			initialData.name = user.name;
// 		}
// 		if (user?.phone) {
// 			initialData.phone = user.phone;
// 		}
// 		setUserData(initialData);
// 		onChange(initialData);
// 	}, [user]);

// 	return (
// 		<div>
// 			<div className="form-group mb-3">
// 				<label>Name</label>
// 				<input
// 					type="text"
// 					className="form-control"
// 					value={name}
// 					onChange={ev => updateValue('name', ev.target.value)}
// 					placeholder="Your name"
// 				/>
// 			</div>
// 			<div className="form-group mb-3">
// 				<label>Email</label>
// 				<input
// 					type="email"
// 					placeholder="Email"
// 					className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
// 					required
// 					value={email}
// 					onChange={ev => updateValue('email', ev.target.value)}
// 					autoFocus
// 				/>
// 			</div>
// 			<div className="form-group mb-3">
// 				<label>Phone Number</label>
// 				<input
// 					type="text"
// 					className="form-control"
// 					value={phone}
// 					onChange={ev => updateValue('phone', ev.target.value)}
// 					placeholder="(555)-555-5555"
// 				/>
// 			</div>
// 		</div>
// 	);
// }

const UserAdminEditModal = ({ options }) => {
	const dispatch = useDispatch();
	const { userId } = options;
	const {
		isLoading,
		isUpdating,
		user,
	} = useSelector(state => ({
		isLoading: state.adminUsers.isLoadingUser,
		isUpdating: state.adminUsers.isUpdating,
		user: state.adminUsers.user,
	}));

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => {
		if (userId) {
			dispatch(fetchAdminUser(userId));
		}
	}, [dispatch, userId]);

	useEffect(() => {
		if (user?.email) {
			setEmail(user.email);
			setIsEmailValid(isValidEmail(user.email));
		}
		if (user?.name) {
			setName(user.name);
		}
		if (user?.phone) {
			setPhone(user.phone);
		}
	}, [user]);

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	//todo: notify on success and reload users data
	const onSubmit = () => {
		setIsSuccess(false);
		dispatch(editAdminUser(userId, {
			name,
			email,
			phone,
		})).then((res) => {
			if (res?.type === "UPDATE_ADMIN_USER_SUCCESS") {
				setIsSuccess(true);	
			}
		});
	};

	const userForm = (
		<div>
			<div className="form-group mb-3">
				<label>Name</label>
				<input
					type="text"
					className="form-control"
					value={name}
					onChange={ev => setName(ev.target.value)}
					placeholder="Name"
				/>
			</div>
			<div className="form-group mb-3">
				<label>Email</label>
				<input
					type="email"
					placeholder="Email"
					className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
					required
					value={email}
					onChange={(ev) => onChangeEmail(ev.target.value)}
					autoFocus
				/>
			</div>
			<div className="form-group mb-3">
				<label>Phone Number</label>
				<input
					type="text"
					className="form-control"
					value={phone}
					onChange={ev => setPhone(ev.target.value)}
					placeholder="(555)-555-5555"
				/>
			</div>
		</div>
	);

	const successAlert = (
		<Alert color="success">
			<div>User info updated successfully!</div>
		</Alert>
	);

	return (
		<StandardModal
			title={"User Admin"}
			size="md"
			closeOnOutsideClick
			confirmText="Save"
			closeLabel="Cancel"
			onConfirm={onSubmit}
			disableConfirm={!email || !isEmailValid || isUpdating || isLoading}
			disableClose={isUpdating}
		>
			<div>
				{isSuccess && successAlert}
				{(isLoading || isUpdating) ? <Spinner /> : 
					<div className="">
						{userForm}
					</div>
				}
			</div>
		</StandardModal>
	);
};

export default UserAdminEditModal;
