import { BOROUGHS } from 'utils/boroughs';

export const getSanbornMapImageUrl = (propertyData) => {
	if (!propertyData) { return null; }
	let propertyId = propertyData?.id;
	let sanbornMaps = propertyData?.sanborn_maps;
	let firstMap = sanbornMaps?.[0];
	let mapId = firstMap?.id;
	if (!propertyId || !mapId) {
		return null;
	}
	return `http://api.violerts.com/api/properties/${propertyId}/sanborn-maps/${mapId}`;
}


export const isManhattan = (propertyData) => {
	return propertyData?.borough === BOROUGHS.MANHATTAN;
}

export const prettyBorough = (propertyData) => {
	if (propertyData?.borough === BOROUGHS.MANHATTAN) {
		return 'Manhattan';
	} else if (propertyData?.borough === BOROUGHS.BRONX) {
		return 'Bronx';
	} else if (propertyData?.borough === BOROUGHS.BROOKLYN) {
		return 'Brooklyn';
	} else if (propertyData?.borough === BOROUGHS.QUEENS) {
		return 'Queens';
	} else if (propertyData?.borough === BOROUGHS.STATEN_ISLAND) {
		return 'Staten Island';
	}
}