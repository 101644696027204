import StandardModal from 'modals/StandardModal';

const ImageModal = ({ options }) => {
	return (
		<StandardModal
			title={""}
			closeOnOutsideClick
			size="xl"
		>
			<div className="text-center">
				<img className="street-view-image" src={options.src} alt="alt" />
			</div>
		</StandardModal>
	);
};

export default ImageModal;
