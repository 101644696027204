// import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import './LeafletMap.css';

const MAX_ZOOM = 19;

function CustomTiles({ url }) {
	const attribution = '&copy; <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">City of New York CC BY 4.0</a>';
	return (
		<TileLayer
			attribution={attribution}
			url={url}
			maxZoom={MAX_ZOOM}
		/>
	);
}

function LeafletMap({ propertyData, zoningData, mapType }) {
	const START_ZOOM = 18;
	const lat = propertyData?.lat;
	const longitude = propertyData?.['long'];

	if (!lat || !longitude) {
		return (
			<div className="leaflet-map-wrapper">
				<div className="map-error-message">Latitude and longitude data not available for this property</div>
			</div>
		);
	}

	const tileUrl_Google = 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}';
	const tileUrl_StreetNames = 'https://maps.nyc.gov/xyz/1.0.0/carto/label-lt/{z}/{x}/{y}.png8';
	const tileUrl_1950s = 'https://maps.nyc.gov/xyz/1.0.0/photo/1951/{z}/{x}/{y}.png8';
	const tileUrl_1920s = 'https://maps.nyc.gov/xyz/1.0.0/photo/1924/{z}/{x}/{y}.png8';
	const tileUrl_Sanborn = 'https://mapwarper.net/mosaics/tile/1194/{z}/{x}/{y}.png';

	let tileLayer = null;
	if (mapType === '1920s') {
		tileLayer = <CustomTiles url={tileUrl_1920s} />;
	} else if (mapType === '1950s') {
		tileLayer = <CustomTiles url={tileUrl_1950s} />;
	} else if (mapType === 'sanborn') {
		tileLayer = <CustomTiles url={tileUrl_Sanborn} />;
	} else if (mapType === 'street') {
		tileLayer = <CustomTiles url={tileUrl_Google} />;
	}
	
	let streetNameLayer = <CustomTiles url={tileUrl_StreetNames} />;

	//key=latlong to force react to re-render the map on property change
	return (
		<div className="leaflet-map-wrapper" key={`${lat}${longitude}`}>
			<MapContainer center={[lat, longitude]} zoom={START_ZOOM} scrollWheelZoom={false}>
				{tileLayer}
				{streetNameLayer}
				<Marker position={[lat, longitude]}>
				  <Popup>
				    {propertyData?.address}
				  </Popup>
				</Marker>
			</MapContainer>
		</div>
	);
}

export default LeafletMap;
