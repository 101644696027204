import { useState } from 'react'
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';

import OutboundLink from 'components/OutboundLink';

import StandardModal from 'modals/StandardModal';
import Spinner from 'components/Spinner';

const CalendarEventModal = ({ options }) => {
	const {
		address,
		bin,
		isLoading,
	} = useSelector(state => ({
		address: state.property.propertyData.address,
		bin: state.property.propertyData.bin,
		isLoading: state.property.isLoading,
	}));
	const [isCopied, setIsCopied] = useState(false);

	const copyBin = () => {
		copy(bin);
		setIsCopied(true);
	}

	return (
		<StandardModal
			title={"FDNY Report"}
			size="md"
			closeOnOutsideClick
		>
			{isLoading ? <Spinner /> : 
				<div>
					<h6 className="">{address}</h6>
					<div className="mb-3">
						<div className="form-group">
							<label>BIN</label>

							<div className="input-group">
								<input type="text" className="form-control" value={bin} disabled />
								<button className={`btn btn-${isCopied ? 'success' : 'primary'}`} onClick={copyBin}>
									{isCopied ? <i className="bi bi-check me-2"/> : <i className="bi bi-clipboard-data me-2"/>}
									{isCopied ? 'BIN Copied' : 'Copy BIN'}
								</button>
							</div>
						</div>
					</div>
					<div className="mb-3 fs-6">
						<OutboundLink
							url="https://fires.fdnycloud.org/CitizenAccess/Report/ReportParameter.aspx?module=&reportID=984&reportType=LINK_REPORT_LIST"
							label={"FDNY Report"}
						/>
					</div>
				</div>
			}
		</StandardModal>
	);
};

export default CalendarEventModal;
