import { DATA_SOURCES, prettifyDataSource } from 'data/dataSourceConstants';

export const getSortOrder = (component) => {
	//number is the order allen wants the links in
	const rank = {
		'DOB Violations': 2,
		'ECB Violations': 1,
		'Job Applications': 7,
		'Electrical-(OPEN_DATA)': 9,
		'Elevator-(OPEN_DATA)': 9.1,
		'Boilers-(OPEN_DATA)': 9.2,
		'Board of Standards and Appeals Applications-(OPEN_DATA)': 11,
		'CityPay Tickets-(CITYPAY)': 6,
		'Sign Application Filings-(OPEN_DATA)': 11.5, //added to make it appear next to others in the same category
		'Tax Lien Sales-(OPEN_DATA)': 1300,
		'ACRIS Legals-(OPEN_DATA)': 1200,
		'Complaints': 5,
		'Department of Buildings': 8,
		'HPD': 4,
		'FDNY': 3,
	};

	//if something is missing, just put it towards the bottom
	if (!component?.key) {
		return 1000;
	}
	return rank[component.key] || 1000;
}

export const linkComparator = (a, b) => getSortOrder(a) - getSortOrder(b);

export const countDataSources = (dataSetConfig) => {
	let dataSourceCount = 0;
	DATA_SOURCES.forEach((dataSource) => {
		if (dataSetConfig[dataSource]) {
			dataSourceCount += 1;
		}
	});
	return dataSourceCount;
}

export const getLabel = (config, dataSource) => {
	let title = config.navTitle || config.title;
	let dataSourceCount = countDataSources(config);
	let prettyTitle = title;
	if (dataSourceCount > 1) {
		let prettyDataSource = prettifyDataSource(dataSource);
		prettyTitle = `${title} (${prettyDataSource})`;
	}
	return prettyTitle;
}
