import { useSelector } from 'react-redux';

import AppHeader from 'components/appHeader/AppHeader';
import SpinnerOverlay from 'components/SpinnerOverlay';

import './AppContainer.css';

const AppContainer = ({ showSidebar, children, hideToolbarSearch, hideHeaderSearch }) => {
	let {
		isLoadingAddress,
		isLoadingProperty,
	} = useSelector(state => ({
		isLoadingAddress: state.address.isLoading,
		isLoadingProperty: state.property.isLoading,
	}));

	let spinnerText = '';
	if (isLoadingAddress) {
		spinnerText = 'Checking Address...';
	} else if (isLoadingProperty) {
		spinnerText = 'Loading Property...';
	}

	return (
		<div className="app">
			<AppHeader hideHeaderSearch={hideHeaderSearch} />
			<SpinnerOverlay text={spinnerText} isLoading={isLoadingAddress || isLoadingProperty} />
			{children}
		</div>
	);
};

export default AppContainer;
