import { useState } from 'react';

import VerticalTabCard from 'pages/_rd_overview/VerticalTabCard';

import ComplianceDates from 'pages/_rd_overview/propertyInfoCard/ComplianceDates';
import Representatives from 'pages/_rd_overview/propertyInfoCard/Representatives';

import BuildingInfoTableV2 from 'pages/_rd_overview/propertyInfoCard/BuildingInfoTableV2';
import RequestDocuments from 'pages/_rd_overview/propertyInfoCard/RequestDocuments';

import './BuildingInfoCard.css';

function BuildingInfoCard({ propertyData, zoningData }) {
	let [content, setContent] = useState('General');

	let buttons = [
		{
			label: 'General',
			onClick: () => setContent('General'),
			isSelected: content === 'General',
		},
		{
			label: 'Representatives',
			onClick: () => setContent('Representatives'),
			isSelected: content === 'Representatives',
		},
		{
			label: 'Compliance',
			onClick: () => setContent('Compliance'),
			isSelected: content === 'Compliance',
		},
		{
			label: 'Documents',
			onClick: () => setContent('Documents'),
			isSelected: content === 'Documents',
		},
	];

	function getContent(contentName) {
		if (content === 'General') {
			return <BuildingInfoTableV2 propertyData={propertyData} zoningData={zoningData} />;
		} else if (content === 'Representatives') {
			return <Representatives />;
		} else if (content === 'Compliance') {
			return <ComplianceDates />;
		} else if (content === 'Documents') {
			return <RequestDocuments />;
		}
	}

	return (
		<div className="rd-overview-building-card">
			<VerticalTabCard buttons={buttons} >
				{getContent(content)}
			</VerticalTabCard>
		</div>
	);
}

export default BuildingInfoCard;
