import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import AdminLayout from 'pages/_rd_dashboard/AdminLayout';

import { fetchAdminNotifications } from 'reducers/adminNotificationsReducer';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

const AdminNotifications = (props) => {
	usePageTitle('Admin | Notifications');
	const dispatch = useDispatch();
	let {
		notifications,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		notifications: state.adminNotifications.data?.data || [],
		isLoading: state.adminNotifications.isLoading,
		currentPage: state.adminNotifications.data?.current_page,
		totalPages: state.adminNotifications.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchAdminNotifications('created_at|desc', 1, 10));
	}, [dispatch]);

	
	return (
		<AdminLayout>
			<div className="dashboard-portfolio">
				<h1>Notifications</h1>
				{isLoading ? <Spinner /> :
					<div className="pb-4">
						<div className="dashboard-table-wrapper">
							<table className="table table-striped">
								<thead><tr>
									<th>User</th>
									<th>Property</th>
									<th>Notification</th>
									<th>Notification Type</th>
									<th>Time</th>
								</tr></thead>
								<tbody>
									{notifications?.map((item) => (
										<tr key={item.id}>
											<td className="dashboard-username-cell">{item.user_slim?.name || '-'}</td>
											<td className="dashboard-table-property-address-cell">{item.property_name?.address || '-'}</td>
											<td className="break-anywhere">{item.notification || '-'}</td>
											<td className="dashboard-notification-type-cell">{item.notification_type?.name || '-'}</td>
											<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchAdminNotifications('created_at|desc', pageNum, 10))}
						/>
					</div>
				}
			</div>
		</AdminLayout>
	);	
}

export default AdminNotifications;
