import Formatters from 'formatters/_Formatter';
import RecordDetailsLink from 'formatters/RecordDetailsLink';

import { BIS } from 'data/dataSourceConstants';

import Highlight from 'components/Highlight';
import SortableHeader from 'components/dataTable/SortableHeader';
import FileUploadButton from 'components/dataTable/FileUploadButton';

import './DataTable.css';

function DataTable(props) {
	let {
		columns,
		data,
		dataSet,
		dataSource,
		propertyData,
		isLoading,
		searchString,
		sortField,
		sortDirection,
		onSort,
	} = props;

	let noDataRow = <tr><td colSpan={columns.length + 1} className="nothing-found-row">No Data Available</td></tr>;

	if (isLoading) {
		noDataRow = <tr><td colSpan={columns.length + 1} className="nothing-found-row">Loading...</td></tr>;
	}

	return (
		<table className="table table-striped data-table">
			<thead>
				<tr>
					<th></th>
					{columns.map((col, i) => (
						<SortableHeader
							key={`${col.key}-${col.label}-${i}`}
							column={col}
							sortField={sortField}
							sortDirection={sortDirection}
							onSort={onSort}
						/>
					))}
				</tr>
			</thead>
			<tbody>
				{(data.length === 0 || isLoading) && noDataRow}
				{!isLoading && data.map((row, i) => (
					<tr key={row.id}>
						<td className="data-table-buttons-cell">
							<div className="data-table-buttons-wrapper">
								<RecordDetailsLink isBis={dataSource === BIS} row={row} dataSet={dataSet} />
								<FileUploadButton dataSet={dataSet} dataSource={dataSource} row={row} />
							</div>
						</td>
						{columns.map((col, j) => {
							let cellContent = row[col.key] || '-';
							if (col.formatter) {
								let Formatter = Formatters[col.formatter];
								if (Formatter && row[col.key]) {
									cellContent = <Formatter text={row[col.key]} rowData={row} propertyData={propertyData} dataSet={dataSet} dataSource={dataSource} />;
								}
								return (
									<td key={`${row.id}-${i}-${col.key}-${col.label}-${j}`}>
										<Highlight text={cellContent} substring={searchString} />
									</td>
								);
							}
							return (
								<td key={`${row.id}-${i}-${col.key}-${col.label}-${j}`}>
									<Highlight text={cellContent} substring={searchString} />
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default DataTable;
