import { useEffect } from 'react'; 
import { shortAddress } from 'utils/addressUtils';

export const usePageTitle = (pageTitle, propertyData) => {
	let title = 'Violerts';
	if (!pageTitle && !!propertyData?.address) {
		title = `${shortAddress(propertyData?.address)} | Violerts`;
	} else if (!pageTitle && !propertyData?.address) {
		title = 'Violerts';
	} else if (pageTitle && !propertyData?.address) {
		title = `${pageTitle} | Violerts`;
	} else {
		title = `${pageTitle} | ${shortAddress(propertyData?.address)} | Violerts`;	
	}

	useEffect(() => {
		document.title = title;
	}, [pageTitle, propertyData, title]);
};