import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_RECORD_DETAILS_REQUEST = 'GET_RECORD_DETAILS_REQUEST';
const GET_RECORD_DETAILS_SUCCESS = 'GET_RECORD_DETAILS_SUCCESS';
const GET_RECORD_DETAILS_ERROR = 'GET_RECORD_DETAILS_ERROR';

const initialState = {
	id: null,
	urlPath: null, //the data set, formatted the way the api way
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchRecordDetails = (id, urlPath) => (dispatch) => {
	let url = `${API_URL}/api/${urlPath}/${id}`;
	dispatch(recordDetailsRequest(id, urlPath));
	return get({
		dispatch,
		url,
		onSuccess: (result) => {
			dispatch(recordDetailsRequestSuccess(result));
		},
		onError: error => dispatch(recordDetailsRequestError(error)),
	});
};

export const recordDetailsRequest = (id, urlPath) => ({
	type: GET_RECORD_DETAILS_REQUEST,
	payload: {
		id,
		urlPath,
		data: null,
		isLoading: true,
		hasError: false,
		error: null,
	}
});

export const recordDetailsRequestSuccess = (data) => ({
	type: GET_RECORD_DETAILS_SUCCESS,
	payload: {
		isLoading: false,
		data,
		hasError: false,
		error: null,
	}
});

export const recordDetailsRequestError = (error) => ({
	type: GET_RECORD_DETAILS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const recordDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_RECORD_DETAILS_REQUEST:
		case GET_RECORD_DETAILS_SUCCESS:
		case GET_RECORD_DETAILS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default recordDetailsReducer;
