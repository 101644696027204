import { get } from 'data/services/apiService';
import { API_URL } from 'data/services/apiConstants';

const GET_ADMIN_PORTFOLIOS_REQUEST = 'GET_ADMIN_PORTFOLIOS_REQUEST';
const GET_ADMIN_PORTFOLIOS_SUCCESS = 'GET_ADMIN_PORTFOLIOS_SUCCESS';
const GET_ADMIN_PORTFOLIOS_ERROR = 'GET_ADMIN_PORTFOLIOS_ERROR';

const initialState = {
	data: null,
	isLoading: false,
	hasError: false,
	error: null,
};

export const fetchAdminPortfolios = (sort='created_at|desc', page=1, perPage=10) => (dispatch, getState) => {
	let state = getState();
	let url = `${API_URL}/api/admin/portfolios?sort=${sort}&page=${page}&per_page=${perPage}`;
	dispatch(getAdminPortfoliosRequest());
	return get({
		dispatch,
		url,
		authToken: state.user.authToken,
		onSuccess: (result) => {
			dispatch(getAdminPortfoliosSuccess(result));
		},
		onError: error => dispatch(getAdminPortfoliosError(error)),
	});
};

export const getAdminPortfoliosRequest = () => ({
	type: GET_ADMIN_PORTFOLIOS_REQUEST,
	payload: {
		data: null,
		isLoading: true,
		hasError: false,
		error: null,
	}
});

export const getAdminPortfoliosSuccess = (data) => ({
	type: GET_ADMIN_PORTFOLIOS_SUCCESS,
	payload: {
		isLoading: false,
		data,
		hasError: false,
		error: null,
	}
});

export const getAdminPortfoliosError = (error) => ({
	type: GET_ADMIN_PORTFOLIOS_ERROR,
	payload: {
		isLoading: false,
		data: null,
		hasError: true,
		error: error,
	}
});

const adminPortfoliosReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ADMIN_PORTFOLIOS_REQUEST:
		case GET_ADMIN_PORTFOLIOS_SUCCESS:
		case GET_ADMIN_PORTFOLIOS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default adminPortfoliosReducer;
