import { useSelector } from 'react-redux';

import Spinner from 'components/Spinner';


import './AICard.css';

function AICard() {
	const {
		propertyData,
		isLoading,
	} = useSelector(state => ({
		propertyData: state.property.propertyData,
		isLoading: state.property.isLoading,
	}));


	return (
		<div className="overview-card overview-card-small ai-card">
			<div className="overview-card-content">
				<div className="fw-bold">Vio AI</div>
				<div className="ai-content">
					{isLoading ? <Spinner /> :
						<div dangerouslySetInnerHTML={{ __html: propertyData?.summary }} />
					}
				</div>

			</div>
		</div>
	);
}

export default AICard;
