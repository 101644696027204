const DISTRICTS_OVERVIEW = 'https://www.nyc.gov/site/planning/zoning/districts-tools.page';

const RESIDENTIAL_OVERVIEW = 'https://www.nyc.gov/site/planning/zoning/districts-tools/residence-districts-r1-r10.page';
const COMMERCIAL_OVERVIEW = 'https://www.nyc.gov/site/planning/zoning/districts-tools/commercial-districts-c1-c8.page';
const MANUFACTURING_OVERVIEW = 'https://www.nyc.gov/site/planning/zoning/districts-tools/mfg-districts.page';

const R1 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r1.page';
const R2 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r2.page';
const R3 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r3.page';
const R4 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r4.page';
const R5 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r5.page';
const R6 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r6.page';
const R7 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r7.page';
const R8 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r8.page';
const R9 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r9.page';
const R10 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/r10.page';

const C1_C2 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c1-c2.page';
const C3_C3A = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c3-c3a.page';
const C4 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c4.page';
const C5 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c5.page';
const C6 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c6.page';
const C7 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c7.page';
const C8 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/c8.page';

const M1 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/m1.page';
const M2 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/m2.page';
const M3 = 'https://www.nyc.gov/site/planning/zoning/districts-tools/m3.page';

const DISTRICT_LOOKUP = {
	R1: R1,
	R2: R2,
	R3: R3,
	R4: R4,
	R5: R5,
	R6: R6,
	R7: R7,
	R8: R8,
	R9: R9,
	R10: R10,
	C1: C1_C2,
	C2: C1_C2,
	C3: C3_C3A,
	C4: C4,
	C5: C5,
	C6: C6,
	C7: C7,
	C8: C8,
	M1: M1,
	M2: M2,
	M3: M3,
};

export const getDistrictUrl = (district='') => {
	if (!district) {
		return DISTRICTS_OVERVIEW;
	}
	let regex = /^[C,R,M]\d{1,2}/i;
	let match = district.match(regex)?.[0];

	if (DISTRICT_LOOKUP[match]) {
		return DISTRICT_LOOKUP[match];
	} else if (district?.[0]?.toLowerCase() === 'r') {
		return RESIDENTIAL_OVERVIEW;
	} else if (district?.[0]?.toLowerCase() === 'c') {
		return COMMERCIAL_OVERVIEW;
	} else if (district?.[0]?.toLowerCase() === 'm') {
		return MANUFACTURING_OVERVIEW;
	}

	return DISTRICTS_OVERVIEW;
}
