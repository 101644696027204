import { OPEN_DATA, BIS } from 'data/dataSourceConstants';
import OutboundLink from 'components/OutboundLink';
import { API_URL } from 'data/services/apiConstants';

function AEULink({ violationNumber, formType, dataSource }) {
	let url = '';
	if (dataSource === BIS) {
		url = `${API_URL}/api/ecb-violations/${violationNumber}/${formType}`;	
	} else if (dataSource === OPEN_DATA) {
		url = `${API_URL}/api/open-data/ecb-violations/${violationNumber}/${formType}`;	
	} else {
		return '-';
	}
	
	return <OutboundLink url={url} iconOnly />;
}

export const AEU2Link = (props) => {
	return AEULink({
		violationNumber: props.text,
		formType: 'aeu2',
		dataSource: props.dataSource,
	});
};

export const AEU20Link = (props) => {
	return AEULink({
		violationNumber: props.text,
		formType: 'aeu20',
		dataSource: props.dataSource,
	});
};
