import { SIGN_IN_SUCCESS, SIGN_OUT, GET_USER_SUCCESS, REGEN_API_KEY_SUCCESS, initialState } from 'reducers/userReducer';

export const getSessionState = () => {
	let authToken = localStorage.getItem('authToken');
	let userDataString = localStorage.getItem('userData');

	if (authToken && userDataString) {
		//todo: try catch parse and clear localstorage if its in a bad state
		let userData = JSON.parse(userDataString);
		let user = Object.assign({}, initialState, {
			authToken,
			userData,
		});
		return { user };
	} else {
		return {};
	}
}

const sessionMiddleware = ({ getState, dispatch }) => {
	return next => action => {
		if (action.type === SIGN_IN_SUCCESS) {
			localStorage.setItem('authToken', action.payload.authToken);
		} else if (action.type === GET_USER_SUCCESS) {
			localStorage.setItem('userData', JSON.stringify(action.payload.userData));
		} else if (action.type === SIGN_OUT) {
			localStorage.removeItem('authToken');
			localStorage.removeItem('userData');
		} else if (action.type === REGEN_API_KEY_SUCCESS) {
			localStorage.setItem('userData', JSON.stringify(action.payload.userData));
		}
		return next(action);
	}
}

export default sessionMiddleware;
