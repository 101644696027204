import './Highlight.css';

function Highlight({ text, substring }) {
	if (!substring || substring === '' || !text || !text.split) {
		return text;
	}

	let parts = text.split(new RegExp(`(${substring})`, 'gi'));

	return (
		<span>
			{parts.map((part, i) => (
				<span key={i} className={part.toLowerCase() === substring.toLowerCase() ? 'highlighted' : '' }>
					{part}
				</span>
			))}
    </span>
  );
}

export default Highlight;
