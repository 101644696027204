import { Link } from "react-router-dom";

import "./SignUpCTA.css";

function SignUpCTA({ text }) {
	return (
		<div className="sign-up-cta-wrapper">
			<div>{text || 'Sign up to access'}</div>
			<div>
				<Link className="btn btn-primary" to="/sign-up">Sign Up</Link>
			</div>
		</div>
	);
}

export default SignUpCTA;
