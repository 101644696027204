// import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
// import TabCard from 'components/_rd_TabCard';
import PropertyDocumentsList from 'components/externalLinks/PropertyDocumentsList';
// import DocDownloadList from 'pages/_rd_overview/propertyInfoCard/DocDownloadList';

import './RequestDocuments.css';

function RequestDocuments() {
	const dispatch = useDispatch();
	const {
		// isLoading,
		propertyData,
	} = useSelector(state => ({
		// isLoading: state.property.isLoading,
		propertyData: state.property.propertyData,
	}));

	const openHistoricDocModal = () => {
		dispatch(openModal(MODAL_TYPES.HISTORIC_DOCUMENT_REQUEST, { propertyData }));
	}


	return (
		<div className="rd-request-documents">
			<div className="building-info-card-header">
				<h2 className="building-info-card-title me-3">Documents</h2>
				<button
					onClick={openHistoricDocModal}
					className="btn btn-primary btn-sm"
					style={{display: "block", marginLeft: "auto", marginRight: "1%", marginBottom: "15px"}}
				>
					Full Historic Records Request
				</button>
			</div>
			<div className="request-docs-lists">
				<PropertyDocumentsList />

				{/* commented out DocDownloadList as per Allen's request*/}
				{/*<div><DocDownloadList /></div>*/}
			</div>
		</div>
	);
}

export default RequestDocuments;
