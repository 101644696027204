import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import { regenerateApiKey } from 'reducers/userReducer';
import Alert from 'components/Alert';
import Spinner from 'components/Spinner';
import OutboundLink from 'components/OutboundLink';

import './Airtable.css';

const Airtable = (props) => {
	usePageTitle('Connect to Airtable');
	let dispatch = useDispatch();
	let {
		isLoggedIn,
		airtableKey,
		isRegeneratingApiKey,
		apiKeyRegenError,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		airtableKey: state.user.userData?.api_key?.api_key || '',
		isRegeneratingApiKey: state.user.isRegeneratingApiKey,
		apiKeyRegenError: state.user.apiKeyRegenError,
	}));
	
	let airtableKeyElement = (
		<div className="airtable-key-container">
			{isRegeneratingApiKey && <Spinner />}
			<div className="form-group mb-3">
				{apiKeyRegenError && <Alert text={apiKeyRegenError} color="danger" />}
				<label className="">Your Airtable API key is:</label>
				<div>
					<input type="text" readOnly className="form-control-plaintext api-key-input" value={airtableKey} />
				</div>
			</div>
			<button disabled={isRegeneratingApiKey} className="btn btn-sm btn-primary" onClick={() => dispatch(regenerateApiKey())}>
				Regenerate Airtable Key
			</button>
		</div>
	);

	if (!isLoggedIn) {
		airtableKeyElement = (
			<div className="airtable-key-container">
				To access your Airtable API key, you must <Link to="/sign-in">sign in</Link> or <Link to="/sign-up">sign up</Link>
			</div>
		)
	}

	return (
		<div className="airtable-instructions-page-wrapper">
			<div className="airtable-instructions-page">
				<div className="card">
	{/*				<div className="card-header">
						How to Connect to Airtable
					</div>*/}
					<div className="card-body">
						<h1>Connecting to Airtable</h1>

						<div className="instruction-section">
							<h2>Step 1: Create Accounts</h2>
							<p>
									In order to import data from Violerts to Airtable, you must have both an Airtable and a Violerts account.
								</p>
								<p>
									<OutboundLink url="https://airtable.com/signup">Create an Airtable account here</OutboundLink>
								</p>
								<p>
									<Link to="/sign-up">Create a Violerts account here</Link>
								</p>
							</div>

							<div className="instruction-section">
								<h2>Step 2: Add the Violerts Data Importer App to Airtable</h2>
								<p>
									Create a new base in Airtable. Instructions for how to create a new base can be found <OutboundLink url="https://www.airtable.com/guides/start/how-to-create-a-base">Here</OutboundLink>
								</p>


								<p>
									Add the Violerts Data Importer app by clicking the link below.
								</p>


								<div className="alert alert-info">
									<p>
										<OutboundLink className="btn btn-primary btn-sm" url="https://airtable.com/marketplace/unlisted?secret=bls71mSmlMfO0XWXE">{'Add Violerts Data Importer'}</OutboundLink>
									</p>
									<br />
									<p>
										Or navigate to: 
									</p>
									<p>
										<OutboundLink url="https://airtable.com/marketplace/unlisted?secret=bls71mSmlMfO0XWXE">{'https://airtable.com/marketplace/unlisted?secret=bls71mSmlMfO0XWXE'}</OutboundLink>
									</p>
								</div>


								<p>
									You will see the following warning, which can be ignored:
								</p>
								<div className="alert alert-warning">
									This app has not been reviewed by Airtable. Make sure you only add unlisted apps from developers you trust.
								</div>


								<p>
									Click "Add".
								</p>
								<p>
									Once the app has been added, go to "Apps", and click the "Add an app" button. Select the "Custom apps" tab and locate the "Violerts Data Importer" app and click "Add".
								</p>
							</div>


							<div className="instruction-section">
								<h2>Connect Airtable to Violerts with your API Key</h2>
								<p>
									After you've added the app, you'll need to enter your API key. In the top right corner of the Violerts Data Importer app, click the gear icon to open the app settings, and enter your API key.
								</p>
								<div className="alert alert-info">
									{airtableKeyElement}
								</div>
							</div>


							<div className="instruction-section">
								<h2>Step 3: Add a Property and Dataset</h2>
								<p>
									From Violerts, copy the address of your property and paste it into the address box in the Airtable Violerts Data Importer app and click "Search".
								</p>
								<p>
									Select the matching property from the search results.
								</p>
								<p>
									Select a dataset to import.
								</p>
								<p>
									Click "Add new Table with Rows".
								</p>
								<p>
									A new table in your base will be created containing all the dataset's data!
								</p>
							</div>


						</div>
					</div>

				</div>
		</div>
	);

}

export default Airtable;
