import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import AdminLayout from 'pages/_rd_dashboard/AdminLayout';

import { fetchAdminUsers } from 'reducers/adminUsersReducer';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

const AdminUsers = (props) => {
	usePageTitle('Admin | Users');
	const dispatch = useDispatch();
	let {
		users,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		users: state.adminUsers.data?.data || [],
		isLoading: state.adminUsers.isLoading,
		currentPage: state.adminUsers.data?.current_page,
		totalPages: state.adminUsers.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchAdminUsers());
	}, [dispatch]);

	const openUserEditAdminModal = (userId) => {
		dispatch(openModal(MODAL_TYPES.USER_ADMIN_EDIT, { userId }));
	}

	const openUserCreateAdminModal = () => {
		dispatch(openModal(MODAL_TYPES.USER_ADMIN_CREATE));
	}

	
	return (
		<AdminLayout>
			<div className="dashboard-portfolio">
				<h1>User Management</h1>
				{isLoading ? <Spinner /> :
					<div className="pb-4">
						<button className="btn btn-primary" onClick={openUserCreateAdminModal}>Invite New User</button>
						<div className="dashboard-table-wrapper">
							<table className="table table-striped">
								<thead><tr>
									<th />
									<th>Email</th>
									<th>Name</th>
									<th>Created At</th>
								</tr></thead>
								<tbody>
									{users?.map((item) => (
										<tr key={item.id}>
											<td>
												<button className="btn btn-hyperlink" onClick={() => openUserEditAdminModal(item.id)}>
													<i className="bi bi-pencil-square" />
												</button>
											</td>
											<td>{item.email || '-'}</td>
											<td className="dashboard-username-cell">{item.name || '-'}</td>
											<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchAdminUsers('created_at|desc', pageNum, 10))}
						/>
					</div>
				}
			</div>
		</AdminLayout>
	);
}

export default AdminUsers;
