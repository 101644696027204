import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API_URL } from 'data/services/apiConstants';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import './ImagesWidget.css';

function ImagesWidget({ thumbnailUrl, propertyData, zoningData, onBack }) {
	const dispatch = useDispatch();
	const [selectedImg, setSelectedImg] = useState(null);
	let lunaImages = [];
	if (propertyData?.lunaImages) {
		lunaImages = propertyData.lunaImages;
	}

	useEffect(() => {
		if (thumbnailUrl) {
			setSelectedImg(`${API_URL}${thumbnailUrl}`);
		}
	}, [thumbnailUrl])

	const onSelect = (src) => {
		setSelectedImg(src);
	}

	const onExpand = () => {
		dispatch(openModal(MODAL_TYPES.IMAGE, { src: selectedImg }));
	}

	return (
		<div className="images-widget">
			<div className="image-overlay-buttons">
				{onBack && <button onClick={() => onBack()}><i className="bi bi-x-lg" /></button>}
				<button onClick={() => onExpand()}><i className="bi bi-fullscreen" /></button>
			</div>
			<div className="main-image">
				{setSelectedImg && <img className="street-view-image" src={selectedImg} alt="Building" />}
			</div>
			<div className="thumbnail-carousel">
				<div className="thumbnail-wrapper" onClick={() => onSelect(`${API_URL}${thumbnailUrl}`)}>
					{thumbnailUrl && <img className="thumb-image" src={`${API_URL}${thumbnailUrl}`} alt={`Street view`} />}
				</div>
				{lunaImages.map((images, i) => (
					<div className="thumbnail-wrapper" key={i}  onClick={() => onSelect(images?.mediumSizeLink || images?.thumbnail)}>
						{images.thumbnail && <img className="thumb-image" src={images.thumbnail} alt={`Historic ${i}`} />}
					</div>
				))}
			</div>
		</div>
	);
}

export default ImagesWidget;
