import { tableConfig } from 'view/dataTables';
import { dataConfig } from 'data/dataConfig';

//props generated by the api that shouldn't be shown to the user
const hiddenProperties = {
	created_at: true,
	updated_at: true,
	isOpen: true,
};

export function getDetailsApiPath(dataSet) {
	if (dataConfig[dataSet].detailsUrl) {
		return dataConfig[dataSet].detailsUrl;
	}
	//if there isnt a detailsUrl prop, then its the same api path as the list endpoint
	return dataConfig[dataSet].urlPath;
}

export function getRecordTitle(dataSet) {
	return tableConfig[dataSet]?.singularTitle || 'Record';
}

export function isPropertyHidden(propName) {
	return !!hiddenProperties[propName];
}

export function prettifyPropName(propName) {
	return propName;
	// return propName.replaceAll('_', ' ').toUpperCase();
}
