// import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { API_URL } from 'data/services/apiConstants';
// import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';
// import BuildingCard from 'pages/_rd_dashboard/BuildingCard';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';
import { fetchPortfolio } from 'reducers/portfolioReducer';
import { removeProperty } from 'reducers/portfolioSettingsReducer';
import { closeModal } from 'reducers/modalReducer';

import Spinner from 'components/Spinner';

import './PortfolioPropertyCard.css';

const PortfolioPropertyCard = (props) => {
	const { property, portfolio } = props;
	const dispatch = useDispatch();
	let {
		userId,
		isLoading,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		isLoading: state.portfolio.isLoading,
	}));

	const openNotificationModal = (portfolioId, property) => {
		dispatch(openModal(MODAL_TYPES.PROPERTY_NOTIFICATION_MODAL, { portfolioId, property }));
	};

	const onExpand = (src) => {
		dispatch(openModal(MODAL_TYPES.IMAGE, { src }));
	};

	const confirmRemove = (propertyId) => {
		return dispatch(openModal(MODAL_TYPES.CONFIRM, { 
			color: 'danger',
			title: 'Remove from Portfolio',
			onConfirm: () => {
				dispatch(removeProperty(propertyId))
				.then((res) => {
					dispatch(fetchPortfolio(userId));
					dispatch(closeModal());
				});
			},
			children: (<div>Are you sure you want to remove this property from your portfolio?</div>),
			confirmLabel: 'Remove',
		}));
	};

	if (isLoading) {
		return (
			<div className="portfolio-property-card">
				<Spinner />
			</div>
		);
	}


	return (
		<div className="portfolio-property-card">
			<div className="thumb-wrapper">
				<img
					className="thumb"
					src={`${API_URL}${property?.thumbnail_url}`}
					alt="Building"
					onClick={() => onExpand(`${API_URL}${property?.thumbnail_url}`)}
				/>
			</div>

			<div className="portfolio-property-card-info">
				<div className="portfolio-property-card-text">
					<div className="portfolio-property-card-address">
						<Link to={`/property/${property?.address}`}>{property?.address}</Link>
					</div>
					<div className="portfolio-property-card-bbl">
						<span>[Block/Lot - {property?.block}/{property?.lot}]</span>
						<span>[BIN - {property?.bin}]</span>
					</div>
				</div>


				<div className="portfolio-property-card-buttons">
					<button className="btn btn-danger btn-sm me-3" onClick={() => confirmRemove(property.id)}>Remove</button>
					<button className="btn btn-primary btn-sm" onClick={() => openNotificationModal(portfolio.id, property)}>Notifications</button>
				</div>
			</div>
										
		</div>
	);	


}

export default PortfolioPropertyCard;
