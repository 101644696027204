import DateFormatter from 'formatters/DateFormatter';
import { getLabelFromValue } from 'pages/backendSearch/backendSearchConstants';

const formatRow = (row, key) => {
	let text = row[key] || '-';
	if (key?.toLowerCase()?.includes('date')) {
		text = <DateFormatter text={text} />;
	}
	return text;
}

const buildTable = (data) => {
	return (
		<table className="table table-striped data-table">
			<thead>
				<tr>
					{Object.keys(data[0]).map((key) => (
						<th key={key} className="">{key}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, i) => (
					<tr key={i}>
						{Object.keys(row).map((key) => (
							<td key={key} className="">{formatRow(row, key)}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

const BackendSearchTable = ({ data, dataSet, searchString, resultPage }) => {
	let tableElement = null;
	if (!data || !data.length) {
		if (searchString && dataSet) {
			tableElement = (
				<div className="backend-search-nothing-found mt-5 mb-4">
					{`No results found for "${searchString}" in ${getLabelFromValue(dataSet)}, page ${resultPage}`}
				</div>
			);
		}
	} else if (data?.length) {
		tableElement = (
			<div className="mt-2">
				<div className="small-text">{`Results for "${searchString}" in ${getLabelFromValue(dataSet)}, page ${resultPage}`}</div>
				<div className="backend-search-results-table-wrapper">
					{buildTable(data)}
				</div>
			</div>
		);
	}	
	return tableElement;
}

export default BackendSearchTable;
