import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { usePageTitle } from 'utils/pageTitle';
import { getBackendSearchExcelExportUrl } from 'data/services/exportService';
import { recordSearch } from 'reducers/recordSearchReducer';
import Spinner from 'view/components/Spinner';
import { DATA_SET_OPTIONS, getLabelFromValue } from 'pages/backendSearch/backendSearchConstants';
import { DataSetSelectDesktop } from 'pages/backendSearch/DataSetSelect';
import BackendSearchTable from 'pages/backendSearch/BackendSearchTable';
import BackendSearchControls from 'pages/backendSearch/BackendSearchControls';
import './BackendSearchPage.css';

const BackendSearchPage = (props) => {
	usePageTitle('Backend Search');
	const dispatch = useDispatch();
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const {
		isLoading,
		data,
		dataSet,
		searchString,
	} = useSelector(state => ({
		isLoading: state.recordSearch.isLoading,
		data: state.recordSearch.data || [],
		dataSet: state.recordSearch.dataSet,
		searchString: state.recordSearch.searchString,
	}));

	const [selectedDataSet, setSelectedDataSet] = useState(DATA_SET_OPTIONS[0].value);
	const [searchTerm, setSearchTerm] = useState('');
	const [page, setPage] = useState(1);
	const [resultPage, setResultPage] = useState(1);

	const onSearch = (currentPage) => {
		const pageToSearch = currentPage || page;
		if (selectedDataSet && searchTerm) {
			setResultPage(pageToSearch);
			dispatch(recordSearch(selectedDataSet, searchTerm, currentPage || page));
		}
	};

	const onSelectDataSet = (e) => {
		setSelectedDataSet(e.target.value);
		setPage(1);
	};

	let exportLink = (
		<div className="my-2">
			{data?.length ? 
				<a className="export-link" href={getBackendSearchExcelExportUrl(dataSet, searchString, resultPage)}>Download Excel</a>
				: null
			}
		</div>
	);

	return (
		<div className="property-overview backend-search">
			<div className="card">
				<div className="card-header">
					Backend Search
				</div>
				<div className={`card-body ${isMobile ? '' : 'desktop-layout'}`}>
					<div className={`${isMobile ? '' : 'backend-search-desktop'}`}>
						{!isMobile && <DataSetSelectDesktop selectedDataSet={selectedDataSet} onSelect={onSelectDataSet} />}
						<div className={`${isMobile ? '' : 'backend-search-right-content'}`}>
							<BackendSearchControls
								onSelectDataSet={onSelectDataSet}
								onSearch={onSearch}
								page={page}
								setPage={setPage}
								searchTerm={searchTerm}
								selectedDataSet={selectedDataSet}
								setSearchTerm={setSearchTerm}
							/>
							{exportLink}
							{isLoading ?
								<div className="mt-5 mb-4">
									<Spinner text={`Searching ${getLabelFromValue(selectedDataSet)} for "${searchTerm}"`} />
								</div> :
								<BackendSearchTable data={data} dataSet={dataSet} searchString={searchString} resultPage={resultPage} />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);	
}

export default BackendSearchPage;
