import { post } from 'data/services/apiService';

export const CLEAR_CART = 'CLEAR_CART';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const SUBMIT_CHECKOUT_REQUEST = 'SUBMIT_CHECKOUT_REQUEST';
export const SUBMIT_CHECKOUT_SUCCESS = 'SUBMIT_CHECKOUT_SUCCESS';
export const SUBMIT_CHECKOUT_ERROR = 'SUBMIT_CHECKOUT_ERROR';

const MAX_ID = 9999999999999999;
const randomId = () => Math.floor(Math.random() * MAX_ID);

export const initialState = {
	items: [],
	isSubmitting: false,
	hasCheckoutError: false,
	checkoutError: null,
};

export const clearCart = () => ({
	type: CLEAR_CART,
	payload: {
		items: [],
		isSubmitting: false,
		hasCheckoutError: false,
		checkoutError: null,
	},
});

export const addItemToCart = ({ documentName, address, propertyId, extra }) => (dispatch, getState) => {
	let state = getState();
	let items = [...state.cart.items];

	let newCartItem = {
		cartItemId: randomId(),
		documentName,
		address,
		propertyId,
		extra: extra || null,
	};
	
	items.push(newCartItem);

	return dispatch({
		type: ADD_ITEM_TO_CART,
		payload: {
			items,
		},
	});
};

export const removeItemFromCart = (address, documentName, extra=null) => (dispatch, getState) => {
	let state = getState();
	let items = [...state.cart.items];
	
	items = items.filter((item) => {
		if (item.address === address && item.documentName === documentName) {
			if (item.extra && extra) {
				return item.extra !== extra;
			}
			return false;
		}
		return true;
	});

	return dispatch({
		type: REMOVE_ITEM_FROM_CART,
		payload: {
			items,
		},
	});
};

export const checkout = () => (dispatch, getState) => {
	let url = `https://example.com`;
	let state = getState();
	let items = [...state.cart.items];

	dispatch(submitCheckoutRequest());
	return post({
		dispatch,
		url,
		data: { items },
		onSuccess: (result) => {
			return dispatch(submitCheckoutRequestSuccess(result))
		},
		onError: (error) => {
			return dispatch(submitCheckoutRequestError(error))
		},
	});
};

export const submitCheckoutRequest = () => ({
	type: SUBMIT_CHECKOUT_REQUEST,
	payload: {
		isSubmitting: true,
	}
});

export const submitCheckoutRequestSuccess = (data) => ({
	type: SUBMIT_CHECKOUT_SUCCESS,
	payload: {
		isSubmitting: false,
		hasCheckoutError: false,
		items: [],
		error: null,
	}
});

export const submitCheckoutRequestError = (error) => ({
	type: SUBMIT_CHECKOUT_ERROR,
	payload: {
		isSubmitting: false,
		hasCheckoutError: true,
		checkoutError: error,
	}
});

const cartReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CART:
		case ADD_ITEM_TO_CART:
		case REMOVE_ITEM_FROM_CART:
		case SUBMIT_CHECKOUT_REQUEST:
		case SUBMIT_CHECKOUT_SUCCESS:
		case SUBMIT_CHECKOUT_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default cartReducer;
