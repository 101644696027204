// import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { Link } from "react-router-dom";

import { closeModal } from 'reducers/modalReducer';
import AddressSearch from 'components/addressSearch/AddressSearch';
import Logo from 'components/appHeader/Logo';

import StandardModal from 'modals/StandardModal';

import './GetStartedModal.css';

const GetStartedModal = ({ options }) => {
	const dispatch = useDispatch();
	const {
		isLoggedIn,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
	}));

	const close = () => {
		return dispatch(closeModal());	
	};

	return (
		<StandardModal
			title={""}
			size="md"
			closeOnOutsideClick
			className="welcome-modal"
			noFooter
		>
			<div className="get-started-modal-body">
				{/*<img src="/violerts-logo.png" className="logo" alt="violerts" />*/}
				<div className="logo-wrapper">
					<Logo />
				</div>

				<div className="copy-wrapper">
					<div>Property compliance</div>
					<div>just got easier.</div>
				</div>

				<div className="address-search-wrapper">
					<AddressSearch
						hideLabel
						addressSearchLabel="Search for a property"
						onSelect={close}
						showHeaderHint
					/>
				</div>

				<div className="backend-search-warpper">
					<Link to={`/backend-search`} onClick={close}>
						<i className="bi bi-door-open-fill"/> Backend Search
					</Link>
				</div>

				{!isLoggedIn && (
					<div className="sign-in-wrapper">
						<div><Link className="auth-link" to="/sign-in" onClick={close}>Sign in</Link></div>
						<div><Link className="auth-link" to="/sign-up" onClick={close}>Sign up</Link></div>
					</div>
				)}
				<div className="use-policy-wrapper mt-2"><Link to="/use-policy">Acceptable Use Policy</Link></div>
			</div>
		</StandardModal>
	);
};

export default GetStartedModal;
