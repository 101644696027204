export const OPEN_DATA = 'OPEN_DATA';
export const BIS = 'BIS';
export const CITYPAY = 'CITYPAY';
export const NYC_GOV = 'NYC_GOV';

export const DATA_SOURCES = [
	OPEN_DATA,
	BIS,
	CITYPAY,
	NYC_GOV,
];

export function camelCaseDataSource(dataSource) {
	if (dataSource === OPEN_DATA) {
		return 'OpenData';
	} else if (dataSource === BIS) {
		return 'BIS';
	} else if (dataSource === CITYPAY) {
		return 'Citypay';
	} else if (dataSource === NYC_GOV) {
		return 'NYCgov';
	}
	return '';
}

export function prettifyDataSource(dataSource) {
	if (dataSource === OPEN_DATA) {
		return 'Open Data';
	} else if (dataSource === BIS) {
		return 'BIS';
	} else if (dataSource === CITYPAY) {
		return 'CityPay';
	} else if (dataSource === NYC_GOV) {
		return 'NYC.gov';
	}
	return '';
}

export function getDataSourceApiUrlPath(dataSource) {
	if (dataSource === OPEN_DATA) {
		return 'open-data';
	} else if (dataSource === BIS) {
		return 'bis';
	} else if (dataSource === CITYPAY) {
		return ''; //special case: citypay data source not in api url path
	} else if (dataSource === NYC_GOV) {
		return ''; //special case: nyc.gov data source not in api url path
	}
	return '';
}

export function getReducerName(configObj, dataSource) {
	return`${configObj.dataSet}${camelCaseDataSource(dataSource)}`;
}

export function getAppRoute(dataSet, dataSource) {
	return`${dataSet}${camelCaseDataSource(dataSource)}`;
}
