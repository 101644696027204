import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRealPropertyMasterData } from 'reducers/acrisRealPropertyMasterReducer';

import Spinner from 'view/components/Spinner';
import OutboundLink from 'view/components/OutboundLink';
import StandardModal from 'modals/StandardModal';
import DateFormatter from 'formatters/DateFormatter';


const cols = [
	{
		key: 'doc_type',
		label: 'Doc Type'
	},
	{
		key: 'record_type',
		label: 'Record Type'
	},
	{
		key: 'recorded_datetime',
		label: 'Date Recorded'
	},
	{
		key: 'good_through_date',
		label: 'Good Through'
	},
	{
		key: 'document_amt',
		label: 'Amonut'
	},
]

const AcrisRealPropertyMasterModal = ({ options }) => {
	const dispatch = useDispatch();
	const { documentId } = options;
	const {
		isLoading,
		propertyId,
		data,
		datasetUrl,
	} = useSelector(state => ({
		isLoading: state.acrisRealPropertyMaster.isLoading,
		propertyId: state.property.propertyData?.id,
		data: state.acrisRealPropertyMaster.data?.data,
		datasetUrl: state.acrisRealPropertyMaster.data?.dataset_url,
	}))

	useEffect(() => {
		if (propertyId && documentId) {
			return dispatch(fetchRealPropertyMasterData(propertyId, documentId));
		}
	}, [dispatch, propertyId, documentId]);

	const getValue = (row, col) => {
		if (!row || !col || !row[col.key]) {
			return '-';
		}
		if (col.key === 'recorded_datetime' || col.key === 'good_through_date') {
			return <DateFormatter text={row[col.key]} />
		}
		return row[col.key];
	}

	let modalContent = (
		<div>
			Nothing found
		</div>
	);

	if (isLoading) {
		modalContent = <Spinner text="Getting real property master data" />
	} else if (data) {
		modalContent = (
			<div>
				<div><OutboundLink url={datasetUrl}>Open Data</OutboundLink></div>
				<div>Document ID: {documentId}</div>
				<table className="table table-striped data-table">
					<thead>
						<tr>
							{cols.map((col, i) => (
								<th key={i}>{col.label}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((row, i) => (
							<tr key={i}>
								{cols.map((col, i) => (
									<th key={i}>{getValue(row, col)}</th>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	}

	return (
		<StandardModal
			title="ACRIS Real Property Master"
			size="lg"
			closeOnOutsideClick
		>
			{modalContent}
		</StandardModal>
	);
};

export default AcrisRealPropertyMasterModal;
