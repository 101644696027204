// import { useState, useEffect } from 'react';

import './VerticalButtons.css';

const VerticalButtons = ({ buttons }) => {

	return (
		<div className="vertical-button-group">
			{buttons.map((item, i) => (
				<button
					key={i}
					className={`btn ${item.isSelected ? 'is-selected' : ''}`}
					onClick={item.onClick}
				>
					{item.children ? item.children : <span>{item.label}</span>}
				</button>
			))}
		</div>
	);
}

export default VerticalButtons;
